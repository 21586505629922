import React, {useEffect, useMemo, useState} from 'react';
import {Loader} from "rsuite";
import debounce from 'lodash.debounce';

type Args = {
  queryResult?: { data: any };
  data?: any;
  children: any;
  renderLoader?: () => any;
  debounce?: false | number;
}

const QueryLoader = ({
  queryResult,
  data,
  children,
  debounce: debounceReady = false,
  renderLoader = () => <Loader />
}: Args) => {
  const [isReady, setIsReady] = useState(false);
  const setReady = useMemo(
    () => debounce(() => setIsReady(true), debounceReady === false ? 0 : debounceReady),
    [setIsReady, debounceReady],
  );

  useEffect(() => {
    if (
      typeof queryResult !== 'undefined'
      && typeof queryResult.data !== 'undefined'
    ) {
      setReady();
    } else if (typeof data !== 'undefined') {
      setReady();
    }
  }, [queryResult, setReady, data]);

  if (!isReady) {
    return renderLoader();
  }

  return children;
};

export default QueryLoader;
