import React from 'react';
import {getPeopleDetails_peopleCredits} from "../../graphql-types";
import {Optional} from "../types";
import Media from '../Media';
import styled from "styled-components";
import {renderOn, useNavigate} from "../../utils";
import {Button} from "rsuite";
import MediaVote from "../MediaVote";
import MediaImage from "../MediaImage";

const Container = styled.div`
  text-align: left;
  overflow: auto;

  ${/* @ts-ignore */ undefined}
  ${MediaImage} {
    width: 100px;
  }
`;

const MediaComponent = styled(Media)`
  padding-left: 1rem;
`;

const VoteContainer = styled.div`
  top: 1rem;
  right: 1rem;
  position: absolute;
`;

const ListsContainer = styled.div`
  margin-top: 1rem;
`;

const ListLink = styled(Button)`
  padding: 0;
`;

const TitleLink = styled(Button)`
  padding: 0;
  font-size: 1.25rem;
  text-align: left;
  margin-bottom: 0;
`;

const MovieParticipation = styled.span`
  max-width: 100%;
`;

type Props = {
  media: Optional<getPeopleDetails_peopleCredits>;
  className?: string;
};

const PersonMedia = ({media, className = ''}: Props) => {
  const {goToList, goToListTv, createTvAndNavigate, createMovieAndNavigate} = useNavigate();

  const handleCreate = async () => {
    if (media?.movieDbId) {
      if (media?.mediaType === 'tv') {
        createTvAndNavigate(media.movieDbId);
      } else {
        createMovieAndNavigate(media.movieDbId);
      }
    }
  };

  const handleNavigateList = (listId: Optional<number>) => {
    if (typeof listId === 'number') {
      if (media?.mediaType === 'tv') {
        goToListTv(listId);
      } else {
        goToList(listId);
      }
    }
  };

  if (!media) return null;

  const {overview, ...rest} = media;

  return (
    <Container
      className={`${className} card`}
    >
      <MediaComponent
        media={rest}
        renderTitle={title => (
          <TitleLink
            appearance="link"
            className="good-word-break"
            onClick={handleCreate}
          >
            {title}
          </TitleLink>
        )}
        titleStyles={{
          paddingRight: '5rem',
        }}
        topRight={
          <VoteContainer>
            <MediaVote
              size={40}
              voteAverage={media.voteAverage}
            />
          </VoteContainer>
        }
        content={
          <>
            {renderOn(
              media.character,
              char => <MovieParticipation className="good-word-break">Plays {char}</MovieParticipation>,
              char => char.length > 0
            )}
            {renderOn(
              media.job,
              job => <MovieParticipation className="good-word-break">{job}</MovieParticipation>,
              job => job.length > 0,
            )}
            {renderOn(
              media.lists,
              lists => (
                <ListsContainer>
                  Your Lists: {lists.map((l, i) => (
                  <React.Fragment key={l?.id}>
                    <ListLink
                      appearance="link"
                      onClick={() => {
                        handleNavigateList(l?.id);
                      }}
                    >
                      {l?.name}
                    </ListLink>
                    {i !== lists.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))}
                </ListsContainer>
              ),
              lists => lists.length > 0
            )}
          </>
        }
      />
    </Container>
  );
};

export default PersonMedia;
