import {OrderByArray} from "../stores";
import {SortOrder} from "../graphql-types";
import {anyPass, isNil, isEmpty as rIsEmpty} from "ramda";

const isEmpty = anyPass([
  isNil,
  rIsEmpty,
]);

export const sortMediaByVar = <T>(currentSort: OrderByArray<T>, media: T[]) => {
  const doSortComparison = (sorting: OrderByArray<T>, valA: T, valB: T): number => {
    if (sorting.length === 0) return 0;
    const currentSort = sorting[0];
    const fieldName = Object.keys(currentSort)[0] as keyof T;
    const sortDir = Object.values(currentSort)[0];

    const direct = (val: number) => val * (sortDir === SortOrder.desc ? 1 : -1);

    if (valA[fieldName] === valB[fieldName]) {
      const [, ...rest] = sorting;
      return doSortComparison(rest, valA, valB);
    }

    if (isEmpty(valA[fieldName])) return 1;
    if (isEmpty(valB[fieldName])) return -1;
    return direct(valA[fieldName] > valB[fieldName] ? -1 : 1);
  };

  return [...media].sort((valueA, valueB) => {
    return doSortComparison(currentSort, valueA, valueB);
  });
};
