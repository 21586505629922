import Sort, {SortField} from "../Sort";
import {ListMediaFilterVar, useMediaListFilter} from "../../stores";
import ProviderLogos from "../ProviderLogos";
import React, {useMemo} from "react";
import styled from "styled-components";
import ProviderDropdown from "../ProviderDropdown";
import {useListWatchProviders} from "../../api";
import {NonNull} from "../../utils/types";
import {listWatchProviders_listWatchProviders} from "../../graphql-types";
import {ReactiveVar} from "@apollo/client";

const ProviderFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ControlsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ProviderFilter = styled(ProviderDropdown)`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

const SortContainer = styled.div`
  margin-right: 2rem;
`;

type Props = {
  sortFields: SortField[];
  sortVar: ReactiveVar<any>;
  filterListVar: ReactiveVar<ListMediaFilterVar>;
  className?: string;
};

type NonNullProvider = NonNull<listWatchProviders_listWatchProviders>;
type NonNullProviders = NonNullProvider[];

const ListControls = ({ sortFields, filterListVar, sortVar, className }: Props) => {
  const [filter, {clearProviders, toggleProvider}] = useMediaListFilter(filterListVar);
  const {data: watchProviders, loading: providersLoading} = useListWatchProviders();

  const selectedProviders = useMemo(() => {
    return (watchProviders?.filter(
      provider => typeof provider?.id === 'number' && (filter.providers || []).includes(provider.id)
    ) || []) as NonNullProviders;
  }, [watchProviders, filter.providers]);

  const toggleModelProvider = (provider: NonNullProvider) => () => {
    toggleProvider(provider.id);
  };

  const isProviderSelected = (provider: NonNullProvider) => {
    return Boolean(filter.providers?.some(id => provider.id === id));
  };

  return (
    <ControlsContainer className={className}>
      <SortContainer>
        <Sort
          fields={sortFields}
          sortVar={sortVar}
        />
      </SortContainer>
      <ProviderFilterContainer>
        <ProviderFilter
          providers={(watchProviders || []) as NonNullProviders}
          toggleProvider={toggleModelProvider}
          isProviderSelected={isProviderSelected}
          loading={providersLoading}
          clearOption={{
            title: 'Clear Providers',
            onSelect: clearProviders,
          }}
        />
        <ProviderLogos providers={selectedProviders} />
      </ProviderFilterContainer>
    </ControlsContainer>
  );
};

export default ListControls;
