import React from 'react';
import {Lists} from "../../components";
import Stats from "../../components/Stats";
import Attribution from "../../components/Attribution";
import MovieRecommend from "../../components/MovieRecommend";
import {useScrollOnMount} from "../../utils";

const LoggedIn = () => {
  useScrollOnMount();
  return (
    <>
      <Attribution />
      <h1 className="is-size-1">Your Watch Lists</h1>
      <Stats />
      <Lists />
      <MovieRecommend />
    </>
  );
};

export default LoggedIn;
