import React, {useEffect, useMemo, useState} from 'react';
import {getPeopleDetails_peopleCredits} from "../../graphql-types";
import {Optional} from "../types";
import PersonMedia from "./PersonMedia";
import styled, {css} from "styled-components";
import Sort, {SortField} from "../Sort";
import {actorPageSortVar} from "../../stores";
import {pipe, range} from "ramda";
import {useReactiveVar} from "@apollo/client";
import {nonNull, sortMediaByVar, useScrollOnMount} from "../../utils";
import {Pagination} from "../pagination";
import {breakpoint} from "../../theme";
import {Placeholder} from 'rsuite';

const mediaSize = css`
  width: 500px;
`;

const Media = styled(PersonMedia)`
  margin-bottom: 2rem;
  margin-right: 1rem;

  ${breakpoint('mobile')} {
    margin-right: 0;
  }

  ${mediaSize}
`;

const MediaPlaceholder = styled(Placeholder.Paragraph)`
  ${mediaSize}
`;

const Wrapper = styled.div`
  flex-grow: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  ${breakpoint('mobile')} {
    flex-direction: column;
    align-items: center;
  }
`;

const TopContainer = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h2`
`;

const sortFields: SortField[] = [
  {
    fieldName: 'popularity',
    label: 'Popularity'
  },
  {
    fieldName: 'episodeCount',
    label: 'TV Episodes',
  },
  {
    fieldName: 'voteAverage',
    label: 'Rating',
  },
  {
    fieldName: 'releaseDate',
    label: 'Released',
  },
  {
    fieldName: 'title',
    label: 'Alphabetically',
  },
  {
    fieldName: 'character',
    label: 'Character Name',
  },
  {
    fieldName: 'job',
    label: 'Job',
  }
];

type InputElement = Optional<getPeopleDetails_peopleCredits>;
type InputMedia = Optional<InputElement[]>;

const deduplicateOnCharacter = (media: InputMedia) => {
  return Object.values((media || []).reduce<{ [k: string]: getPeopleDetails_peopleCredits }>((acc, m) => {
    if (m && m.movieDbId) {
      if (m.movieDbId in acc) {
        if (acc[m.movieDbId].character && m.character) {
          acc[m.movieDbId] = {
            ...acc[m.movieDbId],
            character: `${acc[m.movieDbId].character}, ${m.character}`,
          };
        }
      } else {
        acc[m.movieDbId] = m;
      }
    }
    return acc;
  }, {}));
};

const deduplicateOnJob = (media: InputMedia) => {
  return Object.values((media || []).reduce<{ [k: string]: getPeopleDetails_peopleCredits }>((acc, m) => {
    if (m && m.movieDbId) {
      if (m.movieDbId in acc) {
        if (acc[m.movieDbId].job && m.job) {
          acc[m.movieDbId] = {
            ...acc[m.movieDbId],
            job: `${acc[m.movieDbId].job}, ${m.job}`,
          };
        }
      } else {
        acc[m.movieDbId] = m;
      }
    }
    return acc;
  }, {}));
};

const mapTitle = (media: InputMedia) => (media || []).map<InputElement>(m => ({
  ...m,
  title: m?.title || m?.name
}) as InputElement);

const mapReleaseDate = (media: InputMedia) => (media || []).map<InputElement>(m => ({
  ...m,
  releaseDate: m?.releaseDate || m?.firstAirDate,
}) as InputElement);

const requireTitle = (media: InputMedia) => (media || []).filter(m => Boolean(m?.title));

const require100Votes = (media: InputMedia) => (media || []).map<InputElement>(m => {
  const voteCount = m?.voteCount;
  const voteAverage = nonNull(voteCount) && voteCount > 100 ? m?.voteAverage : null;
  return {
    ...m,
    voteAverage,
  } as InputElement;
});

const prepMedia = pipe(
  require100Votes,
  mapReleaseDate,
  mapTitle,
  requireTitle,
  deduplicateOnCharacter,
  deduplicateOnJob,
);

type Props = {
  media: InputMedia;
  className?: string;
  loading: boolean;
};

const PersonMediaList = ({media, className, loading}: Props) => {
  const uniqueMedia = useMemo(() => prepMedia(media), [media]);
  const sort = useReactiveVar(actorPageSortVar);

  useScrollOnMount();

  const [page, setPage] = useState(1);
  const PER_PAGE = 20;

  const sortedMedia = useMemo(() => sortMediaByVar(sort, uniqueMedia), [uniqueMedia, sort]);
  const paginatedMedia = useMemo(() => sortedMedia.slice(PER_PAGE * (page - 1), PER_PAGE * page), [sortedMedia, page]);

  useEffect(() => setPage(1), [sort]);

  return (
    <Wrapper className={className}>
      <TopContainer>
        <Title>
          Movies and TV
        </Title>
        <Sort
          sortVar={actorPageSortVar}
          fields={sortFields}
        />
        <Pagination
          page={page}
          totalResults={sortedMedia.length}
          totalPages={Math.ceil(sortedMedia.length / PER_PAGE)}
          goToPage={setPage}
          hasSearched
        />
      </TopContainer>
      <Container>
        {loading && typeof media === 'undefined' && range(0, 20).map(n => (
          <MediaPlaceholder
            key={n}
            graph="image"
            rows={5}
          />
        ))}
        {paginatedMedia.map(singleMedia => {
          return (
            <Media
              media={singleMedia}
              key={singleMedia?.movieDbId}
            />
          )
        })}
      </Container>
      <Pagination
        page={page}
        totalResults={sortedMedia.length}
        totalPages={Math.ceil(sortedMedia.length / PER_PAGE)}
        goToPage={setPage}
        hasSearched
      />
    </Wrapper>
  );
};

export default PersonMediaList;
