import {makeVar, useReactiveVar} from "@apollo/client";
import {useCallback, useMemo} from "react";

export const modalState = makeVar<string | null>(null);

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

export const useModal = (givenId?: string) => {
  const state = useReactiveVar(modalState);

  const id = useMemo(() => givenId || uuidv4(), [givenId]);

  const open = useCallback(() => {
    modalState(id);
  }, [id]);

  const close = useCallback(() => {
    if (modalState() === id) {
      modalState(null);
    }
  }, [id]);

  return {
    id,
    close,
    open,
    isOpen: state === id,
  };
}
