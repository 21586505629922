import {AppPaths} from "./routing";
import copy from "copy-to-clipboard";
import {Notification} from "rsuite";
import {useCreateSharedLink} from "../api";
import styled from "styled-components";
import {useCallback} from "react";

const LinkDescription = styled.span`
  white-space: normal;
  word-break: break-all;
  margin-top: 0.5rem;
  display: inline-block;
`;

export const useCreateLink = () => {
  const {createSharedAccess} = useCreateSharedLink();
  return useCallback(async (listId: number) => {
    try {
      const result = await createSharedAccess(listId);
      if (result.data?.createSharedListAccess) {
        const link = encodeURI(`${window.location.origin}${AppPaths.CLAIM_LINK.buildPath(result.data.createSharedListAccess)}`);
        copy(link, {
          message: 'Press #{key} to copy link'
        });
        Notification.info({
          duration: 180 * 1000,
          title: <span>Shared Link Created</span>,
          description: (
            <div>
              <p>Shared link created and copied to clipboard</p>
              <div>
                <LinkDescription>{link}</LinkDescription>
              </div>
            </div>
          )
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [createSharedAccess]);
};
