import {GET_ALL_WATCH_PROVIDERS, GET_WATCH_PROVIDERS, LIST_WATCH_PROVIDERS} from "./queries";
import {useLazyQuery, useQuery} from "@apollo/client";
import {useEffect} from "react";
import {useExtractQueryValue} from "../utils";
import {
  getAllWatchProviders,
  getAllWatchProvidersVariables,
  getWatchProviders,
  getWatchProvidersVariables,
  listWatchProviders,
  listWatchProvidersVariables,
  WatchProviderInputType,
  WatchProviderType,
} from "../../graphql-types";
import {useBuiltUserSettings} from "../UserSettings/userUserSettingsQueries";

export const useCurrentMediaWatchProviders = (type: WatchProviderInputType, id: number | undefined,) => {
  const [doFetch, result] = useLazyQuery<getWatchProviders, getWatchProvidersVariables>(GET_WATCH_PROVIDERS, {
    fetchPolicy: 'network-only',
  });
  const {data: userSettings} = useBuiltUserSettings();

  useEffect(() => {
    if (typeof id !== 'undefined') {
      doFetch({
        variables: {
          locale: userSettings.locale,
          id,
          mediaType: type,
        }
      });
    }
  }, [
    doFetch,
    id,
    userSettings.locale,
    type,
  ]);

  return useExtractQueryValue(result, 'watchProviders');
};

export const useAllWatchProviders = () => {
  const {data: userSettings} = useBuiltUserSettings();
  const result = useQuery<getAllWatchProviders, getAllWatchProvidersVariables>(GET_ALL_WATCH_PROVIDERS, {
    fetchPolicy: 'network-only',
    variables: {
      locale: userSettings.locale,
    }
  });
  return useExtractQueryValue(result, 'allWatchProviders');
};

export const useListWatchProviders = () => {
  const {data: userSettings} = useBuiltUserSettings();
  return useExtractQueryValue(
    useQuery<listWatchProviders, listWatchProvidersVariables>(LIST_WATCH_PROVIDERS, {
      fetchPolicy: 'network-only',
      variables: {
        locale: userSettings.locale,
        type: WatchProviderType.FLATRATE,
      }
    }),
    'listWatchProviders'
  );
};
