import {createGlobalStyle, css, ThemeContext} from "styled-components";
import {useContext, useMemo} from "react";
import {
  darkenColour,
  getBackgroundColour, getDropdownBackground, getEvaluatedDropdownBackgroundColour,
  isDark, lightenColour,
} from "./utils";
import {breakpoint, ThemeShape} from "./index";

const commonStyles = css`
  ${breakpoint('tablet')} {
    html {
      font-size: 14px;
    }
  }

  ${breakpoint('mobile')} {
    html {
      font-size: 13px;
    }
  }
  
  .good-word-break {
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
`;

const DarkStyles = createGlobalStyle`
  ${commonStyles}

  .rs-dropdown-menu {
    background: var(--colour-70);
  }

  .rs-modal-content .rs-dropdown-menu {
    background: ${({theme}) => lightenColour(getEvaluatedDropdownBackgroundColour(theme))};
  }

  .rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content,
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content:hover,
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:hover,
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content:focus,
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:focus {
    background-color: transparent !important;
  }
  
  .rs-modal-content {
    padding: 1rem;
  }

  .card,
  .card-content {
    ${getDropdownBackground};
  }
`;

const LightStyles = createGlobalStyle`
  ${commonStyles}

  .rs-navbar.rs-navbar-default {
    background-color: ${({theme}: { theme: ThemeShape }) => darkenColour(getBackgroundColour(theme), 0.05)}
  }
`;

export const GlobalStyles = () => {
  const theme = useContext(ThemeContext);
  const dark = useMemo(() => isDark(theme), [theme]);

  if (dark) {
    return <DarkStyles />;
  }
  return <LightStyles />;
};

export default GlobalStyles;
