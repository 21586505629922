const colourMap = {
  "--colour-0": "#575757",
  "--colour-1": "#ffffff",
  "--colour-2": "#1675e0",
  "--colour-3": "#0a5dc2",
  "--colour-4": "#004299",
  "--colour-5": "#e5e5ea",
  "--colour-6": "#c5c6c7",
  "--colour-7": "#fff9e6",
  "--colour-8": "#edfae1",
  "--colour-9": "#4caf50",
  "--colour-10": "#e9f5fe",
  "--colour-11": "#2196f3",
  "--colour-12": "#ffb300",
  "--colour-13": "#fde9ef",
  "--colour-14": "#f44336",
  "--colour-15": "#f2faff",
  "--colour-16": "#d9d9d9",
  "--colour-17": "#8e8e93",
  "--colour-18": "#f7f7fa",
  "--colour-19": "#a6a6a6",
  "--colour-20": "#eb3626",
  "--colour-21": "#d62915",
  "--colour-22": "#b81c07",
  "--colour-23": "#ff9800",
  "--colour-24": "#f59700",
  "--colour-25": "#e08e00",
  "--colour-26": "#c27e00",
  "--colour-27": "#ffca28",
  "--colour-28": "#f5c31d",
  "--colour-29": "#e0b412",
  "--colour-30": "#c29d08",
  "--colour-31": "#37ab3c",
  "--colour-32": "#22a12a",
  "--colour-33": "#0f9119",
  "--colour-34": "#00bcd4",
  "--colour-35": "#00b1cc",
  "--colour-36": "#00a0bd",
  "--colour-37": "#008aa6",
  "--colour-38": "#1787e8",
  "--colour-39": "#0d73d4",
  "--colour-40": "#045cb5",
  "--colour-41": "#673ab7",
  "--colour-42": "#5f2bb3",
  "--colour-43": "#531ba8",
  "--colour-44": "#470c99",
  "--colour-45": "#ffffff",
  "--colour-46": "#3498ff",
  "--colour-47": "#2589f5",
  "--colour-48": "#bf2008",
  "--colour-49": "#a81b02",
  "--colour-50": "#8a1700",
  "--colour-51": "#c98600",
  "--colour-52": "#b37a00",
  "--colour-53": "#946800",
  "--colour-54": "#c9a608",
  "--colour-55": "#b39504",
  "--colour-56": "#947e00",
  "--colour-57": "#10911d",
  "--colour-58": "#088216",
  "--colour-59": "#016e10",
  "--colour-60": "#0087a6",
  "--colour-61": "#00728f",
  "--colour-62": "#005870",
  "--colour-63": "#045dbd",
  "--colour-64": "#004da6",
  "--colour-65": "#003d87",
  "--colour-66": "#490c99",
  "--colour-67": "#41068a",
  "--colour-68": "#370075",
  "--colour-69": "#b2b1f0",
  "--colour-70": "#272c36",
  "--colour-71": "#1a8bff",
  "--colour-72": "#017eff",
  "--colour-73": "#f2f2f5",
  "--colour-74": "#a6d7ff",
  "--colour-75": "#085cc9",
  "--colour-76": "#024bb3",
  "--colour-77": "#003b94",
  "--colour-78": "#7a7a7a",
  "--colour-79": "#cce9ff",
  "--colour-80": "#000000"
};
export default colourMap;
