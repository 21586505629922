import {gql} from "@apollo/client";
import {TV_FIELDS} from "./fragments";

export const CREATE_TV_FROM_SEARCH = gql`
  mutation createTvFromSearch(
    $listId: Int!,
    $id: Int!,
    $locale: WatchProviderLocale!
  ) {
    createTvFromSearchResult(
      listId: $listId,
      tvId: $id,
      locale: $locale
    ) {
      id
      list {
        id
        tvShowCount
      }
      tv {
        ...TvFields
      }
    }
  }
  ${TV_FIELDS}
`;

export const CREATE_TV = gql`
  mutation createTv($id: Int!, $locale: WatchProviderLocale) {
    createTv(tvId: $id, locale: $locale) {
      id
    }
  }
`;

export const REMOVE_TV_FROM_LIST = gql`
  mutation removeTvFromList($tvId: Int!, $listId: Int!) {
    removeTvFromList(tvId: $tvId, listId: $listId) {
      id
      list {
        id
        tvShowCount
      }
      tv {
        ...TvFields
      }
    }
  }
  ${TV_FIELDS}
`;
