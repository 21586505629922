import {useExtractQueryValue} from "../utils";
import {useQuery} from "@apollo/client";
import {TIME_SPENT_WATCHING, WATCH_COMPLETION_STAT} from "./queries";
import {getTimeSpentWatchingStat, getWatchCompletionStat} from "../../graphql-types";

export const useWatchCompletionStat = () => {
  return useExtractQueryValue(
    useQuery<getWatchCompletionStat, {}>(WATCH_COMPLETION_STAT, {
      fetchPolicy: 'network-only',
    }),
    "stats"
  );
};

export const useTimeSpentWatchingStat = () => {
  return useExtractQueryValue(
    useQuery<getTimeSpentWatchingStat, {}>(TIME_SPENT_WATCHING, {
      fetchPolicy: 'network-only',
    }),
    'stats'
  )
};
