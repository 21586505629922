type NotNull<T> = T extends null ? never : T;

const isNotNull = (r: any): r is NotNull<typeof r> => {
  return r !== null;
};

export const notNull = <T>(results: (NotNull<T> | null)[]): NotNull<T>[] => {
  return results.filter(isNotNull);
}

type MaybeId = { id?: number | string | null | undefined };
type HasId = { id: number | string };

const hasIdHelper = <T extends HasId>(r: MaybeId): r is T => {
  return typeof r.id !== 'undefined' && r.id !== null;
};

export const hasId = <T extends MaybeId>(results: MaybeId[]): (HasId & T)[] => {
  return results.filter<HasId & T>(hasIdHelper);
};
