import React from 'react';
import {useGetPersonDetails} from "../../api/PeopleDetails";
import PersonDetails from "./PersonDetails";
import PersonMediaList from "./PersonMediaList";
import styled from "styled-components";
import {breakpoint, ThemeProps} from "../../theme";
import {getAltBackgroundColour} from "../../utils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 6rem;
  margin-left: 5rem;
  margin-right: 5rem;
  align-items: flex-start;

  ${breakpoint('tablet')} {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  ${breakpoint('mobile')} {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const Details = styled(PersonDetails)`
  flex-basis: 20%;
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 2rem;
  background-color: ${({theme}: ThemeProps) => getAltBackgroundColour(theme)};
  padding: 1rem;
  border-radius: 0.25rem;

  ${breakpoint('tablet')} {
    width: 100%;
    margin-right: 0;
  }
`;

const MediaList = styled(PersonMediaList)`
  flex-basis: 70%;
  flex-shrink: 1;

  ${breakpoint('tablet')} {
    width: 100%;
  }
`;

type Props = {
  personId: number;
};

const PersonPage = ({personId}: Props) => {
  const {data: personDetails, loading} = useGetPersonDetails(personId);

  return (
    <Container>
      <Details
        person={personDetails?.peopleDetails}
        loading={loading}
      />
      <MediaList
        media={personDetails?.peopleCredits}
        loading={loading}
      />
    </Container>
  );
};

export default PersonPage;
