import {gql} from "@apollo/client";

export const SEARCH_RESULT_FIELDS = gql`
  fragment SearchResultFields on SearchResult {
    id
    type
    hasSeen
    backdropPath
    title
    name
    gender
    posterPath
    releaseDate
    overview
    originalTitle
    knownForDepartment
    profilePath
    popularity
  }
`;
