import {gql} from "@apollo/client";
import {WATCH_PROVIDER_FIELDS, WATCH_PROVIDER_MAPPING_FIELDS} from "./fragments";

export const GET_WATCH_PROVIDERS = gql`
  query getWatchProviders($id: Int!, $locale: WatchProviderLocale!, $mediaType: WatchProviderInputType!) {
    watchProviders(id: $id, locale: $locale, type: FLATRATE, mediaType: $mediaType) {
      ...WatchProviderMappingFields
      watchProvider {
        ...WatchProviderFields
      }
    }
  }
  ${WATCH_PROVIDER_FIELDS}
  ${WATCH_PROVIDER_MAPPING_FIELDS}
`;

export const GET_ALL_WATCH_PROVIDERS = gql`
  query getAllWatchProviders($locale: WatchProviderLocale!) {
    allWatchProviders(type: FLATRATE, locale: $locale) {
      ...WatchProviderFields
    }
  }
  ${WATCH_PROVIDER_FIELDS}
`;

export const LIST_WATCH_PROVIDERS = gql`
  query listWatchProviders($locale: WatchProviderLocale!, $type: WatchProviderType) {
    listWatchProviders(locale: $locale, type: $type) {
      ...WatchProviderFields
    }
  }
  ${WATCH_PROVIDER_FIELDS}
`;
