import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Nav, Navbar} from "rsuite";
import {useAuth0} from "@auth0/auth0-react";
import './style.css';
import styled from "styled-components";
import Profile from "../Profile";

const NavContainer = styled(Nav)`
  & > ul {
    display: flex;
    align-items: center;
  }
`;

const NavLink = ({href, onClick, children}: { href?: string, onClick?: () => void, children: any }) => {
  const history = useHistory();
  if (href) {
    return (
      <Nav.Item componentClass="div" onSelect={() => history.push(href)}>
        <Link to={href}>{children}</Link>
      </Nav.Item>
    );
  }
  return (
    <Nav.Item onSelect={onClick}>
      {children}
    </Nav.Item>
  );
};

export const AppNav = () => {
  const {isAuthenticated, loginWithRedirect} = useAuth0();

  return (
    <Navbar>
      <NavContainer>
        <NavLink href="/">Home</NavLink>
      </NavContainer>
      <NavContainer pullRight>
        {isAuthenticated && <Profile />}
        {!isAuthenticated && <NavLink onClick={() => loginWithRedirect()}>Login</NavLink>}
      </NavContainer>
    </Navbar>
  );
};
