import React, {useMemo} from 'react';
import {getColor, getContrastYIQ} from "../../utils";
import styled from "styled-components";
import {ThemeProps} from "../../theme";

const letterRegex = RegExp(/^\p{L}/, 'u');

const getInitials = (name: string) => {
  const parts = name
    .split('@')[0]
    .split(/[-._:;\\,=+|\s]/)
    .map(part => part[0])
    .filter(Boolean)
    .map(part => part.toUpperCase())
    .filter(part => letterRegex.test(part));
  if (parts.length > 0) return parts.splice(0, 3).join('');
  return '';
};

const Container = styled.div`
  background-color: ${({initials, theme}: ThemeProps & { initials: string }) => getColor(theme, initials)};
  color: ${({initials, theme}: ThemeProps & { initials: string }) => getContrastYIQ(getColor(theme, initials))};
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

type Props = {
  user: { firstName?: string, lastName?: string, name?: string } | null;
  className?: string;
};

const getPart = (str: string | undefined) => typeof str === 'string' ? [str] : [];

const UserAvatar = ({className = '', user}: Props) => {
  let nameString: string[] = [];
  if (user) {
    nameString = [
      ...getPart(user.firstName),
      ...getPart(user.lastName),
    ];
    if (nameString.length === 0) {
      nameString = [...getPart(user.name)];
    }
  }
  const str = nameString.join(' ');

  const ini = useMemo(() => getInitials(str), [str]);
  return (
    <Container
      className={className}
      initials={ini}
    >
      {ini}
    </Container>
  );
};

export default UserAvatar;
