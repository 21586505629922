import React from 'react';
import {useGetRecommendedMovies} from "../../api";
import {Button, Placeholder} from "rsuite";
import styled from "styled-components";
import {NonNull} from "../../utils/types";
import {getRecommendedMovies_recommendMovies} from "../../graphql-types";
import {useNavigate} from "../../utils";
import {breakpoint} from "../../theme";
import {range} from "ramda";

const Wrapper = styled.div`
  margin-top: 5rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: 2rem;
  }
  
  ${breakpoint('mobile')} {
    flex-direction: column;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }

  margin-top: 1rem;
`;

const Movie = styled(Button)`
  margin-bottom: 2rem;
  padding: 0;
  max-width: 150px;
  display: flex;
  flex-direction: column;
`;

const MovieTitle = styled.h6`
  padding: 0.5rem 1rem;
  white-space: normal;
  word-break: break-word;
  text-align: left;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type NonNullMovie = NonNull<getRecommendedMovies_recommendMovies>;

const MovieRecommend = () => {
  const {data: movies, loading} = useGetRecommendedMovies(7);
  const {goToMovie} = useNavigate();

  const nonNullMovies = (movies || []) as NonNullMovie[];

  return (
    <Wrapper>
      <h2>Recommended Movies</h2>
      {movies?.length === 0 && <p>You don't have any movies added yet.</p>}
      <Container>
        {!movies && loading && range(0, 7).map(n => (
          <Placeholder.Paragraph graph="image" key={n} />
        ))}
        {nonNullMovies.map(movie => (
          <Movie
            key={movie.id}
            className="card"
            onClick={() => goToMovie(movie.id)}
          >
            {movie.posterPath && movie.title && (
              <ImageContainer>
                <img src={movie.posterPath} alt={movie.title} />
              </ImageContainer>
            )}
            <MovieTitle>{movie.title}</MovieTitle>
          </Movie>
        ))}
      </Container>
    </Wrapper>
  );
};

export default MovieRecommend;
