import {createContext} from "react";
import {Media} from "./types";

type ContextType = {
  removeMedia: (media: Media) => void;
  toggleViewing: (media: Media) => void;
  goToMedia: (media: Media) => void;
};

export default createContext<ContextType>({
  removeMedia: () => {},
  toggleViewing: () => {},
  goToMedia: () => {},
});
