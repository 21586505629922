import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {getDropdownBackground, getForeground} from "../theme/utils";

const Container = styled.div`
  position: absolute;
  ${({direction}: { direction: 'left' | 'right' }) => direction === 'left' && `transform: translateX(-100%);`}
  width: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  z-index: 10;

  ${getDropdownBackground}
  ${getForeground}
`;

const isInElement = (e: MouseEvent, rect?: DOMRect) => {
  if (!rect) return false;
  return e.clientX > rect.x && e.clientX < rect.right && e.clientY > rect.top && e.clientY < rect.bottom;
};

type Props = {
  close: () => void;
  children: React.ReactNode;
  onClose?: () => void;
  isOpen?: boolean;
  direction?: 'left' | 'right';
}

const Popover = ({
  isOpen = true,
  close,
  children,
  onClose = () => {
  },
  direction = 'left',
}: Props) => {
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const onClick = (e: MouseEvent) => {
        if (!isInElement(e, containerRef.current?.getBoundingClientRect())) {
          onClose();
          close();
        }
      };

      document.addEventListener('click', onClick, true);
      return () => {
        document.removeEventListener('click', onClick, true);
      };
    }
  }, [close, onClose]);

  if (!isOpen) return null;

  return (
    <Container
      direction={direction}
      className="box p-1"
      ref={containerRef}
    >
      {children}
    </Container>
  );
};

export default Popover;
