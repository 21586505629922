import {formatDistance} from 'date-fns'
import {Optional} from "../components/types";

export const formatYear = (date: string) => {
  const theDate = new Date(date);
  return theDate.getFullYear();
};

export const convertToRuntime = (minutes: number) => {
  const remainingMinutes = minutes % 60;
  const hours = Math.floor(minutes / 60);
  return `${hours}h${remainingMinutes > 0 ? ` ${remainingMinutes}m` : ''}`;
};

const doCheck = <T>(date: Optional<string>, check: (d: string) => T, defaultValue: any = '') => {
  if (typeof date !== 'string') return defaultValue;
  return check(date);
};

export const formatDate = (date: Optional<string>) => {
  return doCheck(date, d => `${formatDistance(new Date(d), new Date())} ago`);
};

export const formatFullDate = (date: Optional<string>) => {
  return doCheck(date, d => new Date(d).toLocaleDateString());
};

export const isInFuture = (date: Optional<string>) => {
  return doCheck(date, d => new Date(d) > new Date(), false);
};

export const formatAge = (birthDate: string, deathDay: Optional<string>) => {
  const ageDifMs = ((deathDay && new Date(deathDay)) || new Date()).getTime() - new Date(birthDate).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
