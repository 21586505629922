import {gql} from "@apollo/client";
import {USER_FIELDS} from "./fragments";

export const SYNC_ME = gql`
  mutation syncMe {
    syncMe {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount {
    deleteAccount
  }
`;
