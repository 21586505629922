import {gql} from "@apollo/client";
import {USER_SETTING_FIELDS, USER_SETTINGS_BASE_FIELDS} from "./fragments";
import {WATCH_PROVIDER_FIELDS} from "../WatchProvider/fragments";

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($key: UserSettingsKey!, $value: Json!) {
    updateSettings(key: $key, value: $value) {
      ...UserSettingsFields
    }
  }
  ${USER_SETTING_FIELDS}
`;

export const ADD_WATCH_PROVIDER = gql`
  mutation addWatchProvider($watchProviderId: Int!) {
    addWatchProviderToUserSettings(watchProviderId: $watchProviderId) {
      ...UserSettingsBaseFields
      watchProviders {
        ...WatchProviderFields
      }
    }
  }
  ${USER_SETTINGS_BASE_FIELDS}
  ${WATCH_PROVIDER_FIELDS}
`;

export const REMOVE_WATCH_PROVIDER = gql`
  mutation removeWatchProvider($watchProviderId: Int!) {
    removeWatchProviderFromUserSettings(watchProviderId: $watchProviderId) {
      ...UserSettingsBaseFields
      watchProviders {
        ...WatchProviderFields
      }
    }
  }
  ${USER_SETTINGS_BASE_FIELDS}
  ${WATCH_PROVIDER_FIELDS}
`;
