import React, {useState} from 'react';
import {Button, Dropdown, Icon} from 'rsuite';
import {useAuth0} from "@auth0/auth0-react";
import styled from "styled-components";
import {useNavigate} from "../../utils";
import UserAvatar from "../UserAvatar";

const ProfileButton = styled(Button)`
  padding: 0;
  margin: 0.25rem;
  width: calc(40px + 0.5rem);
  height: calc(40px + 0.5rem);
  background: transparent;
  border-radius: 50%;
`;

const ProfileAvatar = styled(UserAvatar)`
  width: 100%;
  height: 100%;
`;

const Profile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {user, logout} = useAuth0();
  const {goToSettings} = useNavigate();

  return (
    <>
      <Dropdown
        placement="bottomEnd"
        renderTitle={() => (
          <ProfileButton
            onClick={() => setIsOpen(true)}
          >
            <ProfileAvatar
              user={{
                firstName: user.given_name,
                lastName: user.family_name,
                name: user.name,
              }}
            />
          </ProfileButton>
        )}
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <Dropdown.Item
          className="is-size-6"
          onSelect={goToSettings}
          icon={<Icon icon="cog" />}
        >
          Settings
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={logout}
          className="is-size-6"
          icon={<Icon icon="sign-out" />}
        >
          Logout
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default Profile;
