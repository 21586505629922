import {useMutation} from "@apollo/client";
import {
  createTvViewing, createTvViewingVariables,
  removeTvViewings as removeTvViewingsType, removeTvViewingsVariables,
} from "../../graphql-types";
import {useCallback} from "react";
import {CREATE_TV_VIEWING, REMOVE_TV_VIEWINGS} from "./mutations";

export const useCreateTvViewing = () => {
  const [doCreate, result] = useMutation<createTvViewing, createTvViewingVariables>(CREATE_TV_VIEWING);

  const create = useCallback((tvId: number) => {
    doCreate({
      variables: {
        tvId,
      }
    });
  }, [doCreate]);

  return {
    createTvViewing: create,
    ...result,
  }
};

export const useRemoveTvViewings = () => {
  const [doRemove, result] = useMutation<removeTvViewingsType, removeTvViewingsVariables>(REMOVE_TV_VIEWINGS);

  const remove = useCallback((tvId: number) => {
    doRemove({
      variables: {
        tvId,
      }
    });
  }, [doRemove]);

  return {
    removeTvViewings: remove,
    ...result,
  };
};
