import {gql} from "@apollo/client";

export const WATCH_PROVIDER_MAPPING_FIELDS = gql`
  fragment WatchProviderMappingFields on WatchProviderMapping {
    id
    locale
    type
  }
`;

export const WATCH_PROVIDER_FIELDS = gql`
  fragment WatchProviderFields on WatchProvider {
    id
    logoPath
    displayPriority
    providerName
    updatedAt
  }
`;