import {useCallback, useState} from "react";

export type ListState = {
  page: number;
  query: string;
};

export type SetListState = ({ page, query }: Partial<ListState>) => void;

const useListState = (): [ListState, SetListState] => {
  const [state, setState] = useState({
    page: 1,
    query: '',
  });

  return [
    state,
    useCallback((nextState) => {
      setState(prevState => ({
        ...prevState,
        ...nextState,
      }));
    }, []),
  ];
};

export default useListState;
