import {LazyQueryResult, QueryResult} from "@apollo/client";
import {useEffect, useMemo, useState} from "react";
import debounce from 'lodash.debounce';

type HookData<TData, TVariables> = QueryResult<TData, TVariables> | LazyQueryResult<TData, TVariables>;

export const useExtractQueryValue = <TData, TVariables, K extends keyof TData>(hookResult: HookData<TData, TVariables>, key: K) => {
  return useMemo(() => {
    const {data, ...rest} = hookResult;
    if (typeof data !== 'undefined') {
      return {data: data[key], ...rest};
    }
    return {
      data: undefined,
      ...rest,
    };
  }, [hookResult, key]);
};

type DebounceResult = ReturnType<typeof useExtractQueryValue>;

export const useDebounceResult = (hookResult: DebounceResult, selector = (hookResult: DebounceResult) => hookResult.data): typeof hookResult => {
  const [lastResult, setLastResult] = useState(hookResult);
  const setResult = useMemo(() => debounce(setLastResult, 2000), [setLastResult]);

  useEffect(() => {
    if (typeof selector(hookResult) === 'undefined') {
      setResult(hookResult);
    } else {
      setResult.cancel();
      setLastResult(hookResult);
    }
  }, [
    selector,
    lastResult,
    setResult,
    setLastResult,
    hookResult,
  ]);

  return lastResult;
};
