import {useExtractQueryValue} from "../utils";
import {USE_ME} from "./queries";
import {useMe} from "../../graphql-types";
import {useQuery} from "@apollo/client";

export const useGetMe = () => {
  return useExtractQueryValue(
    useQuery<useMe, {}>(USE_ME),
    'me'
  );
};
