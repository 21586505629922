import {useEffect, useState} from "react";
import {useAutoFocus} from "../utils";
import {Input} from "rsuite";
import Popover from "./Popover";

type Props = {
  close: () => void;
  label?: JSX.Element;
  initialValue: string;
  saveChanges: (newValue: string) => void;
}

const TextEditPopover = ({close, label, initialValue, saveChanges}: Props) => {
  const [tempValue, setTempValue] = useState(initialValue);
  const ref = useAutoFocus();

  useEffect(() => {
    const inputElement = ref.current;
    if (inputElement) {

      const onKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          close();
        }
        if (e.key === 'Enter') {
          saveChanges(tempValue);
          close();
        }
      };

      inputElement.addEventListener('keydown', onKeydown);
      return () => {
        inputElement.removeEventListener('keydown', onKeydown);
      };
    }
  }, [ref, tempValue, saveChanges, close]);

  return (
    <Popover close={close}>
      {label}
      <Input
        inputRef={ref}
        value={tempValue}
        onChange={setTempValue}
      />
    </Popover>
  );
};

export default TextEditPopover;
