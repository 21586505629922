import {ThemeProps, ThemeShape} from "./index";
import {BASE_BACKGROUND_COLOUR_KEY, BASE_TEXT_COLOUR_KEY} from "../utils";
import Color from "color";

export const getBackgroundColour = (theme: ThemeShape) => {
  return theme.colours.themeColours[BASE_BACKGROUND_COLOUR_KEY];
};

export const lightenColour = (colour: string, ratio = 0.3) => {
  return Color(colour).rgb().lighten(ratio).hex().toString();
};

export const darkenColour = (colour: string, ratio = 0.3) => {
  return Color(colour).rgb().darken(ratio).hex().toString();
};

export const darkenBackground = ({ theme }: ThemeProps) => {
  return darkenColour(theme.colours.themeColours[BASE_BACKGROUND_COLOUR_KEY]);
};

export const isDark = (theme: ThemeShape) => {
  return Color(getBackgroundColour(theme)).isDark();
};

export const getEvaluatedDropdownBackgroundColour = (theme: ThemeShape) => {
  if (isDark(theme)) {
    return theme.colours.themeColours['--colour-70'];
  }
  return theme.colours.themeColours['--colour-1'];
};

export const getDropdownBackgroundColour = () => {
  return 'var(--colour-70)';
}

export const getDropdownBackground = ({ theme }: ThemeProps) => {
  if (isDark(theme)) {
    return `background-color: ${getDropdownBackgroundColour()} !important;`;
  }
  return `background-color: var(--colour-1) !important;`;
};

export const getForegroundColour = (theme: ThemeShape) => {
  return theme.colours.themeColours[BASE_TEXT_COLOUR_KEY];
};

export const getForeground = ({ theme }: ThemeProps) => {
  return `color: ${getForegroundColour(theme)} !important;`;
};
