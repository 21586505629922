import {gql} from "@apollo/client";
import {LIST_ACCESS_FIELDS, LIST_FIELDS} from "./fragments";
import {USER_FIELDS} from "../User/fragments";
import {MOVIE_FIELDS} from "../Movie/fragments";
import {TV_FIELDS} from "../Tv/fragments";

export const GET_LIST = gql`
  query getList($where: ListWhereUniqueInput!) {
    list(where: $where) {
      ...ListFields
      listAccesses {
        ...ListAccessFields
        user {
          ...UserFields
        }
      }
    }
  }
  ${LIST_FIELDS}
  ${LIST_ACCESS_FIELDS}
  ${USER_FIELDS}
`;

export const GET_LISTS = gql`
  query getLists($orderBy: [ListInputFilters!]) {
    lists(orderBy: $orderBy) {
      ...ListFields
    }
  }
  ${LIST_FIELDS}
`;

export const GET_LISTS_WITH_MEDIA = gql`
  query getListsWithMedia(
    $movieWhere: MovieWhereInput,
    $tvWhere: TvWhereInput
  ) {
    lists {
      ...ListFields
      movies(where: { movie: $movieWhere }) {
        movie {
          ...MovieFields
        }
      }
      tv(where: { tv: $tvWhere }) {
        tv {
          ...TvFields
        }
      }
    }
  }
  ${LIST_FIELDS}
  ${MOVIE_FIELDS}
  ${TV_FIELDS}
`;
