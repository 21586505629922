import {useBuiltUserSettings} from "../../api/UserSettings/userUserSettingsQueries";
import styled, {css, keyframes} from "styled-components";
import React, {useEffect, useState} from "react";
import {Button, ButtonToolbar, SelectPicker} from "rsuite";
import {ColourSettingShape, getDefaultSettings, nonNull, usePrefersDarkTheme, UserSettings,} from "../../utils";
import {UserSettingsKey, WatchProviderLocale} from "../../graphql-types";
import {useAddWatchProviderSetting, useRemoveWatchProviderSetting, useUpdateUserSettings} from "../../api";
import ColourPicker from "./ColourPicker";
import DefaultProviders from "./DefaultProviders";
import DeleteAccountModal from "./DeleteAccountModal";
import {useModal} from "../../stores";
import {Prompt} from "react-router-dom";
import {DARK_THEME_BACKGROUND} from "../../theme";
import iso3166 from 'iso-3166';

const validCountries = iso3166
  .filter(country => Object.values(WatchProviderLocale).includes(country.alpha2 as WatchProviderLocale))
  .map(country => ({
    value: country.alpha2,
    label: country.name,
  }));

const ProviderSelection = styled(SelectPicker)`
  margin-top: 1rem;
`;

export const SettingLabel = styled.label`
  font-weight: bold;
  margin-bottom: 0.25rem;
`;

export const SettingPair = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ConfirmDenyContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`;

const Container = styled.div`
  margin-top: 2rem;
`;

const SettingsSection = styled.div`
  margin-top: 2rem;
`;

const imageGlow = keyframes`
  from {
    box-shadow: 0 0 3px -3px var(--colour-74);
  }
  to {
    box-shadow: 0 0 3px 3px var(--colour-74);
  }
`;

const ConfirmButton = styled(Button)`
  ${({disabled}) => !disabled && css`
    animation: ${imageGlow} 0.5s infinite alternate;
  `}
`;

const Settings = () => {
  const {data: settings, loading: settingsLoading} = useBuiltUserSettings();
  const {updateSettings, loading: updateUserSettingsLoading} = useUpdateUserSettings();
  const {addProvider} = useAddWatchProviderSetting();
  const {removeProvider} = useRemoveWatchProviderSetting();
  const {id: deleteAccountModalId, open} = useModal();
  const prefersDarkTheme = usePrefersDarkTheme();

  const [hasInitialisedSettings, setHasInitialisedSettings] = useState(false);
  const [colourSetting, setColourSetting] = useState<ColourSettingShape>(settings.colour);
  const [providers, setProviders] = useState<UserSettings['watchProviders']>(settings.watchProviders);
  const [countryCode, setCountryCode] = useState(settings.locale);

  const getHasColoursChanged = () => Object.entries(colourSetting)
    .some(([key, value]) => settings.colour[key as keyof typeof settings.colour] !== value);

  const getHasProvidersChanged = () => {
    if (settings.watchProviders.length !== providers.length) return true;
    return !settings.watchProviders.every(
      provider => providers.some(p => p.id === provider.id)
    );
  };

  const getHasCountryCodeChanged = () => {
    return countryCode !== settings.locale;
  };

  const hasChanges = getHasColoursChanged() || getHasProvidersChanged() || getHasCountryCodeChanged();

  useEffect(() => {
    if (settingsLoading) {
      setHasInitialisedSettings(false);
    }
  }, [settingsLoading]);

  useEffect(() => {
    if (!hasInitialisedSettings && !settingsLoading && settings) {
      setColourSetting(settings.colour);
      setProviders(settings.watchProviders);
      setHasInitialisedSettings(true);
      setCountryCode(settings.locale);
    }
  }, [
    settings,
    settingsLoading,
    hasInitialisedSettings,
    setHasInitialisedSettings,
    setProviders,
    setColourSetting,
  ]);

  const setTemporaryColourValue = (colourSettingKey: keyof ColourSettingShape, colour: string) => {
    setColourSetting(prev => ({
      ...prev,
      [colourSettingKey]: colour,
    }));
  };

  const resetColours = () => {
    const defaultSettings = getDefaultSettings(false);
    if (
      !Object.entries(defaultSettings.colour).every(
        ([key, value]) => colourSetting[key as keyof typeof colourSetting] === value
      )
    ) {
      setColourSetting(defaultSettings.colour);
    }
  };

  const resetToDarkMode = () => {
    const defaultSettings = getDefaultSettings(prefersDarkTheme);
    setColourSetting({
      ...defaultSettings.colour,
      backgroundColour: DARK_THEME_BACKGROUND,
    });
  };

  const updateColourSettings = () => {
    return updateSettings(UserSettingsKey.COLOURS, colourSetting);
  };

  const updateCountryCode = () => {
    return updateSettings(UserSettingsKey.LOCALE, countryCode);
  };

  const updateProviders = () => {
    const newProviders = providers.filter(p => !settings.watchProviders.some(wp => wp.id === p.id));
    const removedProviders = settings.watchProviders.filter(wp => !providers.some(p => p.id === wp.id));
    let promise: Promise<any> = Promise.resolve();
    newProviders.forEach(provider => {
      promise = promise.then(() => {
        if (nonNull(provider.id)) {
          return addProvider(provider.id);
        }
      });
    });
    removedProviders.forEach(provider => {
      promise = promise.then(() => {
        if (nonNull(provider.id)) {
          return removeProvider(provider.id);
        }
      });
    });
    return promise;
  };

  const toggleProvider = (provider: UserSettings['watchProviders'][number]) => {
    setProviders(prevProviders => [
      ...prevProviders.filter(p => p.id !== provider.id),
      ...(prevProviders.some(p => p.id === provider.id) ? [] : [provider]),
    ]);
  };

  const confirmChanges = () => {
    let promise: Promise<any> = Promise.resolve();
    if (getHasColoursChanged()) {
      promise = promise.then(updateColourSettings);
    }
    if (getHasProvidersChanged()) {
      promise = promise.then(updateProviders);
    }
    if (getHasCountryCodeChanged()) {
      promise = promise.then(updateCountryCode);
    }
    return promise;
  };

  const cancelChanges = () => {
    setColourSetting(settings.colour);
    setProviders(settings.watchProviders);
    setCountryCode(settings.locale);
  };

  return (
    <Container>
      <Prompt
        when={hasChanges}
        message={() =>
          'You\'ve got unsaved changes, are you sure you want to leave?'
        }
      />

      <SettingsSection>
        <h4>Country Code</h4>
        <p>The Country Code which is used for checking which streaming services are available.</p>
        {hasInitialisedSettings && (
          <ProviderSelection
            data={validCountries}
            cleanable={false}
            value={countryCode}
            defaultValue={countryCode}
            style={{width: 300}}
            onChange={setCountryCode}
          />
        )}
      </SettingsSection>
      <SettingsSection>
        <h4>Default Streaming Providers</h4>
        <p>This option decides which streaming services are used to provide movie recommendations.</p>
        <p>Streaming Provider information is provided by JustWatch.</p>
        <SettingPair>
          <DefaultProviders
            providers={providers}
            toggleProvider={toggleProvider}
          />
        </SettingPair>
      </SettingsSection>
      <SettingsSection>
        <h4>Theme</h4>
        <p>Change your theme for Movie Manage.</p>
        <SettingPair>
          <SettingLabel>Primary Colour</SettingLabel>
          <ColourPicker
            colour={colourSetting.primaryColour}
            setColour={colour => setTemporaryColourValue('primaryColour', colour)}
          />
        </SettingPair>
        <SettingPair>
          <SettingLabel>Background Colour</SettingLabel>
          <ColourPicker
            colour={colourSetting.backgroundColour}
            setColour={colour => setTemporaryColourValue('backgroundColour', colour)}
          />
        </SettingPair>
        <SettingPair>
          <SettingLabel>Reset Colour Scheme</SettingLabel>
          <ButtonToolbar>
            <Button onClick={resetColours}>
              Reset
            </Button>
            <Button onClick={resetToDarkMode}>
              Reset to Dark Mode
            </Button>
          </ButtonToolbar>
        </SettingPair>
      </SettingsSection>
      <SettingsSection>
        <ConfirmDenyContainer>
          <ButtonToolbar>
            <Button onClick={cancelChanges}>
              Cancel
            </Button>
            <ConfirmButton
              appearance="primary"
              onClick={confirmChanges}
              disabled={!hasChanges}
              loading={updateUserSettingsLoading}
            >
              Confirm Changes
            </ConfirmButton>
          </ButtonToolbar>
        </ConfirmDenyContainer>
      </SettingsSection>
      <SettingsSection>

        <DeleteAccountModal id={deleteAccountModalId} />

        <h4>Delete Account</h4>
        <p>Deleting your account will delete all lists that you own, and cannot be undone.</p>
        <SettingPair>
          <Button
            color="red"
            onClick={open}
          >
            Delete Account
          </Button>
        </SettingPair>
      </SettingsSection>
    </Container>
  );
};

export default Settings;
