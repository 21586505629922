import {gql} from "@apollo/client";

export const PEOPLE_DETAILS_FIELDS = gql`
  fragment PeopleDetailsFields on PeopleDetails {
    birthday
    knownForDepartment
    deathday
    movieDbId
    name
    alsoKnownAs
    gender
    biography
    popularity
    placeOfBirth
    profilePath
    adult
    imdbId
    homepage
  }
`;

export const PERSON_MEDIA_FIELDS = gql`
  fragment PersonMediaFields on PersonMedia {
    movieDbId
    originalLanguage
    episodeCount
    overview
    originCountry
    originalName
    genreIds
    name
    mediaType
    posterPath
    firstAirDate
    voteAverage
    voteCount
    character
    backdropPath
    popularity
    creditId
    originalTitle
    video
    releaseDate
    title
    adult
    job
    department
  }
`;
