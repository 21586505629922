import React from 'react';
import {Icon, IconButton} from "rsuite";
import {getImdbUrl} from "../utils";
import {useTruncateText} from "./TruncateText";
import styled from "styled-components";

type Props = {
  imdbId: string;
  title: string;
  type?: 'title' | 'name';
};

const ImdbButton = styled(IconButton)`
  max-width: 100%;
  height: auto !important;
`;

const Button = ({ imdbId, title, type = 'title' }: Props) => {
  const {truncated: titleTruncated} = useTruncateText(title || '', 30);
  return (
    <ImdbButton
      className="mt-4 good-word-break good-word-break"
      target="_blank"
      rel="noopener"
      appearance="default"
      icon={<Icon icon="link" />}
      href={getImdbUrl(imdbId, type)}
    >
      {titleTruncated} on IMDb
    </ImdbButton>
  );
};

export default Button;
