import {gql} from "@apollo/client";
import {MOVIE_FIELDS} from "./fragments";
import {MOVIE_VIEWING_FIELDS} from "../MovieViewing/fragments";
import {WATCH_PROVIDER_FIELDS} from "../WatchProvider/fragments";
import {LIST_FIELDS} from "../List/fragments";

export const GET_MOVIES_FOR_LIST = gql`
  query getMoviesForList(
    $listId: Int!,
    $orderBy: MovieOrderByInput!,
    $locale: String,
    $viewStatus: ListMediaType,
    $providerLocale: WatchProviderLocale,
    $providerIds: [Int!],
    $page: Int,
    $pageSize: Int
    $query: String!
  ) {
    listMovies(
      listId: $listId,
      orderBy: $orderBy,
      viewStatus: $viewStatus,
      providerIds: $providerIds,
      locale: $providerLocale,
      page: $page,
      pageSize: $pageSize,
      query: $query
    ) {
      page
      pages
      totalResults
      data {
        ...MovieFields
        lists(where: {list: {id: { equals: $listId } } }) {
          createdAt
          list {
            id
          }
        }
        movieViewings {
          ...MovieViewingFields
        }
        watchProviderMappings(where: { locale: { equals: $locale }, AND: { type: { equals: FLATRATE } } }) {
          watchProvider {
            ...WatchProviderFields
          }
        }
      }
    }
  }
  ${MOVIE_FIELDS}
  ${MOVIE_VIEWING_FIELDS}
  ${WATCH_PROVIDER_FIELDS}
`;

export const GET_MOVIE = gql`
  query getMovie($movieId: Int!) {
    movie(where: {id: $movieId}) {
      ...MovieFields
      lists {
        id
        createdAt
        list {
          id
          name
          createdAt
          updatedAt
        }
      }
      movieViewings {
        ...MovieViewingFields
      }
    }
  }
  ${MOVIE_FIELDS}
  ${MOVIE_VIEWING_FIELDS}
`;

export const GET_MOVIES_BY_PROVIDERS = gql`  
  query getMoviesByProviders(
    $providerIds: [Int!]!,
    $locale: WatchProviderLocale!,
    $stringLocale: String!,
    $listId: Int
  ) {
    moviesByProvider(locale: $locale, providerIds: $providerIds, type: FLATRATE, listId: $listId) {
      ...MovieFields
      watchProviderMappings(where: { type: { equals: FLATRATE }, AND: {locale: { equals: $stringLocale } } }) {
        watchProvider {
          ...WatchProviderFields
        }
      }
      movieViewings {
        ...MovieViewingFields
      }
      lists {
        list {
          ...ListFields
        }
      }
    }
  }
  ${MOVIE_FIELDS}
  ${WATCH_PROVIDER_FIELDS}
  ${MOVIE_VIEWING_FIELDS}
  ${LIST_FIELDS}
`;

export const GET_RECOMMENDED_MOVIES = gql`
  query getRecommendedMovies(
    $locale: WatchProviderLocale!,
    $providerIds: [Int!]!,
    $numberToRecommend: Int!
  ) {
    recommendMovies(
      locale: $locale,
      numberToRecommend: $numberToRecommend,
      providerIds: $providerIds
    ) {
      ...MovieFields
    }
  }
  ${MOVIE_FIELDS}
`;

export const GET_MOVIE_MOVIE_DB_IDS = gql`
  query getMovieMovieDbIds($listId: Int!) {
    movies(where: { lists: { some: { list: { id: { equals: $listId } } } } }) {
      movieDbId
    }
  }
`;
