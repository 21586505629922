import React, {useContext} from 'react';
import {Optional} from "./types";
import {renderOn, roundVote} from "../utils";
import {ThemeContext} from "styled-components";
import PercentageCircle from "./PercentageCircle";
import Color from "color";

const interpolate = (a: string, b: string, t: number) => {
  const scaleDown = (v: number, i: number) => v / (i === 0 ? 360 : 100);
  const scaleUp = (v: number, i: number) => v * (i === 0 ? 360 : 100);

  const c1 = Color(a).hsl().array().map(scaleDown);
  const c2 = Color(b).hsl().array().map(scaleDown);

  let h: number = 0;
  let d = c2[0] - c1[0];
  if (a[0] > b[0]) {
    // Swap (a.h, b.h)
    const h3 = c2[0];
    c2[0] = c1[0];
    c1[0] = h3;

    d = -d;
    t = 1 - t;
  }

  if (d > 0.5) {
    c1[0] = c1[0] + 1; // 360deg
    h = (c1[0] + t * (c2[0] - c1[0])) % 1; // 360deg
  }
  if (d <= 0.5) {
    h = c1[0] + t * d;
  }

  const values = [
    h,
    c1[1] + t * (c2[1] - c1[1]),
    (c1[2] + t * (c2[2] - c1[2]))
  ]
    .map(scaleUp);

  return Color.hsl(...values)
    .hex()
    .toString()
};

type Props = {
  voteAverage: Optional<number>;
  size?: number;
};

const MediaVote = ({voteAverage, size = 60}: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <>
      {renderOn(
        voteAverage,
        vote => {
          const voteRounded = roundVote(vote);
          if (voteRounded === 0) return <>{null}</>;
          const background = interpolate(theme.colours.palette.red, theme.colours.palette.green, voteRounded / 100);
          return (
            <PercentageCircle
              colour={background}
              percentage={voteRounded}
              size={size}
            />
          )
        }
      )}
    </>
  );
};

export default MediaVote;
