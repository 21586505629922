import {MOVIE_FIELDS} from "./fragments";
import {gql} from "@apollo/client";

export const CREATE_MOVIE_FROM_SEARCH = gql`
  mutation createMovieFromSearchResult($id: Int!, $listId: Int!, $locale: WatchProviderLocale) {
    createMovieFromSearchResult(movieId: $id, listId: $listId, locale: $locale) {
      id
      list {
        id
        movieCount
      }
      movie {
        ...MovieFields
      }
    }
  }
  ${MOVIE_FIELDS}
`;

export const CREATE_MOVIE = gql`
  mutation createMovie($id: Int!, $locale: WatchProviderLocale) {
    createMovie(movieId: $id, locale: $locale) {
      id
    }
  }
`;

export const REMOVE_MOVIE_FROM_LIST = gql`
  mutation removeMovieFromList($movieId: Int!, $listId: Int!) {
    removeMovieFromList(movieId: $movieId, listId: $listId) {
      id
      list {
        id
        movieCount
      }
      movie {
        ...MovieFields
      }
    }
  }
  ${MOVIE_FIELDS}
`;
