import React, {useEffect, useState} from 'react';
import createClient from "./apollo";
import {ApolloClient, ApolloProvider} from "@apollo/client";
import {useAuth0} from "@auth0/auth0-react";

const GraphqlReactLink = ({children}: { children: React.ReactNode }) => {
  const {isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();
  const [apolloClient, setApolloClient] = useState<ApolloClient<any> | null>(null);

  useEffect(() => {
    if (!isLoading) {
      const getAccessToken = async () => {
        try {
          const config = (window as any).movieManageAppConfig
          return await getAccessTokenSilently({
            audience: config.audience,
            scope: 'all',
          });
        } catch (e) {
          console.error('failed to get access token', e);
          return null;
        }
      }
      setApolloClient(createClient(
        isAuthenticated,
        getAccessToken,
      ));
    }
  }, [
    isAuthenticated,
    isLoading,
    setApolloClient,
    getAccessTokenSilently,
  ]);

  if (!apolloClient) {
    return null;
  }

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

export default GraphqlReactLink;
