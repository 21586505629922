import React, {useMemo} from 'react';
import {
  getMoviesByProviders_moviesByProvider_watchProviderMappings_watchProvider
} from "../graphql-types";
import {sortProviders} from "../utils";
import {NonNull} from "../utils/types";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  & > img {
    margin-right: 0.5rem;
  }
`;

type Props = {
  providers: NonNull<getMoviesByProviders_moviesByProvider_watchProviderMappings_watchProvider>[];
}

const ProviderLogos = ({providers}: Props) => {
  const sortedProviders = useMemo(() => {
    const deduped = providers.filter((provider, i) => providers.indexOf(provider) === i);
    return sortProviders(deduped);
  }, [providers]);

  return (
    <Container>
      {sortedProviders.map(provider => (
        <img
          key={provider.id}
          className="image is-32x32"
          title={provider.providerName}
          src={provider.logoPath}
          alt={provider.providerName}
        />
      ))}
    </Container>
  );
};

export default ProviderLogos;
