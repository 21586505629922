/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createList
// ====================================================

export interface createList_createList {
  __typename: "List";
  id: number;
  name: string;
  createdAt: any;
  updatedAt: any;
  movieCount: number | null;
  tvShowCount: number | null;
}

export interface createList {
  createList: createList_createList | null;
}

export interface createListVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteList
// ====================================================

export interface deleteList_deleteOneList {
  __typename: "List";
  id: number;
}

export interface deleteList {
  deleteOneList: (deleteList_deleteOneList | null)[] | null;
}

export interface deleteListVariables {
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateListName
// ====================================================

export interface updateListName_updateListName {
  __typename: "List";
  id: number;
  name: string;
}

export interface updateListName {
  updateListName: updateListName_updateListName | null;
}

export interface updateListNameVariables {
  listId: number;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createSharedLink
// ====================================================

export interface createSharedLink {
  createSharedListAccess: string | null;
}

export interface createSharedLinkVariables {
  listId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: redeemSharedLink
// ====================================================

export interface redeemSharedLink_redeemInvite {
  __typename: "List";
  id: number;
  name: string;
  createdAt: any;
  updatedAt: any;
  movieCount: number | null;
  tvShowCount: number | null;
}

export interface redeemSharedLink {
  redeemInvite: redeemSharedLink_redeemInvite | null;
}

export interface redeemSharedLinkVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeListAccess
// ====================================================

export interface removeListAccess {
  removeSharedListAccess: boolean;
}

export interface removeListAccessVariables {
  listAccessId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getList
// ====================================================

export interface getList_list_listAccesses_user {
  __typename: "User";
  id: number;
  firstName: string;
  lastName: string;
}

export interface getList_list_listAccesses {
  __typename: "ListAccess";
  id: number;
  accessType: ListAccessType;
  createdAt: any;
  updatedAt: any;
  user: getList_list_listAccesses_user;
}

export interface getList_list {
  __typename: "List";
  id: number;
  name: string;
  createdAt: any;
  updatedAt: any;
  movieCount: number | null;
  tvShowCount: number | null;
  listAccesses: getList_list_listAccesses[];
}

export interface getList {
  list: getList_list | null;
}

export interface getListVariables {
  where: ListWhereUniqueInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getLists
// ====================================================

export interface getLists_lists {
  __typename: "List";
  id: number;
  name: string;
  createdAt: any;
  updatedAt: any;
  movieCount: number | null;
  tvShowCount: number | null;
}

export interface getLists {
  lists: getLists_lists[];
}

export interface getListsVariables {
  orderBy?: ListInputFilters[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getListsWithMedia
// ====================================================

export interface getListsWithMedia_lists_movies_movie {
  __typename: "Movie";
  id: number;
  adult: boolean;
  backdropPath: string | null;
  budget: number;
  genres: any;
  homepage: string | null;
  imdbId: string | null;
  movieDbId: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  revenue: number;
  runtime: number | null;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  video: boolean;
  voteAverage: number;
  voteCount: number;
}

export interface getListsWithMedia_lists_movies {
  __typename: "ListsOnMovie";
  movie: getListsWithMedia_lists_movies_movie;
}

export interface getListsWithMedia_lists_tv_tv {
  __typename: "Tv";
  id: number;
  backdropPath: string | null;
  createdAt: any;
  genres: any;
  homepage: string | null;
  movieDbId: number;
  numberOfEpisodes: number;
  numberOfSeasons: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  updatedAt: any;
  voteAverage: number;
  voteCount: number;
}

export interface getListsWithMedia_lists_tv {
  __typename: "ListsOnTv";
  tv: getListsWithMedia_lists_tv_tv;
}

export interface getListsWithMedia_lists {
  __typename: "List";
  id: number;
  name: string;
  createdAt: any;
  updatedAt: any;
  movieCount: number | null;
  tvShowCount: number | null;
  movies: getListsWithMedia_lists_movies[];
  tv: getListsWithMedia_lists_tv[];
}

export interface getListsWithMedia {
  lists: getListsWithMedia_lists[];
}

export interface getListsWithMediaVariables {
  movieWhere?: MovieWhereInput | null;
  tvWhere?: TvWhereInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: mediaSearch
// ====================================================

export interface mediaSearch_mediaSearch_data {
  __typename: "SearchResult";
  id: number | null;
  type: SearchResultType | null;
  hasSeen: boolean | null;
  backdropPath: string | null;
  title: string | null;
  name: string | null;
  gender: number | null;
  posterPath: string | null;
  releaseDate: string | null;
  overview: string | null;
  originalTitle: string | null;
  knownForDepartment: string | null;
  profilePath: string | null;
  popularity: number | null;
}

export interface mediaSearch_mediaSearch {
  __typename: "SearchResults";
  data: (mediaSearch_mediaSearch_data | null)[] | null;
  page: number | null;
  totalPages: number | null;
  totalResults: number | null;
}

export interface mediaSearch {
  mediaSearch: mediaSearch_mediaSearch | null;
}

export interface mediaSearchVariables {
  query: string;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMovieFromSearchResult
// ====================================================

export interface createMovieFromSearchResult_createMovieFromSearchResult_list {
  __typename: "List";
  id: number;
  movieCount: number | null;
}

export interface createMovieFromSearchResult_createMovieFromSearchResult_movie {
  __typename: "Movie";
  id: number;
  adult: boolean;
  backdropPath: string | null;
  budget: number;
  genres: any;
  homepage: string | null;
  imdbId: string | null;
  movieDbId: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  revenue: number;
  runtime: number | null;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  video: boolean;
  voteAverage: number;
  voteCount: number;
}

export interface createMovieFromSearchResult_createMovieFromSearchResult {
  __typename: "ListsOnMovie";
  id: string;
  list: createMovieFromSearchResult_createMovieFromSearchResult_list;
  movie: createMovieFromSearchResult_createMovieFromSearchResult_movie;
}

export interface createMovieFromSearchResult {
  createMovieFromSearchResult: createMovieFromSearchResult_createMovieFromSearchResult | null;
}

export interface createMovieFromSearchResultVariables {
  id: number;
  listId: number;
  locale?: WatchProviderLocale | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMovie
// ====================================================

export interface createMovie_createMovie {
  __typename: "Movie";
  id: number;
}

export interface createMovie {
  createMovie: createMovie_createMovie | null;
}

export interface createMovieVariables {
  id: number;
  locale?: WatchProviderLocale | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeMovieFromList
// ====================================================

export interface removeMovieFromList_removeMovieFromList_list {
  __typename: "List";
  id: number;
  movieCount: number | null;
}

export interface removeMovieFromList_removeMovieFromList_movie {
  __typename: "Movie";
  id: number;
  adult: boolean;
  backdropPath: string | null;
  budget: number;
  genres: any;
  homepage: string | null;
  imdbId: string | null;
  movieDbId: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  revenue: number;
  runtime: number | null;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  video: boolean;
  voteAverage: number;
  voteCount: number;
}

export interface removeMovieFromList_removeMovieFromList {
  __typename: "ListsOnMovie";
  id: string;
  list: removeMovieFromList_removeMovieFromList_list;
  movie: removeMovieFromList_removeMovieFromList_movie;
}

export interface removeMovieFromList {
  removeMovieFromList: removeMovieFromList_removeMovieFromList | null;
}

export interface removeMovieFromListVariables {
  movieId: number;
  listId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMoviesForList
// ====================================================

export interface getMoviesForList_listMovies_data_lists_list {
  __typename: "List";
  id: number;
}

export interface getMoviesForList_listMovies_data_lists {
  __typename: "ListsOnMovie";
  createdAt: any;
  list: getMoviesForList_listMovies_data_lists_list;
}

export interface getMoviesForList_listMovies_data_movieViewings {
  __typename: "MovieViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
}

export interface getMoviesForList_listMovies_data_watchProviderMappings_watchProvider {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

export interface getMoviesForList_listMovies_data_watchProviderMappings {
  __typename: "WatchProviderMapping";
  watchProvider: getMoviesForList_listMovies_data_watchProviderMappings_watchProvider | null;
}

export interface getMoviesForList_listMovies_data {
  __typename: "Movie";
  id: number;
  adult: boolean;
  backdropPath: string | null;
  budget: number;
  genres: any;
  homepage: string | null;
  imdbId: string | null;
  movieDbId: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  revenue: number;
  runtime: number | null;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  video: boolean;
  voteAverage: number;
  voteCount: number;
  lists: getMoviesForList_listMovies_data_lists[];
  movieViewings: getMoviesForList_listMovies_data_movieViewings[];
  watchProviderMappings: getMoviesForList_listMovies_data_watchProviderMappings[];
}

export interface getMoviesForList_listMovies {
  __typename: "PaginatedListMovies";
  page: number;
  pages: number;
  totalResults: number;
  data: getMoviesForList_listMovies_data[];
}

export interface getMoviesForList {
  listMovies: getMoviesForList_listMovies | null;
}

export interface getMoviesForListVariables {
  listId: number;
  orderBy: MovieOrderByInput;
  locale?: string | null;
  viewStatus?: ListMediaType | null;
  providerLocale?: WatchProviderLocale | null;
  providerIds?: number[] | null;
  page?: number | null;
  pageSize?: number | null;
  query: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMovie
// ====================================================

export interface getMovie_movie_lists_list {
  __typename: "List";
  id: number;
  name: string;
  createdAt: any;
  updatedAt: any;
}

export interface getMovie_movie_lists {
  __typename: "ListsOnMovie";
  id: string;
  createdAt: any;
  list: getMovie_movie_lists_list;
}

export interface getMovie_movie_movieViewings {
  __typename: "MovieViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
}

export interface getMovie_movie {
  __typename: "Movie";
  id: number;
  adult: boolean;
  backdropPath: string | null;
  budget: number;
  genres: any;
  homepage: string | null;
  imdbId: string | null;
  movieDbId: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  revenue: number;
  runtime: number | null;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  video: boolean;
  voteAverage: number;
  voteCount: number;
  lists: getMovie_movie_lists[];
  movieViewings: getMovie_movie_movieViewings[];
}

export interface getMovie {
  movie: getMovie_movie | null;
}

export interface getMovieVariables {
  movieId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMoviesByProviders
// ====================================================

export interface getMoviesByProviders_moviesByProvider_watchProviderMappings_watchProvider {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

export interface getMoviesByProviders_moviesByProvider_watchProviderMappings {
  __typename: "WatchProviderMapping";
  watchProvider: getMoviesByProviders_moviesByProvider_watchProviderMappings_watchProvider | null;
}

export interface getMoviesByProviders_moviesByProvider_movieViewings {
  __typename: "MovieViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
}

export interface getMoviesByProviders_moviesByProvider_lists_list {
  __typename: "List";
  id: number;
  name: string;
  createdAt: any;
  updatedAt: any;
  movieCount: number | null;
  tvShowCount: number | null;
}

export interface getMoviesByProviders_moviesByProvider_lists {
  __typename: "ListsOnMovie";
  list: getMoviesByProviders_moviesByProvider_lists_list;
}

export interface getMoviesByProviders_moviesByProvider {
  __typename: "Movie";
  id: number;
  adult: boolean;
  backdropPath: string | null;
  budget: number;
  genres: any;
  homepage: string | null;
  imdbId: string | null;
  movieDbId: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  revenue: number;
  runtime: number | null;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  video: boolean;
  voteAverage: number;
  voteCount: number;
  watchProviderMappings: getMoviesByProviders_moviesByProvider_watchProviderMappings[];
  movieViewings: getMoviesByProviders_moviesByProvider_movieViewings[];
  lists: getMoviesByProviders_moviesByProvider_lists[];
}

export interface getMoviesByProviders {
  moviesByProvider: getMoviesByProviders_moviesByProvider[];
}

export interface getMoviesByProvidersVariables {
  providerIds: number[];
  locale: WatchProviderLocale;
  stringLocale: string;
  listId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRecommendedMovies
// ====================================================

export interface getRecommendedMovies_recommendMovies {
  __typename: "Movie";
  id: number;
  adult: boolean;
  backdropPath: string | null;
  budget: number;
  genres: any;
  homepage: string | null;
  imdbId: string | null;
  movieDbId: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  revenue: number;
  runtime: number | null;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  video: boolean;
  voteAverage: number;
  voteCount: number;
}

export interface getRecommendedMovies {
  recommendMovies: getRecommendedMovies_recommendMovies[] | null;
}

export interface getRecommendedMoviesVariables {
  locale: WatchProviderLocale;
  providerIds: number[];
  numberToRecommend: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMovieMovieDbIds
// ====================================================

export interface getMovieMovieDbIds_movies {
  __typename: "Movie";
  movieDbId: number;
}

export interface getMovieMovieDbIds {
  movies: getMovieMovieDbIds_movies[];
}

export interface getMovieMovieDbIdsVariables {
  listId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMovieCredits
// ====================================================

export interface getMovieCredits_movieCredits_cast {
  __typename: "Actor";
  adult: boolean | null;
  creditId: string | null;
  gender: number | null;
  knownForDepartment: string | null;
  movieDbId: number | null;
  name: string | null;
  originalName: string | null;
  popularity: number | null;
  profilePath: string | null;
  castId: number | null;
  character: string | null;
  order: number | null;
}

export interface getMovieCredits_movieCredits_crew {
  __typename: "CrewMember";
  adult: boolean | null;
  creditId: string | null;
  gender: number | null;
  knownForDepartment: string | null;
  movieDbId: number | null;
  name: string | null;
  originalName: string | null;
  popularity: number | null;
  profilePath: string | null;
  department: string | null;
  job: string | null;
}

export interface getMovieCredits_movieCredits {
  __typename: "Credits";
  cast: (getMovieCredits_movieCredits_cast | null)[] | null;
  crew: (getMovieCredits_movieCredits_crew | null)[] | null;
}

export interface getMovieCredits {
  movieCredits: getMovieCredits_movieCredits | null;
}

export interface getMovieCreditsVariables {
  movieId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createMovieViewing
// ====================================================

export interface createMovieViewing_createMovieViewing_movie_movieViewings {
  __typename: "MovieViewing";
  id: number;
}

export interface createMovieViewing_createMovieViewing_movie {
  __typename: "Movie";
  id: number;
  movieViewings: createMovieViewing_createMovieViewing_movie_movieViewings[];
}

export interface createMovieViewing_createMovieViewing {
  __typename: "MovieViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
  movie: createMovieViewing_createMovieViewing_movie;
}

export interface createMovieViewing {
  createMovieViewing: createMovieViewing_createMovieViewing | null;
}

export interface createMovieViewingVariables {
  movieId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeMovieViewings
// ====================================================

export interface removeMovieViewings_removeMovieViewings_movieViewings {
  __typename: "MovieViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
}

export interface removeMovieViewings_removeMovieViewings {
  __typename: "Movie";
  id: number;
  movieViewings: removeMovieViewings_removeMovieViewings_movieViewings[];
}

export interface removeMovieViewings {
  removeMovieViewings: removeMovieViewings_removeMovieViewings | null;
}

export interface removeMovieViewingsVariables {
  movieId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getPeopleDetails
// ====================================================

export interface getPeopleDetails_peopleDetails {
  __typename: "PeopleDetails";
  birthday: string | null;
  knownForDepartment: string | null;
  deathday: string | null;
  movieDbId: number | null;
  name: string | null;
  alsoKnownAs: (string | null)[] | null;
  gender: number | null;
  biography: string | null;
  popularity: number | null;
  placeOfBirth: string | null;
  profilePath: string | null;
  adult: boolean | null;
  imdbId: string | null;
  homepage: string | null;
}

export interface getPeopleDetails_peopleCredits_lists {
  __typename: "List";
  id: number;
  name: string;
}

export interface getPeopleDetails_peopleCredits_viewings_TvViewing {
  __typename: "TvViewing";
  id: number;
  createdAt: any;
}

export interface getPeopleDetails_peopleCredits_viewings_MovieViewing {
  __typename: "MovieViewing";
  id: number;
  createdAt: any;
}

export type getPeopleDetails_peopleCredits_viewings = getPeopleDetails_peopleCredits_viewings_TvViewing | getPeopleDetails_peopleCredits_viewings_MovieViewing;

export interface getPeopleDetails_peopleCredits {
  __typename: "PersonMedia";
  movieDbId: number | null;
  originalLanguage: string | null;
  episodeCount: number | null;
  overview: string | null;
  originCountry: (string | null)[] | null;
  originalName: string | null;
  genreIds: (number | null)[] | null;
  name: string | null;
  mediaType: string | null;
  posterPath: string | null;
  firstAirDate: string | null;
  voteAverage: number | null;
  voteCount: number | null;
  character: string | null;
  backdropPath: string | null;
  popularity: number | null;
  creditId: string | null;
  originalTitle: string | null;
  video: boolean | null;
  releaseDate: string | null;
  title: string | null;
  adult: boolean | null;
  job: string | null;
  department: string | null;
  lists: (getPeopleDetails_peopleCredits_lists | null)[] | null;
  viewings: (getPeopleDetails_peopleCredits_viewings | null)[] | null;
}

export interface getPeopleDetails {
  peopleDetails: getPeopleDetails_peopleDetails | null;
  peopleCredits: (getPeopleDetails_peopleCredits | null)[] | null;
}

export interface getPeopleDetailsVariables {
  personId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRecentActivities
// ====================================================

export interface getRecentActivities_recentActivity_movie {
  __typename: "Movie";
  id: number;
  title: string;
  posterPath: string | null;
}

export interface getRecentActivities_recentActivity_tv {
  __typename: "Tv";
  id: number;
  title: string;
  posterPath: string | null;
}

export interface getRecentActivities_recentActivity_lists {
  __typename: "List";
  id: number;
  name: string;
}

export interface getRecentActivities_recentActivity_user {
  __typename: "User";
  id: number;
  firstName: string;
  lastName: string;
}

export interface getRecentActivities_recentActivity {
  __typename: "RecentActivity";
  date: any | null;
  details: string | null;
  type: RecentActivityType | null;
  movie: getRecentActivities_recentActivity_movie | null;
  tv: getRecentActivities_recentActivity_tv | null;
  lists: (getRecentActivities_recentActivity_lists | null)[] | null;
  user: getRecentActivities_recentActivity_user | null;
}

export interface getRecentActivities {
  recentActivity: (getRecentActivities_recentActivity | null)[] | null;
}

export interface getRecentActivitiesVariables {
  types: RecentActivityType[];
  listId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWatchCompletionStat
// ====================================================

export interface getWatchCompletionStat_stats_watchCompletion {
  __typename: "WatchCompletionStats";
  completion: number | null;
  tvCount: number | null;
  seenTvCount: number | null;
  movieCount: number | null;
  seenMovieCount: number | null;
}

export interface getWatchCompletionStat_stats {
  __typename: "Stats";
  watchCompletion: getWatchCompletionStat_stats_watchCompletion | null;
}

export interface getWatchCompletionStat {
  stats: getWatchCompletionStat_stats | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTimeSpentWatchingStat
// ====================================================

export interface getTimeSpentWatchingStat_stats {
  __typename: "Stats";
  timeSpentWatching: number | null;
}

export interface getTimeSpentWatchingStat {
  stats: getTimeSpentWatchingStat_stats | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTvFromSearch
// ====================================================

export interface createTvFromSearch_createTvFromSearchResult_list {
  __typename: "List";
  id: number;
  tvShowCount: number | null;
}

export interface createTvFromSearch_createTvFromSearchResult_tv {
  __typename: "Tv";
  id: number;
  backdropPath: string | null;
  createdAt: any;
  genres: any;
  homepage: string | null;
  movieDbId: number;
  numberOfEpisodes: number;
  numberOfSeasons: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  updatedAt: any;
  voteAverage: number;
  voteCount: number;
}

export interface createTvFromSearch_createTvFromSearchResult {
  __typename: "ListsOnTv";
  id: string;
  list: createTvFromSearch_createTvFromSearchResult_list;
  tv: createTvFromSearch_createTvFromSearchResult_tv;
}

export interface createTvFromSearch {
  createTvFromSearchResult: createTvFromSearch_createTvFromSearchResult | null;
}

export interface createTvFromSearchVariables {
  listId: number;
  id: number;
  locale: WatchProviderLocale;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTv
// ====================================================

export interface createTv_createTv {
  __typename: "Tv";
  id: number;
}

export interface createTv {
  createTv: createTv_createTv | null;
}

export interface createTvVariables {
  id: number;
  locale?: WatchProviderLocale | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeTvFromList
// ====================================================

export interface removeTvFromList_removeTvFromList_list {
  __typename: "List";
  id: number;
  tvShowCount: number | null;
}

export interface removeTvFromList_removeTvFromList_tv {
  __typename: "Tv";
  id: number;
  backdropPath: string | null;
  createdAt: any;
  genres: any;
  homepage: string | null;
  movieDbId: number;
  numberOfEpisodes: number;
  numberOfSeasons: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  updatedAt: any;
  voteAverage: number;
  voteCount: number;
}

export interface removeTvFromList_removeTvFromList {
  __typename: "ListsOnTv";
  id: string;
  list: removeTvFromList_removeTvFromList_list;
  tv: removeTvFromList_removeTvFromList_tv;
}

export interface removeTvFromList {
  removeTvFromList: removeTvFromList_removeTvFromList | null;
}

export interface removeTvFromListVariables {
  tvId: number;
  listId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTvForList
// ====================================================

export interface getTvForList_listTv_data_lists_list {
  __typename: "List";
  id: number;
}

export interface getTvForList_listTv_data_lists {
  __typename: "ListsOnTv";
  createdAt: any;
  list: getTvForList_listTv_data_lists_list;
}

export interface getTvForList_listTv_data_tvViewings {
  __typename: "TvViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
}

export interface getTvForList_listTv_data_watchProviderMappings_watchProvider {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

export interface getTvForList_listTv_data_watchProviderMappings {
  __typename: "WatchProviderMapping";
  watchProvider: getTvForList_listTv_data_watchProviderMappings_watchProvider | null;
}

export interface getTvForList_listTv_data {
  __typename: "Tv";
  id: number;
  backdropPath: string | null;
  createdAt: any;
  genres: any;
  homepage: string | null;
  movieDbId: number;
  numberOfEpisodes: number;
  numberOfSeasons: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  updatedAt: any;
  voteAverage: number;
  voteCount: number;
  lists: getTvForList_listTv_data_lists[];
  tvViewings: getTvForList_listTv_data_tvViewings[];
  watchProviderMappings: getTvForList_listTv_data_watchProviderMappings[];
}

export interface getTvForList_listTv {
  __typename: "PaginatedListTv";
  page: number;
  pages: number;
  totalResults: number;
  data: getTvForList_listTv_data[];
}

export interface getTvForList {
  listTv: getTvForList_listTv | null;
}

export interface getTvForListVariables {
  listId: number;
  orderBy: TvOrderByInput;
  locale?: string | null;
  viewStatus?: ListMediaType | null;
  providerLocale?: WatchProviderLocale | null;
  providerIds?: number[] | null;
  page?: number | null;
  pageSize?: number | null;
  query: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTv
// ====================================================

export interface getTv_tv_lists_list {
  __typename: "List";
  id: number;
  name: string;
  createdAt: any;
  updatedAt: any;
}

export interface getTv_tv_lists {
  __typename: "ListsOnTv";
  id: string;
  createdAt: any;
  list: getTv_tv_lists_list;
}

export interface getTv_tv_tvViewings {
  __typename: "TvViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
}

export interface getTv_tv {
  __typename: "Tv";
  id: number;
  backdropPath: string | null;
  createdAt: any;
  genres: any;
  homepage: string | null;
  movieDbId: number;
  numberOfEpisodes: number;
  numberOfSeasons: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  updatedAt: any;
  voteAverage: number;
  voteCount: number;
  seasons: any;
  networks: any;
  lists: getTv_tv_lists[];
  tvViewings: getTv_tv_tvViewings[];
}

export interface getTv {
  tv: getTv_tv | null;
}

export interface getTvVariables {
  tvId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTvMovieDbIds
// ====================================================

export interface getTvMovieDbIds_tvs {
  __typename: "Tv";
  movieDbId: number;
}

export interface getTvMovieDbIds {
  tvs: getTvMovieDbIds_tvs[];
}

export interface getTvMovieDbIdsVariables {
  listId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getTvCredits
// ====================================================

export interface getTvCredits_tvCredits_cast {
  __typename: "Actor";
  adult: boolean | null;
  creditId: string | null;
  gender: number | null;
  knownForDepartment: string | null;
  movieDbId: number | null;
  name: string | null;
  originalName: string | null;
  popularity: number | null;
  profilePath: string | null;
  castId: number | null;
  character: string | null;
  order: number | null;
}

export interface getTvCredits_tvCredits_crew {
  __typename: "CrewMember";
  adult: boolean | null;
  creditId: string | null;
  gender: number | null;
  knownForDepartment: string | null;
  movieDbId: number | null;
  name: string | null;
  originalName: string | null;
  popularity: number | null;
  profilePath: string | null;
  department: string | null;
  job: string | null;
}

export interface getTvCredits_tvCredits {
  __typename: "Credits";
  cast: (getTvCredits_tvCredits_cast | null)[] | null;
  crew: (getTvCredits_tvCredits_crew | null)[] | null;
}

export interface getTvCredits {
  tvCredits: getTvCredits_tvCredits | null;
}

export interface getTvCreditsVariables {
  tvId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTvViewing
// ====================================================

export interface createTvViewing_createTvViewing_tv_tvViewings {
  __typename: "TvViewing";
  id: number;
}

export interface createTvViewing_createTvViewing_tv {
  __typename: "Tv";
  id: number;
  tvViewings: createTvViewing_createTvViewing_tv_tvViewings[];
}

export interface createTvViewing_createTvViewing {
  __typename: "TvViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
  tv: createTvViewing_createTvViewing_tv;
}

export interface createTvViewing {
  createTvViewing: createTvViewing_createTvViewing | null;
}

export interface createTvViewingVariables {
  tvId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeTvViewings
// ====================================================

export interface removeTvViewings_removeTvViewings_tvViewings {
  __typename: "TvViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
}

export interface removeTvViewings_removeTvViewings {
  __typename: "Tv";
  id: number;
  tvViewings: removeTvViewings_removeTvViewings_tvViewings[];
}

export interface removeTvViewings {
  removeTvViewings: removeTvViewings_removeTvViewings | null;
}

export interface removeTvViewingsVariables {
  tvId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: useMe
// ====================================================

export interface useMe_me {
  __typename: "User";
  id: number;
  firstName: string;
  lastName: string;
}

export interface useMe {
  me: useMe_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: syncMe
// ====================================================

export interface syncMe_syncMe {
  __typename: "User";
  id: number;
  firstName: string;
  lastName: string;
}

export interface syncMe {
  syncMe: syncMe_syncMe | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteAccount
// ====================================================

export interface deleteAccount {
  deleteAccount: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateSettings
// ====================================================

export interface updateSettings_updateSettings {
  __typename: "UserSettings";
  id: number;
  key: UserSettingsKey;
  value: any;
  updatedAt: any;
}

export interface updateSettings {
  updateSettings: (updateSettings_updateSettings | null)[] | null;
}

export interface updateSettingsVariables {
  key: UserSettingsKey;
  value: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addWatchProvider
// ====================================================

export interface addWatchProvider_addWatchProviderToUserSettings_watchProviders {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

export interface addWatchProvider_addWatchProviderToUserSettings {
  __typename: "UserSettingsBase";
  id: number;
  updatedAt: any;
  watchProviders: addWatchProvider_addWatchProviderToUserSettings_watchProviders[];
}

export interface addWatchProvider {
  addWatchProviderToUserSettings: addWatchProvider_addWatchProviderToUserSettings | null;
}

export interface addWatchProviderVariables {
  watchProviderId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeWatchProvider
// ====================================================

export interface removeWatchProvider_removeWatchProviderFromUserSettings_watchProviders {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

export interface removeWatchProvider_removeWatchProviderFromUserSettings {
  __typename: "UserSettingsBase";
  id: number;
  updatedAt: any;
  watchProviders: removeWatchProvider_removeWatchProviderFromUserSettings_watchProviders[];
}

export interface removeWatchProvider {
  removeWatchProviderFromUserSettings: removeWatchProvider_removeWatchProviderFromUserSettings | null;
}

export interface removeWatchProviderVariables {
  watchProviderId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUserSettings
// ====================================================

export interface getUserSettings_settingsBase_settingProperties {
  __typename: "UserSettings";
  id: number;
  key: UserSettingsKey;
  value: any;
  updatedAt: any;
}

export interface getUserSettings_settingsBase_watchProviders {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

export interface getUserSettings_settingsBase {
  __typename: "UserSettingsBase";
  id: number;
  updatedAt: any;
  settingProperties: getUserSettings_settingsBase_settingProperties[];
  watchProviders: getUserSettings_settingsBase_watchProviders[];
}

export interface getUserSettings {
  settingsBase: getUserSettings_settingsBase | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getWatchProviders
// ====================================================

export interface getWatchProviders_watchProviders_watchProvider {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

export interface getWatchProviders_watchProviders {
  __typename: "WatchProviderMapping";
  id: number | null;
  locale: string | null;
  type: WatchProviderType | null;
  watchProvider: getWatchProviders_watchProviders_watchProvider | null;
}

export interface getWatchProviders {
  watchProviders: (getWatchProviders_watchProviders | null)[] | null;
}

export interface getWatchProvidersVariables {
  id: number;
  locale: WatchProviderLocale;
  mediaType: WatchProviderInputType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllWatchProviders
// ====================================================

export interface getAllWatchProviders_allWatchProviders {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

export interface getAllWatchProviders {
  allWatchProviders: (getAllWatchProviders_allWatchProviders | null)[] | null;
}

export interface getAllWatchProvidersVariables {
  locale: WatchProviderLocale;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: listWatchProviders
// ====================================================

export interface listWatchProviders_listWatchProviders {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

export interface listWatchProviders {
  listWatchProviders: (listWatchProviders_listWatchProviders | null)[] | null;
}

export interface listWatchProvidersVariables {
  locale: WatchProviderLocale;
  type?: WatchProviderType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonFields
// ====================================================

export interface PersonFields {
  __typename: "Actor" | "CrewMember";
  adult: boolean | null;
  creditId: string | null;
  gender: number | null;
  knownForDepartment: string | null;
  movieDbId: number | null;
  name: string | null;
  originalName: string | null;
  popularity: number | null;
  profilePath: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ActorFields
// ====================================================

export interface ActorFields {
  __typename: "Actor";
  adult: boolean | null;
  creditId: string | null;
  gender: number | null;
  knownForDepartment: string | null;
  movieDbId: number | null;
  name: string | null;
  originalName: string | null;
  popularity: number | null;
  profilePath: string | null;
  castId: number | null;
  character: string | null;
  order: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CrewMemberFields
// ====================================================

export interface CrewMemberFields {
  __typename: "CrewMember";
  adult: boolean | null;
  creditId: string | null;
  gender: number | null;
  knownForDepartment: string | null;
  movieDbId: number | null;
  name: string | null;
  originalName: string | null;
  popularity: number | null;
  profilePath: string | null;
  department: string | null;
  job: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListFields
// ====================================================

export interface ListFields {
  __typename: "List";
  id: number;
  name: string;
  createdAt: any;
  updatedAt: any;
  movieCount: number | null;
  tvShowCount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ListAccessFields
// ====================================================

export interface ListAccessFields {
  __typename: "ListAccess";
  id: number;
  accessType: ListAccessType;
  createdAt: any;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SearchResultFields
// ====================================================

export interface SearchResultFields {
  __typename: "SearchResult";
  id: number | null;
  type: SearchResultType | null;
  hasSeen: boolean | null;
  backdropPath: string | null;
  title: string | null;
  name: string | null;
  gender: number | null;
  posterPath: string | null;
  releaseDate: string | null;
  overview: string | null;
  originalTitle: string | null;
  knownForDepartment: string | null;
  profilePath: string | null;
  popularity: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MovieFields
// ====================================================

export interface MovieFields {
  __typename: "Movie";
  id: number;
  adult: boolean;
  backdropPath: string | null;
  budget: number;
  genres: any;
  homepage: string | null;
  imdbId: string | null;
  movieDbId: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  revenue: number;
  runtime: number | null;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  video: boolean;
  voteAverage: number;
  voteCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MovieViewingFields
// ====================================================

export interface MovieViewingFields {
  __typename: "MovieViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PeopleDetailsFields
// ====================================================

export interface PeopleDetailsFields {
  __typename: "PeopleDetails";
  birthday: string | null;
  knownForDepartment: string | null;
  deathday: string | null;
  movieDbId: number | null;
  name: string | null;
  alsoKnownAs: (string | null)[] | null;
  gender: number | null;
  biography: string | null;
  popularity: number | null;
  placeOfBirth: string | null;
  profilePath: string | null;
  adult: boolean | null;
  imdbId: string | null;
  homepage: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonMediaFields
// ====================================================

export interface PersonMediaFields {
  __typename: "PersonMedia";
  movieDbId: number | null;
  originalLanguage: string | null;
  episodeCount: number | null;
  overview: string | null;
  originCountry: (string | null)[] | null;
  originalName: string | null;
  genreIds: (number | null)[] | null;
  name: string | null;
  mediaType: string | null;
  posterPath: string | null;
  firstAirDate: string | null;
  voteAverage: number | null;
  voteCount: number | null;
  character: string | null;
  backdropPath: string | null;
  popularity: number | null;
  creditId: string | null;
  originalTitle: string | null;
  video: boolean | null;
  releaseDate: string | null;
  title: string | null;
  adult: boolean | null;
  job: string | null;
  department: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RecentActivityFields
// ====================================================

export interface RecentActivityFields {
  __typename: "RecentActivity";
  date: any | null;
  details: string | null;
  type: RecentActivityType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TvFields
// ====================================================

export interface TvFields {
  __typename: "Tv";
  id: number;
  backdropPath: string | null;
  createdAt: any;
  genres: any;
  homepage: string | null;
  movieDbId: number;
  numberOfEpisodes: number;
  numberOfSeasons: number;
  originalLanguage: string;
  originalTitle: string;
  overview: string | null;
  popularity: number;
  posterPath: string | null;
  productionCompanies: any;
  productionCountries: any;
  releaseDate: string;
  spokenLanguages: any;
  status: string;
  tagline: string | null;
  title: string;
  updatedAt: any;
  voteAverage: number;
  voteCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TvViewingFields
// ====================================================

export interface TvViewingFields {
  __typename: "TvViewing";
  id: number;
  createdAt: any;
  updatedAt: any;
  rating: Rating | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFields
// ====================================================

export interface UserFields {
  __typename: "User";
  id: number;
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserSettingsFields
// ====================================================

export interface UserSettingsFields {
  __typename: "UserSettings";
  id: number;
  key: UserSettingsKey;
  value: any;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserSettingsBaseFields
// ====================================================

export interface UserSettingsBaseFields {
  __typename: "UserSettingsBase";
  id: number;
  updatedAt: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WatchProviderMappingFields
// ====================================================

export interface WatchProviderMappingFields {
  __typename: "WatchProviderMapping";
  id: number | null;
  locale: string | null;
  type: WatchProviderType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WatchProviderFields
// ====================================================

export interface WatchProviderFields {
  __typename: "WatchProvider";
  id: number | null;
  logoPath: string | null;
  displayPriority: number | null;
  providerName: string | null;
  updatedAt: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ListAccessType {
  OWNER = "OWNER",
  READ = "READ",
}

export enum ListMediaType {
  NOT_SEEN = "NOT_SEEN",
  SEEN = "SEEN",
}

export enum QueryMode {
  default = "default",
  insensitive = "insensitive",
}

export enum Rating {
  FIVE_STAR = "FIVE_STAR",
  FOUR_STAR = "FOUR_STAR",
  ONE_STAR = "ONE_STAR",
  THREE_STAR = "THREE_STAR",
  TWO_STAR = "TWO_STAR",
}

export enum RecentActivityType {
  MOVIE_ADDITION = "MOVIE_ADDITION",
  MOVIE_VIEWING = "MOVIE_VIEWING",
  TV_ADDITION = "TV_ADDITION",
  TV_VIEWING = "TV_VIEWING",
}

export enum SearchResultType {
  MOVIE = "MOVIE",
  PERSON = "PERSON",
  TV = "TV",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum UserSettingsKey {
  COLOURS = "COLOURS",
  LOCALE = "LOCALE",
}

export enum WatchProviderInputType {
  MOVIE = "MOVIE",
  TV = "TV",
}

export enum WatchProviderLocale {
  AR = "AR",
  AT = "AT",
  AU = "AU",
  BE = "BE",
  BR = "BR",
  CA = "CA",
  CH = "CH",
  CL = "CL",
  CO = "CO",
  CZ = "CZ",
  DE = "DE",
  DK = "DK",
  EC = "EC",
  EE = "EE",
  ES = "ES",
  FI = "FI",
  FR = "FR",
  GB = "GB",
  GR = "GR",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IN = "IN",
  IT = "IT",
  JP = "JP",
  KR = "KR",
  LT = "LT",
  LV = "LV",
  MX = "MX",
  MY = "MY",
  NL = "NL",
  NO = "NO",
  NZ = "NZ",
  PE = "PE",
  PH = "PH",
  PL = "PL",
  PT = "PT",
  RO = "RO",
  RU = "RU",
  SE = "SE",
  SG = "SG",
  TH = "TH",
  TR = "TR",
  US = "US",
  VE = "VE",
  ZA = "ZA",
}

export enum WatchProviderType {
  BUY = "BUY",
  FLATRATE = "FLATRATE",
  RENT = "RENT",
}

export interface BoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface DateTimeFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDateTimeFilter | null;
  notIn?: any[] | null;
}

export interface EnumListAccessTypeFilter {
  equals?: ListAccessType | null;
  in?: ListAccessType[] | null;
  not?: NestedEnumListAccessTypeFilter | null;
  notIn?: ListAccessType[] | null;
}

export interface EnumRatingNullableFilter {
  equals?: Rating | null;
  in?: Rating[] | null;
  not?: NestedEnumRatingNullableFilter | null;
  notIn?: Rating[] | null;
}

export interface EnumUserSettingsKeyFilter {
  equals?: UserSettingsKey | null;
  in?: UserSettingsKey[] | null;
  not?: NestedEnumUserSettingsKeyFilter | null;
  notIn?: UserSettingsKey[] | null;
}

export interface EnumWatchProviderTypeFilter {
  equals?: WatchProviderType | null;
  in?: WatchProviderType[] | null;
  not?: NestedEnumWatchProviderTypeFilter | null;
  notIn?: WatchProviderType[] | null;
}

export interface FloatFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedFloatFilter | null;
  notIn?: number[] | null;
}

export interface IntFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntFilter | null;
  notIn?: number[] | null;
}

export interface IntNullableFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntNullableFilter | null;
  notIn?: number[] | null;
}

export interface ListAccessInviteListRelationFilter {
  every?: ListAccessInviteWhereInput | null;
  none?: ListAccessInviteWhereInput | null;
  some?: ListAccessInviteWhereInput | null;
}

export interface ListAccessInviteWhereInput {
  AND?: ListAccessInviteWhereInput[] | null;
  code?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  expires?: DateTimeFilter | null;
  id?: IntFilter | null;
  list?: ListWhereInput | null;
  listId?: IntFilter | null;
  NOT?: ListAccessInviteWhereInput[] | null;
  OR?: ListAccessInviteWhereInput[] | null;
  updatedAt?: DateTimeFilter | null;
}

export interface ListAccessListRelationFilter {
  every?: ListAccessWhereInput | null;
  none?: ListAccessWhereInput | null;
  some?: ListAccessWhereInput | null;
}

export interface ListAccessWhereInput {
  accessType?: EnumListAccessTypeFilter | null;
  AND?: ListAccessWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: IntFilter | null;
  list?: ListWhereInput | null;
  listId?: IntFilter | null;
  NOT?: ListAccessWhereInput[] | null;
  OR?: ListAccessWhereInput[] | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: IntFilter | null;
}

export interface ListInputFilters {
  createdAt?: SortOrder | null;
  movieCount?: SortOrder | null;
  name?: SortOrder | null;
  tvShowCount?: SortOrder | null;
  updatedAt?: SortOrder | null;
}

export interface ListWhereInput {
  AND?: ListWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: IntFilter | null;
  listAccesses?: ListAccessListRelationFilter | null;
  listAccessInvites?: ListAccessInviteListRelationFilter | null;
  movies?: ListsOnMovieListRelationFilter | null;
  name?: StringFilter | null;
  NOT?: ListWhereInput[] | null;
  OR?: ListWhereInput[] | null;
  tv?: ListsOnTvListRelationFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface ListWhereUniqueInput {
  id?: number | null;
}

export interface ListsOnMovieListRelationFilter {
  every?: ListsOnMovieWhereInput | null;
  none?: ListsOnMovieWhereInput | null;
  some?: ListsOnMovieWhereInput | null;
}

export interface ListsOnMovieWhereInput {
  AND?: ListsOnMovieWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  list?: ListWhereInput | null;
  listId?: IntFilter | null;
  movie?: MovieWhereInput | null;
  movieId?: IntFilter | null;
  NOT?: ListsOnMovieWhereInput[] | null;
  OR?: ListsOnMovieWhereInput[] | null;
}

export interface ListsOnTvListRelationFilter {
  every?: ListsOnTvWhereInput | null;
  none?: ListsOnTvWhereInput | null;
  some?: ListsOnTvWhereInput | null;
}

export interface ListsOnTvWhereInput {
  AND?: ListsOnTvWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  list?: ListWhereInput | null;
  listId?: IntFilter | null;
  NOT?: ListsOnTvWhereInput[] | null;
  OR?: ListsOnTvWhereInput[] | null;
  tv?: TvWhereInput | null;
  tvId?: IntFilter | null;
}

export interface MovieListRelationFilter {
  every?: MovieWhereInput | null;
  none?: MovieWhereInput | null;
  some?: MovieWhereInput | null;
}

export interface MovieOrderByInput {
  addedToListAt?: SortOrder | null;
  adult?: SortOrder | null;
  backdropPath?: SortOrder | null;
  budget?: SortOrder | null;
  createdAt?: SortOrder | null;
  genres?: SortOrder | null;
  homepage?: SortOrder | null;
  id?: SortOrder | null;
  imdbId?: SortOrder | null;
  movieDbId?: SortOrder | null;
  originalLanguage?: SortOrder | null;
  originalTitle?: SortOrder | null;
  overview?: SortOrder | null;
  popularity?: SortOrder | null;
  posterPath?: SortOrder | null;
  productionCompanies?: SortOrder | null;
  productionCountries?: SortOrder | null;
  releaseDate?: SortOrder | null;
  revenue?: SortOrder | null;
  runtime?: SortOrder | null;
  spokenLanguages?: SortOrder | null;
  status?: SortOrder | null;
  tagline?: SortOrder | null;
  title?: SortOrder | null;
  updatedAt?: SortOrder | null;
  video?: SortOrder | null;
  viewedAt?: SortOrder | null;
  voteAverage?: SortOrder | null;
  voteCount?: SortOrder | null;
}

export interface MovieViewingListRelationFilter {
  every?: MovieViewingWhereInput | null;
  none?: MovieViewingWhereInput | null;
  some?: MovieViewingWhereInput | null;
}

export interface MovieViewingWhereInput {
  AND?: MovieViewingWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: IntFilter | null;
  movie?: MovieWhereInput | null;
  movieId?: IntFilter | null;
  NOT?: MovieViewingWhereInput[] | null;
  OR?: MovieViewingWhereInput[] | null;
  rating?: EnumRatingNullableFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: IntFilter | null;
}

export interface MovieWhereInput {
  adult?: BoolFilter | null;
  AND?: MovieWhereInput[] | null;
  backdropPath?: StringNullableFilter | null;
  budget?: IntFilter | null;
  createdAt?: DateTimeFilter | null;
  homepage?: StringNullableFilter | null;
  id?: IntFilter | null;
  imdbId?: StringNullableFilter | null;
  lists?: ListsOnMovieListRelationFilter | null;
  movieDbId?: IntFilter | null;
  movieViewings?: MovieViewingListRelationFilter | null;
  NOT?: MovieWhereInput[] | null;
  OR?: MovieWhereInput[] | null;
  originalLanguage?: StringFilter | null;
  originalTitle?: StringFilter | null;
  overview?: StringNullableFilter | null;
  popularity?: FloatFilter | null;
  posterPath?: StringNullableFilter | null;
  releaseDate?: StringFilter | null;
  revenue?: IntFilter | null;
  runtime?: IntNullableFilter | null;
  status?: StringFilter | null;
  tagline?: StringNullableFilter | null;
  title?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  video?: BoolFilter | null;
  voteAverage?: FloatFilter | null;
  voteCount?: IntFilter | null;
  watchProviderMappings?: WatchProviderMappingListRelationFilter | null;
  WatchProviderUpdate?: WatchProviderUpdateListRelationFilter | null;
}

export interface NestedBoolFilter {
  equals?: boolean | null;
  not?: NestedBoolFilter | null;
}

export interface NestedDateTimeFilter {
  equals?: any | null;
  gt?: any | null;
  gte?: any | null;
  in?: any[] | null;
  lt?: any | null;
  lte?: any | null;
  not?: NestedDateTimeFilter | null;
  notIn?: any[] | null;
}

export interface NestedEnumListAccessTypeFilter {
  equals?: ListAccessType | null;
  in?: ListAccessType[] | null;
  not?: NestedEnumListAccessTypeFilter | null;
  notIn?: ListAccessType[] | null;
}

export interface NestedEnumRatingNullableFilter {
  equals?: Rating | null;
  in?: Rating[] | null;
  not?: NestedEnumRatingNullableFilter | null;
  notIn?: Rating[] | null;
}

export interface NestedEnumUserSettingsKeyFilter {
  equals?: UserSettingsKey | null;
  in?: UserSettingsKey[] | null;
  not?: NestedEnumUserSettingsKeyFilter | null;
  notIn?: UserSettingsKey[] | null;
}

export interface NestedEnumWatchProviderTypeFilter {
  equals?: WatchProviderType | null;
  in?: WatchProviderType[] | null;
  not?: NestedEnumWatchProviderTypeFilter | null;
  notIn?: WatchProviderType[] | null;
}

export interface NestedFloatFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedFloatFilter | null;
  notIn?: number[] | null;
}

export interface NestedIntFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntFilter | null;
  notIn?: number[] | null;
}

export interface NestedIntNullableFilter {
  equals?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: number[] | null;
  lt?: number | null;
  lte?: number | null;
  not?: NestedIntNullableFilter | null;
  notIn?: number[] | null;
}

export interface NestedStringFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  not?: NestedStringFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface NestedStringNullableFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  not?: NestedStringNullableFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface StringFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface StringNullableFilter {
  contains?: string | null;
  endsWith?: string | null;
  equals?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: string[] | null;
  lt?: string | null;
  lte?: string | null;
  mode?: QueryMode | null;
  not?: NestedStringNullableFilter | null;
  notIn?: string[] | null;
  startsWith?: string | null;
}

export interface TvListRelationFilter {
  every?: TvWhereInput | null;
  none?: TvWhereInput | null;
  some?: TvWhereInput | null;
}

export interface TvOrderByInput {
  addedToListAt?: SortOrder | null;
  backdropPath?: SortOrder | null;
  createdAt?: SortOrder | null;
  createdBy?: SortOrder | null;
  episodeRunTime?: SortOrder | null;
  genres?: SortOrder | null;
  homepage?: SortOrder | null;
  id?: SortOrder | null;
  inProduction?: SortOrder | null;
  languages?: SortOrder | null;
  lastAirDate?: SortOrder | null;
  lastEpisodeToAir?: SortOrder | null;
  movieDbId?: SortOrder | null;
  networks?: SortOrder | null;
  numberOfEpisodes?: SortOrder | null;
  numberOfSeasons?: SortOrder | null;
  originalLanguage?: SortOrder | null;
  originalTitle?: SortOrder | null;
  originCountry?: SortOrder | null;
  overview?: SortOrder | null;
  popularity?: SortOrder | null;
  posterPath?: SortOrder | null;
  productionCompanies?: SortOrder | null;
  productionCountries?: SortOrder | null;
  releaseDate?: SortOrder | null;
  seasons?: SortOrder | null;
  spokenLanguages?: SortOrder | null;
  status?: SortOrder | null;
  tagline?: SortOrder | null;
  title?: SortOrder | null;
  type?: SortOrder | null;
  updatedAt?: SortOrder | null;
  viewedAt?: SortOrder | null;
  voteAverage?: SortOrder | null;
  voteCount?: SortOrder | null;
}

export interface TvViewingListRelationFilter {
  every?: TvViewingWhereInput | null;
  none?: TvViewingWhereInput | null;
  some?: TvViewingWhereInput | null;
}

export interface TvViewingWhereInput {
  AND?: TvViewingWhereInput[] | null;
  createdAt?: DateTimeFilter | null;
  id?: IntFilter | null;
  NOT?: TvViewingWhereInput[] | null;
  OR?: TvViewingWhereInput[] | null;
  rating?: EnumRatingNullableFilter | null;
  tv?: TvWhereInput | null;
  tvId?: IntFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: IntFilter | null;
}

export interface TvWatchProviderUpdateListRelationFilter {
  every?: TvWatchProviderUpdateWhereInput | null;
  none?: TvWatchProviderUpdateWhereInput | null;
  some?: TvWatchProviderUpdateWhereInput | null;
}

export interface TvWatchProviderUpdateWhereInput {
  AND?: TvWatchProviderUpdateWhereInput[] | null;
  id?: IntFilter | null;
  locale?: StringFilter | null;
  NOT?: TvWatchProviderUpdateWhereInput[] | null;
  OR?: TvWatchProviderUpdateWhereInput[] | null;
  tv?: TvWhereInput | null;
  tvId?: IntFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface TvWhereInput {
  AND?: TvWhereInput[] | null;
  backdropPath?: StringNullableFilter | null;
  createdAt?: DateTimeFilter | null;
  homepage?: StringNullableFilter | null;
  id?: IntFilter | null;
  inProduction?: BoolFilter | null;
  lastAirDate?: StringFilter | null;
  lists?: ListsOnTvListRelationFilter | null;
  movieDbId?: IntFilter | null;
  NOT?: TvWhereInput[] | null;
  numberOfEpisodes?: IntFilter | null;
  numberOfSeasons?: IntFilter | null;
  OR?: TvWhereInput[] | null;
  originalLanguage?: StringFilter | null;
  originalTitle?: StringFilter | null;
  overview?: StringNullableFilter | null;
  popularity?: FloatFilter | null;
  posterPath?: StringNullableFilter | null;
  releaseDate?: StringFilter | null;
  status?: StringFilter | null;
  tagline?: StringNullableFilter | null;
  title?: StringFilter | null;
  tvViewings?: TvViewingListRelationFilter | null;
  TvWatchProviderUpdate?: TvWatchProviderUpdateListRelationFilter | null;
  type?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  voteAverage?: FloatFilter | null;
  voteCount?: IntFilter | null;
  watchProviderMappings?: WatchProviderMappingListRelationFilter | null;
}

export interface UserSettingsBaseWhereInput {
  AND?: UserSettingsBaseWhereInput[] | null;
  id?: IntFilter | null;
  NOT?: UserSettingsBaseWhereInput[] | null;
  OR?: UserSettingsBaseWhereInput[] | null;
  settingProperties?: UserSettingsListRelationFilter | null;
  updatedAt?: DateTimeFilter | null;
  user?: UserWhereInput | null;
  userId?: IntFilter | null;
  UserSettingsProviders?: UserSettingsProvidersListRelationFilter | null;
  watchProviders?: WatchProviderListRelationFilter | null;
}

export interface UserSettingsListRelationFilter {
  every?: UserSettingsWhereInput | null;
  none?: UserSettingsWhereInput | null;
  some?: UserSettingsWhereInput | null;
}

export interface UserSettingsProvidersListRelationFilter {
  every?: UserSettingsProvidersWhereInput | null;
  none?: UserSettingsProvidersWhereInput | null;
  some?: UserSettingsProvidersWhereInput | null;
}

export interface UserSettingsProvidersWhereInput {
  AND?: UserSettingsProvidersWhereInput[] | null;
  id?: IntFilter | null;
  NOT?: UserSettingsProvidersWhereInput[] | null;
  OR?: UserSettingsProvidersWhereInput[] | null;
  settings?: UserSettingsBaseWhereInput | null;
  updatedAt?: DateTimeFilter | null;
  userSettingsId?: IntFilter | null;
  watchProvider?: WatchProviderWhereInput | null;
  watchProviderId?: IntFilter | null;
}

export interface UserSettingsWhereInput {
  AND?: UserSettingsWhereInput[] | null;
  id?: IntFilter | null;
  key?: EnumUserSettingsKeyFilter | null;
  NOT?: UserSettingsWhereInput[] | null;
  OR?: UserSettingsWhereInput[] | null;
  updatedAt?: DateTimeFilter | null;
  userSetting?: UserSettingsBaseWhereInput | null;
  userSettingsBaseId?: IntFilter | null;
}

export interface UserWhereInput {
  AND?: UserWhereInput[] | null;
  auth0UserId?: StringFilter | null;
  createdAt?: DateTimeFilter | null;
  firstName?: StringFilter | null;
  id?: IntFilter | null;
  lastName?: StringFilter | null;
  listAccesses?: ListAccessListRelationFilter | null;
  movieViewings?: MovieViewingListRelationFilter | null;
  NOT?: UserWhereInput[] | null;
  OR?: UserWhereInput[] | null;
  settings?: UserSettingsBaseWhereInput | null;
  tvViewings?: TvViewingListRelationFilter | null;
  updatedAt?: DateTimeFilter | null;
}

export interface WatchProviderListRelationFilter {
  every?: WatchProviderWhereInput | null;
  none?: WatchProviderWhereInput | null;
  some?: WatchProviderWhereInput | null;
}

export interface WatchProviderMappingListRelationFilter {
  every?: WatchProviderMappingWhereInput | null;
  none?: WatchProviderMappingWhereInput | null;
  some?: WatchProviderMappingWhereInput | null;
}

export interface WatchProviderMappingWhereInput {
  AND?: WatchProviderMappingWhereInput[] | null;
  id?: IntFilter | null;
  locale?: StringFilter | null;
  movies?: MovieListRelationFilter | null;
  NOT?: WatchProviderMappingWhereInput[] | null;
  OR?: WatchProviderMappingWhereInput[] | null;
  tv?: TvListRelationFilter | null;
  type?: EnumWatchProviderTypeFilter | null;
  watchProvider?: WatchProviderWhereInput | null;
  watchProviderId?: IntFilter | null;
}

export interface WatchProviderUpdateListRelationFilter {
  every?: WatchProviderUpdateWhereInput | null;
  none?: WatchProviderUpdateWhereInput | null;
  some?: WatchProviderUpdateWhereInput | null;
}

export interface WatchProviderUpdateWhereInput {
  AND?: WatchProviderUpdateWhereInput[] | null;
  id?: IntFilter | null;
  locale?: StringFilter | null;
  movie?: MovieWhereInput | null;
  movieId?: IntFilter | null;
  NOT?: WatchProviderUpdateWhereInput[] | null;
  OR?: WatchProviderUpdateWhereInput[] | null;
  updatedAt?: DateTimeFilter | null;
}

export interface WatchProviderWhereInput {
  AND?: WatchProviderWhereInput[] | null;
  displayPriority?: IntFilter | null;
  id?: IntFilter | null;
  logoPath?: StringNullableFilter | null;
  NOT?: WatchProviderWhereInput[] | null;
  OR?: WatchProviderWhereInput[] | null;
  providerId?: IntFilter | null;
  providerName?: StringFilter | null;
  updatedAt?: DateTimeFilter | null;
  UserSettingsBase?: UserSettingsBaseWhereInput | null;
  userSettingsBaseId?: IntNullableFilter | null;
  UserSettingsProviders?: UserSettingsProvidersListRelationFilter | null;
  watchProviderMappings?: WatchProviderMappingListRelationFilter | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
