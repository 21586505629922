import {SortOrder} from "../graphql-types";
import {makeVar, ReactiveVar, useReactiveVar} from "@apollo/client";
import {useCallback} from "react";
import {ActorPageMovieSort, ListMediaFilterVar, ListSummarySort, MovieListSort, TvListSort} from "./types";

export const movieSortVar = makeVar<MovieListSort>([
  {
    addedToListAt: SortOrder.desc,
  }
]);

export const tvSortVar = makeVar<TvListSort>([
  {
    addedToListAt: SortOrder.desc,
  }
]);

export const listSortVar = makeVar<ListSummarySort>([
  {
    updatedAt: SortOrder.desc,
  }
]);

export const actorPageSortVar = makeVar<ActorPageMovieSort>([
  {
    popularity: SortOrder.desc,
  }
]);

export type SortVars = typeof listSortVar | typeof movieSortVar;

export const useSetSortField = <T extends SortVars>(sortVar: T) => {
  return useCallback((fieldName: string) => {
    sortVar([
      {
        [fieldName as keyof ReturnType<T>]: (Object.values(sortVar()[0])[0] as SortOrder),
      }
    ]);
  }, [sortVar]);
}

export const useSortOrder = <T extends SortVars>(sortVar: T) => {
  const value = useReactiveVar(sortVar);
  return Object.values(value[0])[0] as SortOrder;
};

export const useToggleOrder = <T extends SortVars>(sortVar: T) => {
  const order = useReactiveVar(sortVar);
  const sortValue = useSortOrder(sortVar);
  return useCallback(() => {
    sortVar([
      {
        [Object.keys(order[0])[0]]: (sortValue === SortOrder.desc ? SortOrder.asc : SortOrder.desc),
      }
    ]);
  }, [sortVar, order, sortValue]);
}

export const useActiveSortField = <T extends SortVars>(sortVar: T): string | string[] => {
  const value = useReactiveVar(sortVar);
  return Object.keys(value[0])[0] as string;
};

export const listMovieFilterVar = makeVar<ListMediaFilterVar>({
  providers: undefined,
});

export const listTvFilterVar = makeVar<ListMediaFilterVar>({
  providers: undefined,
});

const updaters = (filterVar: ReactiveVar<ListMediaFilterVar>) => ({
  toggleProvider: (providerId: number) => {
    const currentProviders = filterVar().providers;

    let nextProviders;
    if (typeof currentProviders === 'undefined') {
      nextProviders = [providerId];
    } else {
      if (currentProviders.some(id => providerId === id)) {
        nextProviders = currentProviders.length === 1 ? undefined : currentProviders.filter(id => id !== providerId);
      } else {
        nextProviders = [...currentProviders, providerId];
      }
    }

    return filterVar({
      ...filterVar(),
      providers: nextProviders,
    })
  },
  clearProviders: () => {
    filterVar({
      ...filterVar(),
      providers: undefined,
    });
  }
});

export const useMediaListFilter = (filterVar: ReactiveVar<ListMediaFilterVar>): [ListMediaFilterVar, ReturnType<typeof updaters>] => {
  return [
    useReactiveVar(filterVar),
    updaters(filterVar),
  ];
};
