import {Button, Input, Modal} from "rsuite";
import {createModal} from "../Modal";
import {useState} from "react";
import {useDeleteAccount} from "../../api";
import {useAuth0} from "@auth0/auth0-react";
import {useAutoFocus} from "../../utils";

type Props = {
  id: string;
};

const DeleteAccountModal = createModal<Props>(({ModalComponent}) => {
  const [value, setValue] = useState('');
  const {deleteAccount, loading} = useDeleteAccount();
  const {logout} = useAuth0();
  const ref = useAutoFocus();

  const handleDelete = () => {
    (async () => {
      if ((await deleteAccount()).data?.deleteAccount) {
        logout();
      }
    })();
  };

  return (
    <ModalComponent>
      <Modal.Header>
        <Modal.Title className="is-size-2">
          Delete Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Deleting your account will delete all lists that you own, and <span className="has-text-weight-bold">cannot be undone.</span></p>
        <p>Please type <span className="has-text-weight-bold">"I understand"</span> in the box below to confirm your account deletion.</p>
        <Input
          inputRef={ref}
          onPressEnter={handleDelete}
          className="mt-2"
          value={value}
          onChange={setValue}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="red"
          disabled={value !== 'I understand'}
          onClick={handleDelete}
          loading={loading}
        >
          Delete Account
        </Button>
      </Modal.Footer>
    </ModalComponent>
  );
});

export default DeleteAccountModal;
