import {useExtractQueryValue} from "../utils";
import {useQuery} from "@apollo/client";
import {GET_RECENT_ACTIVITIES} from "./queries";
import {getRecentActivities, getRecentActivitiesVariables, RecentActivityType} from "../../graphql-types";

export const useRecentActivities = (types: RecentActivityType[], listId?: number) => {
  return useExtractQueryValue(
    useQuery<getRecentActivities, getRecentActivitiesVariables>(GET_RECENT_ACTIVITIES, {
      fetchPolicy: 'network-only',
      variables: {
        types,
        listId,
      }
    }),
    'recentActivity',
  )
};
