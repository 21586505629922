import {useMutation} from "@apollo/client";
import {CREATE_MOVIE, CREATE_MOVIE_FROM_SEARCH, REMOVE_MOVIE_FROM_LIST} from "./mutations";
import {isNumber} from "../../utils";
import {useCallback} from "react";
import {
  createMovieFromSearchResult,
  createMovieFromSearchResultVariables, createMovieVariables, createMovie,
  removeMovieFromList,
  removeMovieFromListVariables,
} from "../../graphql-types";
import {GET_LIST, GET_LISTS} from "../List/queries";
import {getListParameters, getListsParameters} from "../List";
import {listSortVar} from "../../stores";
import {GET_MOVIE} from "./queries";
import {getMovieVariables} from "./useMovieQueries";
import {TIME_SPENT_WATCHING, WATCH_COMPLETION_STAT} from "../Stats/queries";
import {useBuiltUserSettings} from "../UserSettings/userUserSettingsQueries";

export const useCreateMovieFromSearchResult = () => {
  const [createMovie, result] = useMutation<createMovieFromSearchResult, createMovieFromSearchResultVariables>(CREATE_MOVIE_FROM_SEARCH);
  const {data: userSettings} = useBuiltUserSettings();

  const create = useCallback(async (id: number | undefined, listId: number | undefined) => {
    if (isNumber(id) && isNumber(listId)) {
      const result = await createMovie({
        variables: {
          id,
          listId,
          locale: userSettings.locale,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {query: GET_LIST, variables: getListParameters(listId)},
          'getMoviesForList',
          'getRecentActivities',
          'getMovie',
          'getMovieMovieDbIds',
        ],
      });
      return result.data?.createMovieFromSearchResult?.movie || null;
    }
    return null;
  }, [createMovie, userSettings.locale]);

  return {
    ...result,
    createMovie: create,
  };
};

export const useCreateMovie = () => {
  const [createMovie, result] = useMutation<createMovie, createMovieVariables>(CREATE_MOVIE);
  const {data: userSettings} = useBuiltUserSettings();

  const create = useCallback((movieDbId: number) => {
    return createMovie({
      variables: {
        id: movieDbId,
        locale: userSettings.locale,
      }
    });
  }, [
    createMovie,
    userSettings,
  ]);

  return {
    ...result,
    createMovie: create,
  };
};

export const useRemoveMovieFromList = () => {
  const [removeMovie, result] = useMutation<removeMovieFromList, removeMovieFromListVariables>(REMOVE_MOVIE_FROM_LIST);
  const {data: userSettings} = useBuiltUserSettings();

  const remove = useCallback(async (movieId: number | undefined, listId: number | undefined) => {
    if (isNumber(movieId) && isNumber(listId)) {
      const result = await removeMovie({
        variables: {
          listId,
          movieId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {query: GET_LIST, variables: getListParameters(listId)},
          {query: GET_LISTS, variables: getListsParameters(listSortVar())},
          'getMoviesForList',
          {query: GET_MOVIE, variables: getMovieVariables(movieId)},
          {query: WATCH_COMPLETION_STAT},
          {query: TIME_SPENT_WATCHING},
          'getMovie',
          'getMovieMovieDbIds',
        ],
      });
      return result.data?.removeMovieFromList?.movie || null;
    }
    return null;
  }, [removeMovie, userSettings.locale]);

  return {
    ...result,
    removeMovie: remove,
  };
};
