import React, {useRef, useState} from 'react';
import {Dropdown, DropdownProps} from "rsuite";
import styled from "styled-components";

const DropdownComponent = styled(Dropdown)`
  .rs-dropdown-menu {
    max-height: 300px;
    overflow: auto;
  }
`;

type Props = DropdownProps;

const NonCloseDropdown = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const canCloseRef = useRef(true);

  return (
    <DropdownComponent
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        if (canCloseRef.current) {
          setIsOpen(false);
        }
        canCloseRef.current = true;
      }}
      onSelect={() => {
        canCloseRef.current = false;
      }}
      {...props}
    />
  );
};

export default NonCloseDropdown;
