import styled from "styled-components";
import movieDbLogo from './blue_short-8e7b30f73a4020692ccca9c88bafe5dcb6f8a62a4c6bc55cd9ba82bb2cd95f6c.svg';
import {breakpoint} from "../../theme";

const Container = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.5rem 1rem;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  ${breakpoint('tablet')} {
    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  background-color: var(--background-colour);
`;

const AttributionImage = styled.img`
  height: 1rem;

  ${breakpoint('tablet')} {
    height: 0.75rem;
  }
`;

const Attribution = () => {
  return (
    <Container>
      <span>This product uses the TMDb API but is not endorsed or certified by TMDb.</span>
      <AttributionImage
        className="image"
        src={movieDbLogo}
        alt="The Movie DB"
      />
    </Container>
  );
};

export default Attribution;
