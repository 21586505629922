import {gql} from "@apollo/client";

export const TV_FIELDS = gql`
  fragment TvFields on Tv {
    id
    backdropPath
    createdAt
    genres
    homepage
    movieDbId
    numberOfEpisodes
    numberOfSeasons
    originalLanguage
    originalTitle
    overview
    popularity
    posterPath
    productionCompanies
    productionCountries
    releaseDate
    spokenLanguages
    status
    tagline
    title
    updatedAt
    voteAverage
    voteCount
  }
`;
