import {AppNav} from "./nav";
import React, {useCallback} from "react";
import {backButtonContainer} from "../stores";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  ignorePadding?: boolean;
};

const BackButtonContainer = styled.div`
  position: absolute;
  margin-top: 1rem;
  margin-left: 1rem;
  z-index: 1;
`;

const AppContainer = styled.div`
  ${({ignorePadding}: { ignorePadding: boolean }) => ignorePadding && `
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: unset !important;
  `}
`;

const AppSection = styled.section`
  padding-top: 4rem;

  ${({ignorePadding}: Props) => !ignorePadding ? undefined : `
  padding: 0;
  width: 100%;
`}
`;

const PageScaffold = ({children, ignorePadding}: Props) => {
  const setBackButton = useCallback((ref: HTMLDivElement) => {
    backButtonContainer(ref);
  }, []);

  return (
    <>
      <AppNav />
      <BackButtonContainer ref={setBackButton} />
      <AppContainer ignorePadding={Boolean(ignorePadding)} className="container">
        <AppSection ignorePadding={ignorePadding} className="section">
          {children}
        </AppSection>
      </AppContainer>
    </>
  );
};

export default PageScaffold;
