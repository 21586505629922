import {gql} from "@apollo/client";
// Just here for documentation really, apollo codegen seems to ignore fragments in other fragments...
gql`
  fragment PersonFields on PersonType {
    adult
    creditId
    gender
    knownForDepartment
    movieDbId
    name
    originalName
    popularity
    profilePath
  }
`;

export const ACTOR_FIELDS = gql`
  fragment ActorFields on Actor {
    adult
    creditId
    gender
    knownForDepartment
    movieDbId
    name
    originalName
    popularity
    profilePath

    castId
    character
    order
  }
`;

export const CREW_MEMBER_FIELDS = gql`
  fragment CrewMemberFields on CrewMember {
    adult
    creditId
    gender
    knownForDepartment
    movieDbId
    name
    originalName
    popularity
    profilePath

    department
    job
  }
`;
