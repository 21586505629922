import {useQuery} from "@apollo/client";
import {GET_PEOPLE_DETAILS} from "./queries";
import {getPeopleDetails, getPeopleDetailsVariables} from "../../graphql-types";

export const useGetPersonDetails = (personId: number) => {
  return useQuery<getPeopleDetails, getPeopleDetailsVariables>(GET_PEOPLE_DETAILS, {
    fetchPolicy: 'network-only',
    variables: {
      personId,
    }
  });
};
