import React from 'react';
import {WatchProviderInputType} from "../../graphql-types";
import {useCreateMovieViewing, useMovie, useRemoveMovieViewings} from "../../api";
import {useTv} from "../../api/Tv/useTvQueries";
import {Optional} from "../types";
import {Checkbox} from "rsuite";
import {nonNull} from "../../utils";
import {useCreateTvViewing, useRemoveTvViewings} from "../../api/TvViewing";

type Props = {
  mediaId: Optional<number>;
  mediaType: WatchProviderInputType;
  className?: string;
};

type ChildProps = Props & {
  children: React.ReactNode;
};

type CheckboxProps = {
  isChecked: boolean;
  onToggle: (isChecked: boolean) => void;
  className?: string;
};

const CheckboxComponent = ({isChecked, onToggle, className}: CheckboxProps) => {
  return (
    <Checkbox
      className={className}
      checked={isChecked}
      onChange={(value, checked) => onToggle(checked)}
    >
      Watched
    </Checkbox>
  );
};

const MovieMedia = ({mediaId, className}: ChildProps) => {
  const {data: movie} = useMovie(mediaId);
  const {createMovieViewing} = useCreateMovieViewing();
  const {removeMovieViewings} = useRemoveMovieViewings();

  const viewings = movie?.movieViewings;
  const hasViewings = nonNull(viewings) && viewings.length > 0

  const toggleViewing = () => {
    if (nonNull(mediaId)) {
      if (!hasViewings) {
        createMovieViewing(mediaId);
      } else {
        removeMovieViewings(mediaId);
      }
    }
  }

  return (
    <CheckboxComponent
      className={className}
      isChecked={hasViewings}
      onToggle={toggleViewing}
    />
  );
};

const TvMedia = ({mediaId, className}: ChildProps) => {
  const {data: movie} = useTv(mediaId);
  const {createTvViewing} = useCreateTvViewing();
  const {removeTvViewings} = useRemoveTvViewings();

  const viewings = movie?.tvViewings;
  const hasViewings = nonNull(viewings) && viewings.length > 0

  const toggleViewing = () => {
    if (nonNull(mediaId)) {
      if (!hasViewings) {
        createTvViewing(mediaId);
      } else {
        removeTvViewings(mediaId);
      }
    }
  };

  return (
    <CheckboxComponent
      className={className}
      isChecked={hasViewings}
      onToggle={toggleViewing}
    />
  );
};

const MediaWatchedCheckbox = (props: Props) => {
  const {mediaType} = props;

  const TheComponent = mediaType === WatchProviderInputType.MOVIE ? MovieMedia : TvMedia;

  return (
    <TheComponent {...props}>
      Watched
    </TheComponent>
  );
};

export default MediaWatchedCheckbox;
