import React from 'react';
import {Optional} from "../types";
import styled from "styled-components";

const Container = styled.div`
`;

type Props = {
  biography: Optional<string>;
};

const Biography = ({biography}: Props) => {
  return (
    <Container>
      <p>{biography}</p>
    </Container>
  );
};

export default Biography;
