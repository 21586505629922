import React, {useContext} from 'react';
import {Avatar, Placeholder} from 'rsuite';
import {useTimeSpentWatchingStat, useWatchCompletionStat} from "../api/Stats";
import {convertToRuntime, getContrastYIQ, nonNull, renderOn, round} from "../utils";
import styled, {ThemeContext} from "styled-components";
import PercentageCircle from "./PercentageCircle";
import {breakpoint, ThemeProps, ThemeShape} from "../theme";
import {range} from "ramda";

const Stat = styled.div.attrs({className: 'is-size-4'})`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const StatsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  ${breakpoint('desktop')} {
    & > *:not(:last-child) {
      margin-right: 2rem;
    }
  }

  ${breakpoint('mobile')} {
    margin-top: 1rem;
    flex-direction: column;
    align-content: center;

    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  width: ${({theme}: ThemeProps) => theme.stats.size}px;
  height: ${({theme}: ThemeProps) => theme.stats.size}px;
  background-color: ${({colour}) => colour};
  color: ${({colour}) => getContrastYIQ(colour)};
`;

const Stats = () => {
  const {data: completionData, loading: watchCompletionLoading} = useWatchCompletionStat();
  const {data: timeSpent, loading: timeSpentWatchingLoading} = useTimeSpentWatchingStat();
  const theme = useContext<ThemeShape>(ThemeContext);

  const {
    timeSpentWatching,
  } = (timeSpent || {});

  const {
    completion,
    seenMovieCount,
    movieCount,
    tvCount,
    seenTvCount,
  } = (completionData?.watchCompletion || {});

  const placeholder = (
    <Placeholder.Graph
      style={{
        width: theme.stats.size,
        height: theme.stats.size,
      }}
    />
  );

  return (
    <StatsRow>
      {watchCompletionLoading && !completionData?.watchCompletion && range(0, 5).map(n => (
        <Stat key={n}>
          {placeholder}
        </Stat>
      ))}
      {renderOn(
        completion,
        data => (
          <Stat>
            <span>Completion</span>
            <PercentageCircle
              colour={theme.colours.themeColours['--colour-46']}
              percentage={round(data * 100, 0)}
              size={theme.stats.size}
            />
          </Stat>
        )
      )}
      {renderOn(
        seenMovieCount,
        data => (
          <Stat>
            <span>Watched Movies</span>
            <StyledAvatar colour={theme.colours.themeColours['--colour-57']}>{data}</StyledAvatar>
          </Stat>
        )
      )}
      {renderOn(
        movieCount,
        data => (
          <Stat>
            <span>Total Movies</span>
            <StyledAvatar colour={theme.colours.themeColours["--colour-21"]}>{data}</StyledAvatar>
          </Stat>
        )
      )}
      {renderOn(
        seenTvCount,
        data => (
          <Stat>
            <span>Watched TV Series</span>
            <StyledAvatar colour={theme.colours.themeColours['--colour-35']}>{data}</StyledAvatar>
          </Stat>
        )
      )}
      {renderOn(
        tvCount,
        data => (
          <Stat>
            <span>Total TV Series</span>
            <StyledAvatar colour={theme.colours.themeColours['--colour-41']}>{data}</StyledAvatar>
          </Stat>
        )
      )}
      {timeSpentWatchingLoading && !timeSpent && (
        <Stat>
          {placeholder}
        </Stat>
      )}
      <Stat>
        <span>Time Spent Watching</span>
        <StyledAvatar
          colour={theme.colours.themeColours['--colour-27']}
        >
          {convertToRuntime(
            nonNull(timeSpentWatching)
              ? timeSpentWatching
              : 0
          )}
        </StyledAvatar>
      </Stat>
    </StatsRow>
  );
};

export default Stats;
