import React, {useState} from 'react';
import {useCreateMovieFromSearchResult, useListsWithMedia, useRemoveMovieFromList} from "../../api";
import styled from "styled-components";
import {Button, Checkbox, Icon, Loader} from "rsuite";
import Popover from "../Popover";
import {WatchProviderInputType} from "../../graphql-types";
import {Optional} from "../types";
import {useCreateTvFromSearchResult, useRemoveTvFromList} from "../../api/Tv";
import {nonNull} from "../../utils";

const Container = styled.div`
  position: relative;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DropdownIcon = styled(Icon)`
  margin-left: 1rem;
`;

const LoaderComponent = styled(Loader)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const ListCheckbox = styled(Checkbox)`
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;

type Props = {
  className?: string;
  mediaMovieDbId: Optional<number>;
  mediaId?: Optional<number>;
  mediaType: WatchProviderInputType;
};

const AddToList = ({className, mediaId, mediaType, mediaMovieDbId}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const variables = mediaType === 'TV'
    ? {
      tvWhere: {
        id: {
          equals: mediaId,
        },
      }
    }
    : {
      movieWhere: {
        id: {
          equals: mediaId,
        },
      }
    };

  const {data: lists} = useListsWithMedia(variables);

  const {createTv, loading: createTvLoading} = useCreateTvFromSearchResult();
  const {createMovie, loading: createMovieLoading} = useCreateMovieFromSearchResult();
  const {removeMovie, loading: removeMovieLoading} = useRemoveMovieFromList();
  const {removeTv, loading: removeTvLoading} = useRemoveTvFromList();

  const isLoading = createTvLoading || createMovieLoading || removeTvLoading || removeMovieLoading;

  const onToggle = (listId: number, value: boolean) => {
    if (!isLoading && nonNull(mediaMovieDbId)) {
      if (value) {
        if (mediaType === WatchProviderInputType.MOVIE) {
          createMovie(mediaMovieDbId, listId);
        } else {
          createTv(mediaMovieDbId, listId);
        }
      } else if (nonNull(mediaId)) {
        if (mediaType === WatchProviderInputType.MOVIE) {
          removeMovie(mediaId, listId);
        } else {
          removeTv(mediaId, listId);
        }
      }
    }
  };

  const isMediaInList = (listId: number) => {
    const list = (lists || []).find(list => list.id === listId);
    if (!list) return false;
    return mediaType === WatchProviderInputType.MOVIE
      ? list.movies.some(m => m.movie.id === mediaId || m.movie.movieDbId === mediaMovieDbId)
      : list.tv.some(t => t.tv.id === mediaId || t.tv.movieDbId === mediaMovieDbId);
  };

  return (
    <Container className={className}>
      <Button
        onClick={() => setIsPopoverOpen(true)}
      >
        Add to List
        <DropdownIcon icon="caret-down" />
      </Button>
      {isPopoverOpen && (
        <Popover
          direction="right"
          isOpen={isPopoverOpen}
          close={() => {
            setIsPopoverOpen(false);
          }}
        >
          {isLoading && <LoaderComponent />}
          <ListContainer>
            {(lists || []).map(l => {
              return (
                <ListCheckbox
                  key={l.id}
                  disabled={isLoading}
                  checked={isMediaInList(l.id)}
                  onChange={(_: any, checked: boolean) => onToggle(l.id, checked)}
                >
                  {l.name}
                </ListCheckbox>
              )
            })}
          </ListContainer>
        </Popover>
      )}
    </Container>
  );
};

export default AddToList;
