import React from 'react';
import {NonNull} from "../../utils/types";
import {getTv_tv, WatchProviderInputType} from "../../graphql-types";
import MediaPage from "../../components/MediaPage";
import {useCurrentTv} from "../../api/Tv/useTvQueries";
import {useCurrentTvCredits} from "../../api/TvCredits";

const TvPage = () => {
  const {data: tv, loading, called} = useCurrentTv();
  const {data: tvCredits} = useCurrentTvCredits();

  const nonNull = tv as NonNull<getTv_tv>;

  return (
    <MediaPage
      type={WatchProviderInputType.TV}
      loading={loading || !called}
      credits={tvCredits}
      media={nonNull}
    />
  );
};

export default TvPage;
