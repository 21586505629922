import {gql} from "@apollo/client";
import {PEOPLE_DETAILS_FIELDS, PERSON_MEDIA_FIELDS} from "./fragments";

export const GET_PEOPLE_DETAILS = gql`
  query getPeopleDetails($personId: Int!) {
    peopleDetails(personId: $personId) {
      ...PeopleDetailsFields
    }

    peopleCredits(personId: $personId) {
      ...PersonMediaFields
      lists {
        id
        name
      }
      viewings {
        ... on TvViewing {
          id
          createdAt
        }
        ... on MovieViewing {
          id
          createdAt
        }
      }
    }
  }
  ${PEOPLE_DETAILS_FIELDS}
  ${PERSON_MEDIA_FIELDS}
`;
