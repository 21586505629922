import {gql} from "@apollo/client";

export const LIST_FIELDS = gql`
  fragment ListFields on List {
    id
    name
    createdAt
    updatedAt
    movieCount
    tvShowCount
  }
`;

export const LIST_ACCESS_FIELDS = gql`
  fragment ListAccessFields on ListAccess {
    id
    accessType
    createdAt
    updatedAt
  }
`;
