import {
  getMovieCredits,
  getMovieCredits_movieCredits_cast,
  getMovieCredits_movieCredits_crew
} from "../../graphql-types";
import {renderOn, useNavigate} from "../../utils";
import React, {useMemo} from "react";
import styled, {css} from "styled-components";
import PlaceholderPerson from "../../assets/PlaceholderPerson";
import {Person as PersonType} from './types';
import {breakpoint} from "../../theme";
import {Button} from "rsuite";

const Wrapper = styled.div`
  padding: 2rem;
  padding-top: 0;
`;

const Title = styled.h3`
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${breakpoint('mobile')} {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &::after {
    content: '';
    flex: auto;
  }
`;

const containerMargin = css`
  margin: 0 1rem 2rem;
`;

const Card = styled.div`
  ${containerMargin}
`;

const ActorLink = styled(Button)`
  padding: 0;
`;

const CardImage = styled.div`
`;

const PlaceholderContainer = styled.div`
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 278px;
  width: 185px;
`;

const CardContent = styled.div`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 185px;
`;

const WordWrap = styled.span`
  word-wrap: break-word !important;
  white-space: normal;
  display: inline-block;
`;

type PersonProps = {
  person: PersonType;
  onClick?: () => void;
};

const Person = ({person, onClick}: PersonProps) => {
  return (
    <Card className="card">
      <CardImage className="card-image">
        {person.name && person.profilePath && <img src={person.profilePath} alt={person.name} />}
        {person.name && !person.profilePath && (
          <PlaceholderContainer>
            <PlaceholderPerson />
          </PlaceholderContainer>
        )}
      </CardImage>
      <CardContent className="card-content">
        {!onClick ? person.name : (
          <ActorLink
            onClick={onClick}
            className="good-word-break"
            appearance="link"
          >
            {person.name}
          </ActorLink>
        )}
        <div>
          {renderOn(
            (person as getMovieCredits_movieCredits_cast).character,
            character => <WordWrap>as {character}</WordWrap>,
            character => character.length > 0,
          )}
        </div>
        <div>
          {renderOn(
            (person as getMovieCredits_movieCredits_crew).job,
            job => <WordWrap>{job}</WordWrap>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const deduplicatePeople = (people: (getMovieCredits_movieCredits_cast | null)[] | (getMovieCredits_movieCredits_crew | null)[]) => {
  return Object.values(
    (people as Array<any>).reduce((acc, person) => {
      if (person && person.name) {
        if (!(person.name in acc)) {
          acc[person.name] = {...person};
        } else {
          const existing = acc[person.name];
          if (
            existing.character
            && person.character
          ) {
            existing.character += `, ${person.character}`;
          } else if (
            existing.job
            && person.job
          ) {
            existing.job += `, ${person.job}`;
          }
        }
      }
      return acc;
    }, {})
  );
};

type Props = {
  credits?: getMovieCredits['movieCredits'];
};

const MediaCredits = ({credits}: Props) => {
  const {goToPerson} = useNavigate();

  const actors = useMemo(() => {
    return deduplicatePeople(
      (credits?.cast || [])
        .filter(person => Boolean(person?.name))
    ) as getMovieCredits_movieCredits_crew[];
  }, [credits]);

  const crew = useMemo(() => {
    return deduplicatePeople(
      (credits?.crew || [])
        .filter(person => Boolean(person?.name))
    ) as getMovieCredits_movieCredits_cast[];
  }, [credits]);

  if (!credits || !credits.cast) return null;

  return (
    <Wrapper>
      <Title>Cast</Title>
      <Container>
        {credits && actors.length === 0 && <span>No actor information available.</span>}
        {actors.map((castMember, i) => (
          renderOn(castMember, actor => (
            <Person
              key={i}
              person={actor}
              onClick={() => {
                goToPerson(actor.movieDbId);
              }}
            />
          ))
        ))}
      </Container>
      <Title>Crew</Title>
      <Container>
        {credits && crew.length === 0 && <span>No crew information available</span>}
        {crew.map((crewMember, i) => (
          renderOn(crewMember, person => (
            <Person
              key={i}
              person={person}
              onClick={() => {
                goToPerson(person.movieDbId);
              }}
            />
          ))
        ))}
      </Container>
    </Wrapper>
  );
};

export default MediaCredits;
