import React from 'react';
import {Optional} from "./types";
import styled from "styled-components";
import {breakpoint} from "../theme";

const Image = styled.img`
  width: 150px;

  ${breakpoint('tablet')} {
    width: 100px;
  }
`;

type Props = {
  src: Optional<string>;
  alt: Optional<string>;
  className?: string;
};

const MediaImage = ({ src, alt, className }: Props) => {
  if (!src || !alt) return null;
  return (
    <Image
      className={className}
      src={src}
      alt={alt}
    />
  );
};

export default MediaImage;
