import {
  getWatchProviders,
  getWatchProviders_watchProviders,
} from "../../graphql-types";
import styled from "styled-components";
import React, {useMemo} from "react";
import {NonNull} from "../../utils/types";
import {Loader} from "rsuite";

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 64px;
  flex-wrap: wrap;
`;

export const ProviderName = styled.div`
  text-align: center;
`;

const Attribution = styled.p`
  margin-bottom: 0.75rem;
`;

type Props = {
  watchProviders?: getWatchProviders['watchProviders'];
  className?: string;
  loading: boolean;
};

type WatchProviderMapping = NonNull<getWatchProviders_watchProviders>;

const WatchProviders = ({watchProviders, className = '', loading}: Props) => {
  const watchList = (watchProviders || []).filter(
    watch => Boolean(watch?.watchProvider?.logoPath) && Boolean(watch?.watchProvider?.providerName)
  ) as WatchProviderMapping[];

  const uniqueProviders = useMemo(() => {
    const providers = watchList
      .map(watch => watch.watchProvider);
    return providers.filter((provider, i) => providers.findIndex(p => p.id === provider.id) === i);
  }, [watchList]);

  if (loading) {
    return <Loader />;
  }

  if (watchList.length === 0 && !loading) return null;

  return (
    <div className={className}>
      <h4>Available To Watch On:</h4>
      <Attribution>(Provided by JustWatch)</Attribution>
      <FlexWrapper>
        {uniqueProviders.map(provider => (
          <Wrapper key={provider.id}>
            <img
              className="image is-64x64"
              src={provider?.logoPath}
              alt={provider?.providerName}
            />
            <ProviderName>{provider?.providerName}</ProviderName>
          </Wrapper>
        ))}
      </FlexWrapper>
    </div>
  );
};

export default WatchProviders;
