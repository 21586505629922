import {gql} from "@apollo/client";
import {SEARCH_RESULT_FIELDS} from "./fragments";

export const SEARCH_MEDIA = gql`
  query mediaSearch($query: String!, $page: Int) {
    mediaSearch(query: $query, page: $page) {
      data {
        ...SearchResultFields
      }
      page
      totalPages
      totalResults
    }
  }
  ${SEARCH_RESULT_FIELDS}
`;
