import {useMutation} from "@apollo/client";
import {
  createTvFromSearch,
  createTvFromSearchVariables, createTvVariables,
  removeTvFromList,
  removeTvFromListVariables,
  createTv,
} from "../../graphql-types";
import {useBuiltUserSettings} from "../UserSettings/userUserSettingsQueries";
import {useCallback} from "react";
import {isNumber} from "../../utils";
import {GET_LIST, GET_LISTS} from "../List/queries";
import {getListParameters, getListsParameters} from "../List";
import {listSortVar} from "../../stores";
import {CREATE_TV, CREATE_TV_FROM_SEARCH, REMOVE_TV_FROM_LIST} from "./mutations";

export const useCreateTvFromSearchResult = () => {
  const [createMovie, result] = useMutation<createTvFromSearch, createTvFromSearchVariables>(CREATE_TV_FROM_SEARCH);
  const {data: userSettings} = useBuiltUserSettings();

  const create = useCallback(async (id: number | undefined, listId: number | undefined) => {
    if (isNumber(id) && isNumber(listId)) {
      const result = await createMovie({
        variables: {
          id,
          listId,
          locale: userSettings.locale,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {query: GET_LIST, variables: getListParameters(listId)},
          'getTvForList',
          'getRecentActivities',
          'getTv',
          'getTvMovieDbIds',
        ],
      });
      return result.data?.createTvFromSearchResult?.tv || null;
    }
    return null;
  }, [createMovie, userSettings.locale]);

  return {
    ...result,
    createTv: create,
  };
};

export const useCreateTv = () => {
  const [createTv, result] = useMutation<createTv, createTvVariables>(CREATE_TV);
  const {data: userSettings} = useBuiltUserSettings();

  const create = useCallback((tvId: number) => {
    return createTv({
      variables: {
        id: tvId,
        locale: userSettings.locale,
      }
    });
  }, [
    createTv,
    userSettings,
  ]);

  return {
    ...result,
    createTv: create,
  };
};

export const useRemoveTvFromList = () => {
  const [removeTv, result] = useMutation<removeTvFromList, removeTvFromListVariables>(REMOVE_TV_FROM_LIST);
  const {data: userSettings} = useBuiltUserSettings();

  const remove = useCallback(async (tvId: number | undefined, listId: number | undefined) => {
    if (isNumber(tvId) && isNumber(listId)) {
      const result = await removeTv({
        variables: {
          listId,
          tvId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {query: GET_LIST, variables: getListParameters(listId)},
          {query: GET_LISTS, variables: getListsParameters(listSortVar())},
          'getTvForList',
          'getTv',
          'getTvMovieDbIds',
        ],
      });
      return result.data?.removeTvFromList?.tv || null;
    }
    return null;
  }, [removeTv, userSettings.locale]);

  return {
    ...result,
    removeTv: remove,
  };
};
