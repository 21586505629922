import {LoginButton} from "../../components";
import {Content, Header} from "rsuite";
import Attribution from "../../components/Attribution";
import demoImage from '../../assets/Screenshot 2021-02-07 at 17.24.46.png';
import React from "react";
import styled from "styled-components";

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const DemoImage = styled.img`
  width: 500px;
`;

const LoginRequired = () => {
  return (
    <>
      <Attribution />
      <Header>
        <h1 className="is-size-1 mb-4">Movie Manage</h1>
      </Header>
      <Content className="is-size-5">
        <p>This is a tool to create watch lists for films you are interested in.</p>
        <p>You can create shared lists to share with friends, find out which movies are available on your preferred streaming services, and generally organise films you want to watch.</p>
        <ImageContainer>
          <DemoImage src={demoImage} alt="List Demo" />
        </ImageContainer>
        <p>Create an account/login below to get started (it's free!).</p>
      </Content>
      <LoginButton
        className="mt-6"
        appearance="primary"
        size="lg"
      />
    </>
  );
};

export default LoginRequired;
