import {useMemo} from "react";

type Props = {
  children: string;
  maxLength?: number;
};

export const useTruncateText = (text: string, maxLength = 100) => {
  return useMemo(() => {
    // @ts-ignore
    const asCharacterPoints = [...text];
    if (asCharacterPoints.length > maxLength) {
      const firstIndexOfSpace = asCharacterPoints.slice(maxLength).findIndex(
        char => char === ' ',
      );
      return {
        truncated: `${asCharacterPoints.slice(0, firstIndexOfSpace + maxLength).join('')}...`,
        isTruncated: true,
      }
    }
    return {
      truncated: text,
      isTruncated: false,
    };
  }, [text, maxLength]);
};

const TruncateText = ({children, maxLength}: Props) => {
  const {truncated} = useTruncateText(children, maxLength);
  return <>{truncated}</>;
};

export default TruncateText;
