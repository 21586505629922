import React, {useEffect, useMemo, useState} from 'react';
import defaultTheme, {ThemeShape} from "../theme";
import ThemeStoreContext, {ThemeStore as ThemeStoreType} from "../ThemeStoreContext";
import {useBuiltUserSettings} from "../api/UserSettings/userUserSettingsQueries";
import {
  mapColourSettingsToColours,
  updateThemeWithColours,
  BASE_PRIMARY_COLOUR_KEY,
  BASE_BACKGROUND_COLOUR_KEY,
  useDefaultTheme,
} from "../utils";
import {useAuth0} from "@auth0/auth0-react";
import QueryLoader from "./QueryLoader";

type Props = {
  children: (theme: ThemeShape) => React.ReactNode;
};

const ThemeStore = ({children}: Props) => {
  const {data: userSettings} = useBuiltUserSettings();

  const [{theme}, setTheme] = useState<ThemeStoreType>({
    theme: defaultTheme,
    setTheme: () => {
    },
  });

  const value = useMemo(() => ({
    theme,
    setTheme: (newTheme: ThemeShape) => {
      setTheme({
        theme: newTheme,
        // @ts-ignore
        setTheme,
      });
    },
  }), [theme, setTheme]);

  useEffect(() => {
    const mappedColours = mapColourSettingsToColours([
      {
        themeKey: BASE_PRIMARY_COLOUR_KEY,
        colour: userSettings.colour.primaryColour,
      },
      {
        themeKey: BASE_BACKGROUND_COLOUR_KEY,
        colour: userSettings.colour.backgroundColour,
      }
    ]);

    const updatedTheme = updateThemeWithColours(mappedColours, value.theme);

    value.setTheme(updatedTheme);
  }, [value, userSettings]);

  return (
    <QueryLoader data={userSettings} renderLoader={() => null}>
      <ThemeStoreContext.Provider value={value}>
        {children(theme)}
      </ThemeStoreContext.Provider>
    </QueryLoader>
  );
};

const SwitchOnAuth = ({children}: Props): JSX.Element => {
  const {isAuthenticated, isLoading} = useAuth0();
  const baseTheme = useDefaultTheme();
  if (isLoading) return <>{null}</>;
  if (isAuthenticated) {
    return <ThemeStore>{children}</ThemeStore>;
  }
  return <>{children(baseTheme)}</>;
};

export default SwitchOnAuth;
