import {useMutation} from "@apollo/client";
import {DELETE_ACCOUNT, SYNC_ME} from "./userMutations";
import {deleteAccount, syncMe} from '../../graphql-types';

export const useUserMutations = () => {
  const [syncMe, result] = useMutation<syncMe, {}>(SYNC_ME);

  return {
    syncMe,
    ...result
  }
};

export const useDeleteAccount = () => {
  const [doDelete, result] = useMutation<deleteAccount, {}>(DELETE_ACCOUNT);

  return {
    deleteAccount: doDelete,
    ...result,
  };
};
