import {gql} from "@apollo/client";
import {MOVIE_VIEWING_FIELDS} from "./fragments";

export const CREATE_MOVIE_VIEWING = gql`
  mutation createMovieViewing($movieId: Int!) {
    createMovieViewing(movieId: $movieId) {
      ...MovieViewingFields
      movie {
        id
        movieViewings {
          id
        }
      }
    }
  }
  ${MOVIE_VIEWING_FIELDS}
`;

export const REMOVE_MOVIE_VIEWINGS = gql`
  mutation removeMovieViewings($movieId: Int!) {
    removeMovieViewings(movieId: $movieId) {
      id
      movieViewings {
        ...MovieViewingFields
      }
    }
  }
  ${MOVIE_VIEWING_FIELDS}
`;
