import React from 'react';
import {useRecentActivities} from "../../api/RecentActivity";
import {getRecentActivities_recentActivity, RecentActivityType} from "../../graphql-types";
import {formatDate, nonNull, useListRouteParams, useNavigate} from "../../utils";
import {range} from "ramda";
import {Button, Container, Placeholder} from 'rsuite';
import styled from "styled-components";
import {useGetMe} from "../../api/User/useUserQueries";
import UserAvatar from "../UserAvatar";
import {breakpoint} from "../../theme";

const activityTypes = [
  RecentActivityType.MOVIE_ADDITION,
  RecentActivityType.MOVIE_VIEWING,
  RecentActivityType.TV_VIEWING,
  RecentActivityType.TV_ADDITION,
];

const RecentActivityElement = styled.div`
  padding: 1rem;

  margin-bottom: 0.5rem;
  margin-top: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RecentActivityTitle = styled.div.attrs({className: 'good-word-break'})`
  font-size: 1.25rem;
`;

const RecentActivityMediaLink = styled(Button)`
  font-size: 1.25rem;
  text-align: left;
  padding: 0;
`;

const MediaAdditionLink = styled(Button)`
  font-size: 1.25rem;
  padding: 0;
  text-align: left;
`;

const ActivityDate = styled.div``;

const IMAGE_SIZE = '5rem';
const MID_IMAGE_SIZE = '4rem';

const ImageContainer = styled.div`
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};

  ${breakpoint('tablet')} {
    width: ${MID_IMAGE_SIZE};
    height: ${MID_IMAGE_SIZE};
  }

  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-right: 2rem;
`;

const Avatar = styled(UserAvatar)`
  text-align: center;
  flex-grow: 1;
  font-size: 1.4rem;

  ${breakpoint('tablet')} {
    font-size: 1.2rem;
  }
`;

const ActivityImage = styled.img`
  width: ${IMAGE_SIZE};
`;

const TextContent = styled.div``;

type ActivityProps = {
  recentActivity: getRecentActivities_recentActivity;
  isMe: boolean;
  goToMedia: (id: number) => void;
  mediaKey: 'movie' | 'tv';
}

const MediaAdded = ({recentActivity, mediaKey, goToMedia}: ActivityProps) => {
  const firstList = recentActivity.lists && recentActivity.lists[0];
  const media = recentActivity && recentActivity[mediaKey as keyof typeof recentActivity];

  return (
    <TextContent>
      <RecentActivityTitle>
        <span>
          <MediaAdditionLink
            appearance="link"
            className="good-word-break"
            onClick={() => goToMedia(media?.id as number)}
          >
            "{media?.title}"
          </MediaAdditionLink>&nbsp;
          {recentActivity.details} {firstList?.name}
        </span>
      </RecentActivityTitle>
      <ActivityDate>
        {formatDate(recentActivity.date)}
      </ActivityDate>
    </TextContent>
  );
};

const MediaWatched = ({recentActivity, isMe, goToMedia, mediaKey}: ActivityProps) => {
  const media = recentActivity && recentActivity[mediaKey as keyof typeof recentActivity];
  return (
    <TextContent>
      <RecentActivityTitle>
        <span>{recentActivity?.user?.firstName} {recentActivity?.user?.lastName} {isMe ? ' (you)' : ''} {recentActivity?.details}&nbsp;
          <RecentActivityMediaLink
            appearance="link"
            className="good-word-break"
            onClick={() => goToMedia(media?.id as number)}
          >
            "{media?.title}"
          </RecentActivityMediaLink>
        </span>
      </RecentActivityTitle>
      <ActivityDate>
        {formatDate(recentActivity.date)}
      </ActivityDate>
    </TextContent>
  );
};

const RecentActivity = () => {
  const {listId} = useListRouteParams();
  const {data, loading} = useRecentActivities(activityTypes, listId);
  const {data: me} = useGetMe();
  const {goToMovie, goToTv} = useNavigate();

  const nonNullData = (data || []).filter(nonNull);

  if (loading) {
    return (
      <Container className="container pt-4">
        {range(0, 5).map((i) => <Placeholder.Paragraph key={i} />)}
      </Container>
    );
  }

  return (
    <Container className="container mb-6">
      {nonNullData.length === 0 && !loading && (
        <span className="is-size-4 mt-6">There's no recent activity.</span>
      )}
      {nonNullData.map((recentActivity, i) => {
        const isMe = recentActivity?.user?.id === me?.id;
        const isMovieAddition = recentActivity?.type === RecentActivityType.MOVIE_ADDITION;
        const isMovieViewing = recentActivity?.type === RecentActivityType.MOVIE_VIEWING;
        const isTvAddition = recentActivity?.type === RecentActivityType.TV_ADDITION;
        const isTvViewing = recentActivity?.type === RecentActivityType.TV_VIEWING;

        const media = recentActivity.movie || recentActivity.tv;

        return (
          <RecentActivityElement key={i}>
            <ImageContainer>
              {(isMovieAddition || isTvAddition) && media?.posterPath && (
                <ActivityImage
                  src={media.posterPath}
                  alt={media.title}
                />
              )}
              {(isMovieViewing || isTvViewing) && (
                <Avatar user={recentActivity.user} />
              )}
            </ImageContainer>
            {isMovieViewing && (
              <MediaWatched
                isMe={isMe}
                goToMedia={goToMovie}
                recentActivity={recentActivity}
                mediaKey="movie"
              />
            )}
            {isMovieAddition && (
              <MediaAdded
                isMe={isMe}
                goToMedia={goToMovie}
                recentActivity={recentActivity}
                mediaKey="movie"
              />
            )}
            {isTvViewing && (
              <MediaWatched
                recentActivity={recentActivity}
                isMe={isMe}
                goToMedia={goToTv}
                mediaKey="tv"
              />
            )}
            {isTvAddition && (
              <MediaAdded
                recentActivity={recentActivity}
                isMe={isMe}
                goToMedia={goToTv}
                mediaKey="tv"
              />
            )}
          </RecentActivityElement>
        );
      })}
    </Container>
  );
};

export default RecentActivity;
