import {Optional} from '../types';
import {convertToRuntime, formatYear, nonNull, renderOn} from "../../utils";
import React from "react";

type Props = {
  runtime?: Optional<number>;
  seasons?: Optional<number>;
  releaseDate?: Optional<string>;
  time?: Optional<number>;
};

const MediaTimes = ({runtime, releaseDate, seasons}: Props) => {
  return (
    <>
      {renderOn(
        releaseDate,
        date => <span>{formatYear(date)}</span>,
        date => !isNaN(formatYear(date))
      )}
      {renderOn(
        releaseDate && (runtime || nonNull(seasons)),
        () => <span> • </span>,
        () => !isNaN(formatYear(releaseDate as string)) && (
          (runtime as number) > 0
          || nonNull(seasons)
        )
      )}
      {renderOn(
        runtime,
        time => <span>{convertToRuntime(time)}</span>,
        time => time > 0,
      )}
      {renderOn(
        seasons,
        seasons => <span>{seasons} seasons</span>,
        () => !runtime,
      )}
    </>
  );
};

export default MediaTimes;
