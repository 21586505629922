import React from 'react';
import {getPeopleDetails_peopleDetails} from "../../graphql-types";
import styled, {css} from "styled-components";
import {Placeholder} from 'rsuite';
import {Optional} from "../types";
import {formatAge, formatFullDate, renderOn} from "../../utils";
import {breakpoint} from "../../theme";
import ImdbButton from "../ImdbButton";
import Biography from "./Biography";

const IMAGE_WIDTH = '185px';
const IMAGE_HEIGHT = '278px';

const Name = styled.h1`
`;

const imageSize = css`
  width: ${IMAGE_WIDTH};
  height: ${IMAGE_HEIGHT};
  min-width: ${IMAGE_WIDTH};
`;

const ProfileImage = styled.img`
  margin-bottom: 1rem;
  ${imageSize}
`;

const ProfileImagePlaceholder = styled(Placeholder.Graph)`
  ${imageSize}
`;

const ImageContainer = styled.div`
  margin-right: 3rem;
  margin-bottom: 2rem;
  
  ${breakpoint('mobile')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-shrink: 1;

  margin-bottom: 5rem;

  ${breakpoint('tablet')} {
    margin-bottom: 3rem;
  }

  ${breakpoint('mobile')} {
    margin-bottom: 1rem;
  }

  ${breakpoint('mobile')} {
    position: relative;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-basis: 60%;
  width: 100%;

  & > * {
    margin-bottom: 1rem;
  }
`;

type Props = {
  person: Optional<getPeopleDetails_peopleDetails>;
  className?: string;
  loading: boolean;
};

const PersonDetails = ({person, className, loading}: Props) => {
  return (
    <ProfileContainer className={className}>
      <ImageContainer>
        {renderOn(
          person?.profilePath,
          path => (
            <ProfileImage src={path} />
          )
        )}
        {loading && (
          <ProfileImagePlaceholder graph="image" />
        )}
        {renderOn(person?.name, name => <Name>{name}</Name>)}
        {loading && <Placeholder.Paragraph rows={1} /> }
        <div>
          {person?.imdbId && person.name && (
            <ImdbButton
              imdbId={person.imdbId}
              title={person.name}
              type="name"
            />
          )}
        </div>
      </ImageContainer>
      <ProfileSection>
        {renderOn(person?.placeOfBirth,
          birth => (
            <div>
              <h4>From</h4>
              <p className="good-word-break">{birth}</p>
            </div>
          ))
        }
        {loading && <Placeholder.Paragraph rows={2} />}
        {renderOn(
          person?.birthday,
          date => (
            <div>
              <h4>Age</h4>
              <p>{formatAge(date, person?.deathday)} - Born {formatFullDate(date)}{person?.deathday && <span>, Died {formatFullDate(person.deathday)}</span>}</p>
            </div>
          )
        )}
        {loading && <Placeholder.Paragraph rows={2} />}
        {renderOn(
          person?.biography,
          biography => (
            <div>
              <h4>Biography</h4>
              <Biography biography={biography} />
            </div>
          ),
          biography => biography.length > 0
        )}
        {loading && <Placeholder.Paragraph rows={10} />}
      </ProfileSection>
    </ProfileContainer>
  );
};

export default PersonDetails;
