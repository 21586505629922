import {useNavigate} from "../../utils";
import {Button} from "rsuite";
import styled from "styled-components";

const MovieLink = styled(Button)`
  white-space: normal;
  text-align: left;
`;

type Props = {
  title: string | null | undefined;
  onClick?: () => void;
};

const MediaTitleLink = ({
  title, onClick = () => {
  }
}: Props) => {
  return (
    <MovieLink
      appearance="link"
      className="is-size-5 p-0"
      componentClass="a"
      onClick={onClick}
    >
      {title}
    </MovieLink>
  );
};

export default MediaTitleLink;
