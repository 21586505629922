import {AppPaths, nonNull, renderOn, useNavigate, useNavigateContext, useScrollOnMount} from "../../utils";
import CreateMedia from "../../components/CreateMedia";
import {listMovieFilterVar, listTvFilterVar, movieSortVar, tvSortVar, useMediaListFilter, useModal} from "../../stores";
import {Icon, IconButton, Nav} from "rsuite";
import {SortField} from "../../components/Sort";
import {
  useCreateMovieViewing,
  useCurrentList,
  useCurrentListMovies,
  useRemoveMovieFromList, useRemoveMovieViewings
} from "../../api";
import React, {useEffect, useMemo} from "react";
import styled, {createGlobalStyle} from "styled-components";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import TitleContainer from "../../components/list/TitleContainer";
import EditListDropdown from "../../components/list/EditListDropdown";
import {List} from "../../components";
import RecentActivity from "../../components/RecentActivity";
import {useCurrentListTv} from "../../api/Tv/useTvQueries";
import ListControls from "../../components/list/ListControls";
import {ControlContext} from "../../components/list";
import {useRemoveTvFromList} from "../../api/Tv";
import {Media} from "../../components/list/types";
import {useCreateTvViewing, useRemoveTvViewings} from "../../api/TvViewing";
import {containerStyle} from "../../components/list/styles";
import useListState from "../../components/list/useListState";
import {useReactiveVar} from "@apollo/client";

const CustomNav = styled(Nav)`
  margin-top: 2rem;
`;

const TopContainer = styled.div`
  padding-top: 4rem;
  
  ${containerStyle}
`;

const GlobalStyle = createGlobalStyle`
  body {
    padding-bottom: 0;
  }
`;

const StyledTitleContainer = styled(TitleContainer)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const StyledListControls = styled(ListControls)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const movieSortFields: SortField[] = [
  {
    fieldName: 'title',
    label: 'Alphabetically',
  },
  {
    fieldName: 'runtime',
    label: 'Runtime',
  },
  {
    fieldName: 'releaseDate',
    label: 'Year',
  },
  {
    fieldName: 'popularity',
    label: 'Popularity',
  },
  {
    fieldName: 'revenue',
    label: 'Revenue',
  },
  {
    fieldName: 'addedToListAt',
    label: 'Date Added',
  }
];

const tvSortFields: SortField[] = [
  {
    fieldName: 'title',
    label: 'Alphabetically',
  },
  {
    fieldName: 'numberOfSeasons',
    label: 'Seasons',
  },
  {
    fieldName: 'releaseDate',
    label: 'Released',
  },
  {
    fieldName: 'popularity',
    label: 'Popularity',
  },
  {
    fieldName: 'addedToListAt',
    label: 'Date Added',
  }
];

const navKeys = {
  LIST_RECENT_ACTIVITY: 'LIST_RECENT_ACTIVITY',
  LIST_MOVIES: 'LIST_MOVIES',
  LIST_TV: 'LIST_TV',
};

const ListPage = () => {
  const {goToList, goToListRecentActivity, goToListTv, goToMovie, goToTv} = useNavigate();
  const {open, id} = useModal();
  const {data: list, called, loading} = useCurrentList();
  const navigateContext = useNavigateContext();

  const movieSort = useReactiveVar(movieSortVar);
  const tvSort = useReactiveVar(tvSortVar);

  const {removeMovie} = useRemoveMovieFromList();
  const {createMovieViewing} = useCreateMovieViewing();
  const {removeMovieViewings} = useRemoveMovieViewings();

  const {removeTv} = useRemoveTvFromList();
  const {createTvViewing} = useCreateTvViewing();
  const {removeTvViewings} = useRemoveTvViewings();

  const [movieFilter] = useMediaListFilter(listMovieFilterVar);
  const [tvFilter] = useMediaListFilter(listTvFilterVar);

  const listMovieMatch = Boolean(useRouteMatch(AppPaths.LIST_MOVIES));
  const listTvMatch = Boolean(useRouteMatch(AppPaths.LIST_TV));

  const [tvNonViewedState, setTvNonViewedState] = useListState();
  const [tvViewedState, setTvViewedState] = useListState();
  const [movieNonViewedState, setMovieNonViewedState] = useListState();
  const [movieViewedState, setMovieViewedState] = useListState();

  useScrollOnMount();

  useEffect(() => setTvNonViewedState({ page: 1 }), [tvFilter.providers]);
  useEffect(() => setMovieNonViewedState({ page: 1 }), [movieFilter.providers]);

  const key = (() => {
    if (listMovieMatch) return navKeys.LIST_MOVIES;
    if (listTvMatch) return navKeys.LIST_TV;
    return navKeys.LIST_RECENT_ACTIVITY;
  })();

  const onNavigate = (key: string) => {
    switch (key) {
      case navKeys.LIST_MOVIES: {
        goToList(list?.id);
        break;
      }
      case navKeys.LIST_TV: {
        goToListTv(list?.id);
        break;
      }
      case navKeys.LIST_RECENT_ACTIVITY: {
        goToListRecentActivity(list?.id);
        break;
      }
    }
  };

  const movieControlContextValue = useMemo(
    () => ({
      removeMedia: (media: Media) => removeMovie(media.id, list?.id),
      toggleViewing: (media: Media) => {
        if (media.viewings.length === 0) {
          createMovieViewing(media.id);
        } else {
          removeMovieViewings(media.id);
        }
      },
      goToMedia: (media: Media) => {
        goToMovie(media.id, list?.id);
      },
    }),
    [
      list,
      removeMovie,
      createMovieViewing,
      removeMovieViewings,
      goToMovie,
    ]
  );

  const tvControlContextValue = useMemo(
    () => ({
      removeMedia: (media: Media) => removeTv(media.id, list?.id),
      toggleViewing: (media: Media) => {
        if (media.viewings.length === 0) {
          createTvViewing(media.id);
        } else {
          removeTvViewings(media.id);
        }
      },
      goToMedia: (media: Media) => {
        goToTv(media.id);
      },
    }),
    [
      createTvViewing,
      removeTvViewings,
      goToTv,
      list,
      removeTv,
    ]
  );

  useEffect(() => {
    if (!loading && called && !list) {
      navigateContext.setIs404();
    }
  }, [navigateContext, loading, called, list]);

  return (
    <>
      <GlobalStyle />
      <CreateMedia id={id} />
      <TopContainer className="container">
        {renderOn(
          list,
          (list) => (
            <StyledTitleContainer
              title={list.name}
              elementRight={(
                <div className="is-flex is-flex-direction-row">
                  <IconButton
                    icon={<Icon icon="search" />}
                    onClick={open}
                  >
                    Search Media
                  </IconButton>
                  <div className="ml-2">
                    <EditListDropdown listId={list.id} />
                  </div>
                </div>
              )}
            />
          ),
        )}
        <CustomNav
          appearance="subtle"
          activeKey={key}
          onSelect={onNavigate}
        >
          <Nav.Item
            eventKey={navKeys.LIST_MOVIES}
          >
            Movies {nonNull(list?.movieCount) && `(${list?.movieCount})`}
          </Nav.Item>
          <Nav.Item eventKey={navKeys.LIST_TV}>
            TV {nonNull(list?.tvShowCount) && `(${list?.tvShowCount})`}
          </Nav.Item>
          <Nav.Item eventKey={navKeys.LIST_RECENT_ACTIVITY}>Recent Activity</Nav.Item>
        </CustomNav>
        <Switch>
          <Route {...AppPaths.LIST_MOVIES}>
            <StyledListControls
              sortVar={movieSortVar}
              filterListVar={listMovieFilterVar}
              sortFields={movieSortFields}
            />
          </Route>
          <Route {...AppPaths.LIST_TV}>
            <StyledListControls
              sortFields={tvSortFields}
              sortVar={tvSortVar}
              filterListVar={listTvFilterVar}
            />
          </Route>
        </Switch>
      </TopContainer>
      <Switch>
        <Route {...AppPaths.LIST_MOVIES}>
          <div>
            <ControlContext.Provider value={movieControlContextValue}>
              <List
                labelPlural="movies"
                hook={useCurrentListMovies}
                filter={movieFilter}
                nonViewedState={movieNonViewedState}
                setNonViewedState={setMovieNonViewedState}
                viewedState={movieViewedState}
                setViewedState={setMovieViewedState}
                order={movieSort}
              />
            </ControlContext.Provider>
          </div>
        </Route>
        <Route {...AppPaths.LIST_TV}>
          <div>
            <ControlContext.Provider value={tvControlContextValue}>
              <List
                labelPlural="TV shows"
                hook={useCurrentListTv}
                filter={tvFilter}
                nonViewedState={tvNonViewedState}
                setNonViewedState={setTvNonViewedState}
                viewedState={tvViewedState}
                setViewedState={setTvViewedState}
                order={tvSort}
              />
            </ControlContext.Provider>
          </div>
        </Route>
        <Route {...AppPaths.LIST_RECENT_ACTIVITY}>
          <RecentActivity />
        </Route>
      </Switch>
    </>
  );
};

export default ListPage;
