import {Button, ButtonProps} from "rsuite";
import {useAuth0} from "@auth0/auth0-react";
import styled from "styled-components";
import {ThemeProps} from "../theme";

const RealLoginButton = styled(Button)`
  background-color: ${({theme}: ThemeProps) => theme.colours.themeColours['--colour-2']};
`;

export const LoginButton = (props: ButtonProps) => {
  const {loginWithRedirect} = useAuth0();

  return (
    <RealLoginButton
      onClick={loginWithRedirect}
      {...props}
    >
      Login/Sign up
    </RealLoginButton>
  );
};
