import {gql} from "@apollo/client";
import {LIST_FIELDS} from "./fragments";

export const CREATE_LIST = gql`
  mutation createList($name: String!) {
    createList(name: $name) {
      ...ListFields
    }
  }
  ${LIST_FIELDS}
`;

export const DELETE_LIST = gql`
  mutation deleteList($id: Int!) {
    deleteOneList(id: $id) {
      id
    }
  }
`;

export const UPDATE_LIST_NAME = gql`
  mutation updateListName($listId: Int!, $name: String!) {
    updateListName(id: $listId, name: $name) {
      id
      name
    }
  }
`;

export const CREATE_SHARED_LINK = gql`
  mutation createSharedLink($listId: Int!) {
    createSharedListAccess(listId: $listId)
  }
`;

export const REDEEM_SHARED_LINK = gql`
  mutation redeemSharedLink($code: String!) {
    redeemInvite(code: $code) {
      ...ListFields
    }
  }
  ${LIST_FIELDS}
`;

export const REMOVE_LIST_ACCESS = gql`
  mutation removeListAccess($listAccessId: Int!) {
    removeSharedListAccess(listAccessId: $listAccessId)
  }
`;
