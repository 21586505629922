import {useLazyQuery, useQuery, useReactiveVar} from "@apollo/client";
import {GET_LISTS, GET_LIST, GET_LISTS_WITH_MEDIA} from "./queries";
import {useExtractQueryValue} from "../utils";
import {
  getList,
  getLists,
  getListsVariables,
  getListsWithMedia,
  getListsWithMediaVariables,
  getListVariables,
  ListAccessType,
  MovieWhereInput, TvWhereInput
} from "../../graphql-types";
import {listSortVar, ListSummarySort} from "../../stores";
import {useListRouteParams} from "../../utils";
import {useEffect, useMemo} from "react";
import {useGetMe} from "../User/useUserQueries";

export const getListsParameters = (sortOrder: ListSummarySort) => {
  return {
    orderBy: sortOrder,
  };
};

export const useUserLists = () => {
  const listOrder = useReactiveVar(listSortVar);
  return useExtractQueryValue(
    useQuery<getLists, getListsVariables>(GET_LISTS, {
      variables: getListsParameters(listOrder),
      fetchPolicy: 'network-only',
    }),
    'lists'
  );
};

export const useListsWithMedia = ({
  movieWhere,
  tvWhere,
}: {
  movieWhere?: MovieWhereInput;
  tvWhere?: TvWhereInput
}) => {
  return useExtractQueryValue(
    useQuery<getListsWithMedia, getListsWithMediaVariables>(GET_LISTS_WITH_MEDIA, {
      fetchPolicy: 'network-only',
      variables: {
        movieWhere,
        tvWhere,
      }
    }),
    'lists'
  );
};

export const getListParameters = (listId: number) => {
  return {
    where: {
      id: listId,
    }
  }
};

export const useList = (listId: number | undefined) => {
  const [fetchList, result] = useLazyQuery<getList, getListVariables>(GET_LIST, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (typeof listId !== 'undefined') {
      fetchList({
        variables: getListParameters(listId),
      });
    }

  }, [listId, fetchList]);

  return useExtractQueryValue(result, 'list');
};

export const useCurrentList = () => {
  const {listId} = useListRouteParams();
  return useList(listId);
};

export const useIsListOwner = (listId: number) => {
  const {data: me} = useGetMe();
  const {data: list} = useList(listId);

  return useMemo(() => {
    if (typeof me?.id === 'undefined') return false;
    return list?.listAccesses.some(access => (
      access.accessType === ListAccessType.OWNER
      && access.user?.id === me?.id
    ));
  }, [list, me]);
};
