import {useTvRouteParams} from "../../utils";
import {useLazyQuery} from "@apollo/client";
import {getTvCredits, getTvCreditsVariables} from "../../graphql-types";
import {useEffect} from "react";
import {useExtractQueryValue} from "../utils";
import {GET_TV_CREDITS} from "./queries";

export const useCurrentTvCredits = () => {
  const {tvId} = useTvRouteParams();
  const [doFetch, result] = useLazyQuery<getTvCredits, getTvCreditsVariables>(GET_TV_CREDITS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (typeof tvId !== 'undefined') {
      doFetch({
        variables: {
          tvId,
        }
      });
    }
  }, [doFetch, tvId]);

  return useExtractQueryValue(result, 'tvCredits');
};