import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Auth0Provider} from "@auth0/auth0-react";
import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import GraphqlReactLink from "./GraphqlReactLink";
import {UserSync} from "./components";
import DefaultCountryCode from './DefaultCountryCode';
import './index.css';
import 'bulma/css/bulma.min.css';
import './styles/rsuite-with-variables.css';
import './styles/overrides.css';
import {ThemeProvider} from "styled-components";
import ThemeStore from "./components/ThemeStore";
import GlobalStyles from "./theme/GlobalStyles";
import Head from "./Head";
import ErrorComponent from "./ErrorComponent";
import {getFirstRegion} from "./utils";
import {WatchProviderLocale} from "./graphql-types";

import * as types from 'styled-components/cssprop'; // eslint-disable-line

(async () => {
  try {
    const response = await fetch('/graphql', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        query: `
      {
        appConfig {
          auth0Domain
          auth0ClientId
          audience
        }
      }
    `
      }),
    });

    const countryCode = getFirstRegion();

    const result = await response.json();

    const appConfig = result.data.appConfig;
    const {auth0Domain, auth0ClientId, audience} = appConfig;
    // @ts-ignore
    window.movieManageAppConfig = appConfig;

    ReactDOM.render(
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        redirectUri={window.location.origin}
        audience={audience}
        scope="all"
        cacheLocation="localstorage"
      >
        <DefaultCountryCode.Provider value={countryCode || WatchProviderLocale.GB}>
          <GraphqlReactLink>
            <Router>
              <UserSync>
                <ThemeStore>
                  {(theme) => (
                    <ThemeProvider theme={theme}>
                      <GlobalStyles />
                      <Head />
                      <App />
                    </ThemeProvider>
                  )}
                </ThemeStore>
              </UserSync>
            </Router>
          </GraphqlReactLink>
        </DefaultCountryCode.Provider>
      </Auth0Provider>,
      document.getElementById("root")
    );
  } catch (e) {
    console.error(e);
    ReactDOM.render(
      <ErrorComponent />,
      document.getElementById("root")
    );
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
