import {Dropdown, Icon} from "rsuite";
import styled from "styled-components";
import ConfirmDeleteModal from "../lists/ConfirmDeleteModal";
import {useModal} from "../../stores";
import {useCallback, useMemo, useState} from "react";
import TextEditPopover from "../TextEditPopover";
import {
  useCurrentList,
  useDeleteList,
  useIsListOwner,
  useList,
  useUpdateListName
} from "../../api";
import {nonNull, renderOn, useListRouteParams, useNavigate} from "../../utils";
import {useCreateLink} from "../../utils/sharing";
import ListUsers from "../ListUsers";

const BinIcon = styled(Icon).attrs({icon: 'trash2'})``;

const KebabIcon = styled(Icon).attrs({icon: 'ellipsis-v'})`
  margin-right: 0 !important;
`;

const TitleEditLabel = styled.span`
  flex-shrink: 0;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-weight: bold;
`;

const PopoverWrapper = styled.div``;

type Props = {
  listId: number;
};

const EditListDropdown = ({listId}: Props) => {
  const {listId: currentListId} = useListRouteParams();
  const {data: list, loading: listLoading, called: listCalled} = useList(listId);
  const {data: currentList, loading: currentListLoading, called: currentListCalled} = useCurrentList();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const {open, id} = useModal();
  const {open: openUsersModals, id: usersModalId} = useModal();
  const {updateName} = useUpdateListName();
  const {deleteList} = useDeleteList();
  const {goToIndex} = useNavigate();
  const isListOwner = useIsListOwner(listId);
  const createLink = useCreateLink();

  const isReady = listCalled && !listLoading && ((!currentListLoading && currentListCalled) || typeof currentListId === 'undefined');

  const clickHandler = useCallback((fn: () => void) => () => {
    fn();
    setIsDropdownOpen(false);
  }, [setIsDropdownOpen]);

  const saveChanges = useCallback((newValue) => {
    if (typeof list?.id === 'number' && newValue !== '') {
      updateName(list.id, newValue);
    }
  }, [list?.id, updateName]);

  const handleDelete = useCallback(() => {
    if (typeof list?.id !== 'undefined') {
      const promise = deleteList(list.id);
      if (list.id === currentList?.id) {
        promise.then(goToIndex);
      }
    }
  }, [
    list,
    currentList,
    goToIndex,
    deleteList,
  ]);

  const handleCreateSharedLink = useCallback(() => {
    const listId = list?.id;
    if (nonNull(listId)) {
      createLink(listId);
    }
  }, [list, createLink]);

  const onDelete = useMemo(() => clickHandler(() => {
    if (list?.movieCount === 0 && list?.tvShowCount === 0) {
      handleDelete();
    } else {
      open();
    }
  }), [
    clickHandler,
    list,
    handleDelete,
    open,
  ]);

  const close = useCallback(() => {
    setIsPopoverOpen(false);
  }, [setIsPopoverOpen]);

  return (
    <>
      <ConfirmDeleteModal
        id={id}
        listId={listId}
        handleDelete={handleDelete}
      />

      {isPopoverOpen && typeof list?.name === 'string' && (
        <TextEditPopover
          initialValue={list.name}
          saveChanges={saveChanges}
          close={close}
          label={<TitleEditLabel>Edit Name:</TitleEditLabel>}
        />
      )}

      <Dropdown
        placement="bottomEnd"
        className="has-text-right"
        noCaret
        icon={<KebabIcon />}
        onClose={() => setIsDropdownOpen(false)}
        onOpen={() => setIsDropdownOpen(true)}
        open={isDropdownOpen}
      >
        <PopoverWrapper>
          {typeof list?.name === 'string' && (
            <Dropdown.Item
              icon={<Icon icon="pencil" />}
              onSelect={clickHandler(() => {
                setIsPopoverOpen(true);
              })}
            >
              Edit Name
            </Dropdown.Item>
          )}
        </PopoverWrapper>
        {isListOwner && (
          <Dropdown.Item
            icon={<Icon icon="link" />}
            onSelect={handleCreateSharedLink}
          >
            Create Shared Link
          </Dropdown.Item>
        )}
        {renderOn(
          list?.id,
          listId => <ListUsers listId={listId} id={usersModalId} />
        )}
        <Dropdown.Item
          onSelect={openUsersModals}
          icon={<Icon icon="people-group" />}
        >
          Who Has Access
        </Dropdown.Item>
        {isReady && isListOwner && (
          <Dropdown.Item
            icon={<BinIcon />}
            onSelect={onDelete}
          >
            Delete List
          </Dropdown.Item>
        )}
      </Dropdown>
    </>
  );
};

export default EditListDropdown;
