import {gql} from "@apollo/client";
import {USER_SETTING_FIELDS, USER_SETTINGS_BASE_FIELDS} from "./fragments";
import {WATCH_PROVIDER_FIELDS} from "../WatchProvider/fragments";

export const GET_USER_SETTINGS = gql`
  query getUserSettings {
    settingsBase {
        ...UserSettingsBaseFields
        settingProperties {
            ...UserSettingsFields
        }
        watchProviders {
            ...WatchProviderFields
        }
    }
  }
  ${USER_SETTING_FIELDS}
  ${USER_SETTINGS_BASE_FIELDS}
  ${WATCH_PROVIDER_FIELDS}
`;
