import {Container} from "rsuite";

const PageNotFound = () => {
  return (
    <Container>
      <h1>404 Page Not Found</h1>
      <p>The thing you were looking for may have been deleted</p>
    </Container>
  );
};

export default PageNotFound;
