import React, {useContext, useMemo} from 'react';
import {getColor, getContrastYIQ} from "../utils";
import {ThemeContext} from "styled-components";

export type GenreType = {
  name: string;
  id: number;
};

type Args = {
  genre: GenreType;
};

const Genre = ({genre: {name}}: Args) => {
  const theme = useContext(ThemeContext);
  const colour = useMemo(() => getColor(theme, name), [name, theme]);
  const foregroundColour = useMemo(() => getContrastYIQ(colour), [colour]);

  return (
    <span
      className="tag mr-2"
      style={{
        backgroundColor: colour,
        color: foregroundColour,
      }}
    >
      {name}
    </span>
  );
};

export default Genre;
