import {gql} from "@apollo/client";
import {WATCH_PROVIDER_FIELDS} from "../WatchProvider/fragments";
import {TV_FIELDS} from "./fragments";
import {TV_VIEWING_FIELDS} from "../TvViewing/fragments";

export const GET_TV_FOR_LIST = gql`
  query getTvForList(
    $listId: Int!,
    $orderBy: TvOrderByInput!,
    $locale: String,
    $viewStatus: ListMediaType,
    $providerLocale: WatchProviderLocale,
    $providerIds: [Int!],
    $page: Int,
    $pageSize: Int,
    $query: String!
  ) {
    listTv(
      listId: $listId,
      orderBy: $orderBy,
      viewStatus: $viewStatus,
      providerIds: $providerIds,
      locale: $providerLocale,
      page: $page,
      pageSize: $pageSize,
      query: $query
    ) {
      page
      pages
      totalResults
      data {
        ...TvFields
        lists(where: {list: {id: { equals: $listId } } }) {
          createdAt
          list {
            id
          }
        }
        tvViewings {
          ...TvViewingFields
        }
        watchProviderMappings(where: { locale: { equals: $locale }, AND: { type: { equals: FLATRATE } } }) {
          watchProvider {
            ...WatchProviderFields
          }
        }
      }
    }
  }
  ${TV_FIELDS}
  ${TV_VIEWING_FIELDS}
  ${WATCH_PROVIDER_FIELDS}
`;

export const GET_TV = gql`
  query getTv($tvId: Int!) {
    tv(where: {id: $tvId}) {
      ...TvFields
      seasons
      networks
      lists {
        id
        createdAt
        list {
          id
          name
          createdAt
          updatedAt
        }
      }
      tvViewings {
        ...TvViewingFields
      }
    }
  }
  ${TV_FIELDS}
  ${TV_VIEWING_FIELDS}
`;

export const GET_TV_MOVIE_DB_IDS = gql`
  query getTvMovieDbIds($listId: Int!) {
    tvs(where: { lists: { some: { list: { id: { equals: $listId } } } } }) {
      movieDbId
    }
  }
`;
