import {Icon, IconButton, Input} from "rsuite";
import {useState} from "react";
import {useCreateList} from "../../api";
import {AppPaths, useGoTo} from "../../utils";

const CreateListButton = ({className}: { className?: string }) => {
  const createList = useCreateList();
  const [inputName, setInputName] = useState('');
  const goTo = useGoTo();

  const onClick = () => {
    (async () => {
      if (inputName !== '') {
        const result = await createList.createList({
          name: inputName,
        });
        if (result.data?.createList) {
          goTo(AppPaths.LIST.buildPath(result.data.createList.id));
        }
      }
    })();
    setInputName('');
  };

  return (
    <div className={className}>
      <Input
        value={inputName}
        onChange={setInputName}
        onPressEnter={onClick}
      />
      <IconButton
        disabled={inputName.length === 0}
        icon={<Icon icon="plus" />}
        className="mt-4"
        onClick={onClick}
      >
        Add New List
      </IconButton>
    </div>
  );
};

export default CreateListButton;
