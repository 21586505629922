import {Button, Modal} from "rsuite";
import {useList} from "../../api";
import {useCallback} from "react";
import QueryLoader from "../QueryLoader";
import {createModal} from "../Modal";

type Args = {
  listId: number;
  id: string;
  handleDelete: () => void;
}

const ConfirmDeleteModal = createModal<Args>(({listId, closeModal, ModalComponent, handleDelete}) => {
  const listResult = useList(listId);

  const deletion = useCallback(() => {
    handleDelete();
    closeModal();
  }, [
    handleDelete,
    closeModal,
  ]);

  if (listResult.loading || !listResult.called) return null;

  const warnings = [];
  if (listResult.data?.movieCount) {
    warnings.push(`${listResult.data?.movieCount} movies`);
  }
  if (listResult.data?.tvShowCount) {
    if (warnings.length > 0) {
      warnings.push('and');
    }
    warnings.push(`${listResult.data?.tvShowCount} TV Shows`);
  }

  return (
    <QueryLoader queryResult={listResult} renderLoader={() => null}>
      <ModalComponent>
        <Modal.Header>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your list has {warnings.join(' ')} added to it.</p>
          <p>You are about to delete "{listResult.data?.name}", are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance="primary"
            onClick={deletion}
          >
            Confirm
          </Button>
          <Button
            appearance="subtle"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </ModalComponent>
    </QueryLoader>
  );
});

export default ConfirmDeleteModal;
