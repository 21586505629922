import React, {CSSProperties} from 'react';
import styled from "styled-components";
import {formatDate, renderOn} from "../../utils";
import TruncateText from "../TruncateText";
import {Optional} from "../types";
import {breakpoint} from "../../theme";
import MediaTimes from "./MediaTimes";
import MediaImage from "../MediaImage";

const Newline = styled.span.attrs({className: 'mt-2'})`
  display: block;
`;

const MediaContainer = styled.div`
  margin-top: 0 !important;
  padding: 1rem;
  position: relative;

  ${breakpoint('mobile')} {
    padding-right: 0;
    padding-left: 0;
  }
`;

const OverviewContainer = styled(Newline)`
  ${breakpoint('mobile')} {
    display: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding-right: 3rem;
`;

const Image = styled.div`
  max-width: 100%;
`;

type Viewing = {
  createdAt: Optional<string>;
};

type Media = {
  posterPath: Optional<string>;
  title: Optional<string>;
  releaseDate: Optional<string>;

  // Just optional
  addedToListAt?: Optional<string>;
  viewings?: Optional<Optional<Viewing>[]>;
  overview?: Optional<string>;

  // Specific to each media type
  runtime?: Optional<number>;
  numberOfSeasons?: Optional<number>;
};

type Args = {
  media: Media;
  topRight?: React.ReactNode;
  className?: string;
  renderTitle?: (title: Optional<string>) => JSX.Element | Optional<string>;
  content?: React.ReactNode;
  titleClassname?: string;
  titleStyles?: CSSProperties;
};

const MediaComponent = ({
  media,
  topRight,
  className = '',
  content = null,
  renderTitle = (title) => title,
  titleClassname = '',
  titleStyles,
}: Args) => {
  return (
    <MediaContainer className={`media has-text-weight-medium ${className}`}>
      {media.posterPath && media.title && (
        <Image className="media-left">
          <MediaImage src={media.posterPath} alt={media.title} />
        </Image>
      )}
      <div className="media-content">
        <div className="content">
          <TitleContainer
            style={titleStyles}
            className={`media-title-container is-flex is-justify-content-space-between is-align-items-start ${titleClassname}`}
          >
            <div>
              <div>
                <h3 className="is-size-4 good-word-break">{renderTitle(media.title)}</h3>
                <MediaTimes
                  releaseDate={media.releaseDate}
                  runtime={media.runtime}
                  seasons={media.numberOfSeasons}
                />
              </div>
            </div>
            {topRight}
          </TitleContainer>
          {
            media.viewings && media.viewings[0]?.createdAt
              ? <Newline>Viewed {formatDate(media.viewings[0].createdAt)}</Newline>
              : media.addedToListAt && <Newline>Added {formatDate(media.addedToListAt)}</Newline>
          }
          <OverviewContainer>
            {renderOn(
              media.overview,
              overview => <TruncateText maxLength={200}>{overview}</TruncateText>
            )}
          </OverviewContainer>
          {content}
        </div>
      </div>
    </MediaContainer>
  );
}

export default MediaComponent;
