import React from 'react';
import {usePeopleRouteParams} from "../../utils";
import PersonPageComponent from "../../components/PersonPage";

const PersonPage = () => {
  const {personId} = usePeopleRouteParams();

  if (typeof personId === 'undefined') return null;

  return <PersonPageComponent personId={personId} />;
};

export default PersonPage;
