import {useListWatchProviders} from "../../api";
import ProviderDropdown from "../ProviderDropdown";
import {listWatchProviders_listWatchProviders} from "../../graphql-types";
import {NonNull} from "../../utils/types";
import {UserSettings} from "../../utils";
import ProviderLogos from "../ProviderLogos";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

type Provider = NonNull<listWatchProviders_listWatchProviders>;
type Providers = NonNull<listWatchProviders_listWatchProviders[]>;

type Props = {
  providers: UserSettings['watchProviders'];
  toggleProvider: (provider: UserSettings['watchProviders'][number]) => void;
};

const DefaultProviders = ({ providers, toggleProvider }: Props) => {
  const {data: allWatchProviders, loading} = useListWatchProviders();

  const isProviderOn = (provider: Provider) => {
    return providers.some(watchProvider => watchProvider.id === provider.id);
  };

  const nonNullProviders = (allWatchProviders || []) as Providers;
  const selectedProviders = nonNullProviders.filter(isProviderOn);

  const toggleProviderHandler = (provider: Provider) => () => {
    toggleProvider(provider);
  };

  return (
    <Container>
      <ProviderDropdown
        disabled={typeof allWatchProviders === 'undefined'}
        isProviderSelected={isProviderOn}
        loading={loading}
        providers={nonNullProviders}
        toggleProvider={toggleProviderHandler}
      />
      <ProviderLogos
        providers={selectedProviders}
      />
    </Container>
  );
};

export default DefaultProviders;
