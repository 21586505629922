import {useAuth0} from "@auth0/auth0-react";
import {useCallback, useEffect, useRef} from "react";

export const useLogout = () => {
  const {logout} = useAuth0();

  return useCallback(() => {
    logout({returnTo: window.location.origin});
  }, [logout]);
};

export const useFocusOnRender = () => {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return ref;
};
