import {Helmet} from 'react-helmet';
import {ThemeContext} from "styled-components";
import {useContext, useMemo} from "react";
import {ThemeShape} from "./theme";
import {isDark} from "./theme/utils";

const Head = () => {
  const theme = useContext<ThemeShape>(ThemeContext);
  const dark = isDark(theme);

  const baseColours = `
    --dropdown-background: var(${dark ? '--colour-70' : '--colour-1'});
  `;

  const variableString = useMemo(
    () => `:root { ${baseColours}\n` + Object.entries(theme.colours.themeColours).reduce((acc, [key, colour]) => {
      return `    ${acc} ${key}: ${colour};\n`;
    }, '') + '}',
    [theme, baseColours]
  );
  return (
    <Helmet>
      <style>
        {variableString}
      </style>
    </Helmet>
  );
};

export default Head;
