import {gql} from "@apollo/client";

export const WATCH_COMPLETION_STAT = gql`
  query getWatchCompletionStat {
    stats {
      watchCompletion {
        completion
        tvCount
        seenTvCount
        movieCount
        seenMovieCount
      }
    }
  }
`;

export const TIME_SPENT_WATCHING = gql`
  query getTimeSpentWatchingStat {
    stats {
      timeSpentWatching
    }
  }
`;
