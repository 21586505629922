import {useLazyQuery, useQuery} from "@apollo/client";
import {
  GET_MOVIE,
  GET_MOVIE_MOVIE_DB_IDS,
  GET_MOVIES_BY_PROVIDERS,
  GET_MOVIES_FOR_LIST,
  GET_RECOMMENDED_MOVIES
} from "./queries";
import {
  getMovie, getMovieMovieDbIds, getMovieMovieDbIdsVariables,
  getMoviesByProviders,
  getMoviesByProvidersVariables,
  getMoviesForList,
  getMoviesForList_listMovies_data,
  getMoviesForListVariables,
  getMovieVariables as getMovieVariablesType,
  getRecommendedMovies,
  getRecommendedMoviesVariables,
  ListMediaType,
  MovieOrderByInput,
  WatchProviderLocale,
} from "../../graphql-types";
import {useExtractQueryValue} from "../utils";
import {useCurrentList} from "../List";
import {useCallback, useEffect, useMemo} from "react";
import {MovieListSort, OrderBy, OrderByArray} from "../../stores";
import {nonNull, useListRouteParams, useMovieRouteParams} from "../../utils";
import {useBuiltUserSettings} from "../UserSettings/userUserSettingsQueries";
import {Optional} from "../../components/types";

type ResultType = {
  listId: number;
  orderBy: OrderBy<MovieOrderByInput>;
};

export const getMoviesForListParameters = (listId: number, movieSort: MovieListSort, locale: WatchProviderLocale): ResultType => {
  return {
    listId: listId,
    orderBy: movieSort[0],
    ...(!locale ? {} : {providerLocale: locale}),
    ...(!locale ? {} : {locale}),
  }
};

export const useCurrentListMovies = <T>({
  viewStatus,
  providerIds,
  page,
  pageSize,
  query,
  order,
}: {
  viewStatus?: ListMediaType;
  providerIds?: number[];
  page?: number;
  pageSize?: number;
  order: OrderByArray<T>;
  query: string;
} = {
  query: '',
  order: [],
}) => {
  const {data: list} = useCurrentList();
  const [doFetch, result] = useLazyQuery<getMoviesForList, getMoviesForListVariables>(GET_MOVIES_FOR_LIST, {
    fetchPolicy: 'network-only',
  });
  const extractedValue = useExtractQueryValue(result, 'listMovies');
  const {data: userSettings} = useBuiltUserSettings();

  useEffect(() => {
    if (list?.id) {
      doFetch({
        variables: {
          ...getMoviesForListParameters(list.id, order, userSettings.locale),
          viewStatus,
          providerIds,
          page,
          pageSize,
          query,
        },
      });
    }
  }, [
    list?.id,
    doFetch,
    order,
    viewStatus,
    providerIds,
    pageSize,
    page,
    userSettings.locale,
    query,
  ]);

  return useMemo(() => {
    const {data, ...rest} = extractedValue;
    const getDateAdded = (movie: getMoviesForList_listMovies_data) => {
      const matchingList = movie.lists.find(listOnMovie => typeof listOnMovie.list?.id !== 'undefined' && listOnMovie.list?.id === list?.id);
      if (matchingList) {
        return matchingList.createdAt;
      }
      return null;
    };

    return {
      data: {
        ...data,
        data: data && data.data.map(movie => ({
          ...movie,
          addedToListAt: getDateAdded(movie),
        })),
      },
      ...rest,
    };
  }, [
    extractedValue,
    list?.id,
  ]);
};

const useCurrentListMovieMovieDbIds = () => {
  const {listId} = useListRouteParams();
  const [fetch, {data}] = useLazyQuery<getMovieMovieDbIds, getMovieMovieDbIdsVariables>(GET_MOVIE_MOVIE_DB_IDS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (typeof listId === 'number') {
      fetch({
        variables: {
          listId,
        }
      })
    }
  }, [listId]);

  return data?.movies.map(movie => movie.movieDbId) || [];
};

export const useHasListGotMovieDbMovie = () => {
  const ids = useCurrentListMovieMovieDbIds();

  return useCallback((movieDbId: number) => {
    return ids.some(id => movieDbId === id);
  }, [ids]);
};

export const getMovieVariables = (movieId: number) => ({
  movieId,
});

export const useCurrentMovie = () => {
  const {movieId} = useMovieRouteParams();
  const [doFetch, result] = useLazyQuery<getMovie, getMovieVariablesType>(GET_MOVIE, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (nonNull(movieId)) {
      doFetch({
        variables: getMovieVariables(movieId),
      });
    }
  }, [doFetch, movieId]);

  return useExtractQueryValue(result, 'movie');
};

export const useMovie = (movieId: Optional<number>) => {
  const [doFetch, result] = useLazyQuery<getMovie, getMovieVariablesType>(GET_MOVIE, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (nonNull(movieId)) {
      doFetch({
        variables: getMovieVariables(movieId),
      });
    }
  }, [doFetch, movieId]);

  return useExtractQueryValue(result, 'movie');
};

export const useGetMoviesByProvider = () => {
  const [doFetch, result] = useLazyQuery<getMoviesByProviders, getMoviesByProvidersVariables>(GET_MOVIES_BY_PROVIDERS, {
    fetchPolicy: 'network-only',
  });
  const {data: userSettings} = useBuiltUserSettings();

  const fetch = useCallback((providerIds: number[], listId?: number) => {
    doFetch({
      variables: {
        providerIds,
        stringLocale: userSettings.locale,
        locale: userSettings.locale,
        listId,
      }
    });
  }, [doFetch, userSettings.locale]);

  return {
    search: fetch,
    ...useExtractQueryValue(result, 'moviesByProvider'),
  };
};

export const useGetRecommendedMovies = (n: number) => {
  const {data: settings} = useBuiltUserSettings();
  return useExtractQueryValue(
    useQuery<getRecommendedMovies, getRecommendedMoviesVariables>(GET_RECOMMENDED_MOVIES, {
      fetchPolicy: 'network-only',
      variables: {
        locale: settings.locale,
        numberToRecommend: n,
        providerIds: settings.watchProviders.map(provider => provider.id) as number[],
      }
    }),
    'recommendMovies',
  )
};
