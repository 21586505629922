import styled from "styled-components";

type Args = {
  title: string;
  elementRight?: JSX.Element;
  className?: string;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  margin-right: 1rem;
`;

const TitleContainer = ({title, elementRight, className}: Args) => {
  return (
    <Container className={className}>
      <Title className="is-size-1">{title}</Title>
      <div className="is-flex is-flex-direction-column is-justify-content-center">
        {elementRight}
      </div>
    </Container>
  );
};

export default TitleContainer;
