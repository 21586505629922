import styled, {css} from "styled-components";
import Media from '../Media';
import {Button, Icon, IconButton, Tag} from "rsuite";
import {mediaSearch_mediaSearch_data, SearchResultType} from "../../graphql-types";
import {breakpoint} from "../../theme";
import PersonResult from "./PersonResult";
import {useNavigate} from "../../utils";

type SearchResult = mediaSearch_mediaSearch_data & { id: number };

type SearchResultArgs = {
  results: SearchResult[];
  createMedia: (media: SearchResult) => Promise<any>;
  getIsInList: (media: SearchResult) => boolean;
  loading: boolean;
};

const border = css`border-top: 1px solid rgba(219, 219, 219, .5);`;

const padding = css`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const InListContainer = styled.div`
  ${border}
  ${padding}
`;

const TopRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${breakpoint('mobile')} {
    width: 100%;
  }
`;

const TopRightTagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > * {
    margin-bottom: 0.5rem;
  }
`;

const TitleLink = styled(Button)`
  padding: 0;
  font-size: 1.5rem;
  text-align: left;
`;

const AddToListButton = styled(IconButton)`
  margin-top: 1rem;
  height: auto !important;
`;

const MediaElement = ({
  searchResult,
  isInList,
  createMedia,
  loading,
}: {
  searchResult: SearchResult;
  isInList: boolean;
  createMedia?: () => void;
  loading: boolean;
}) => {
  const {createMovieAndNavigate, createTvAndNavigate} = useNavigate();

  if (searchResult.type === SearchResultType.PERSON) {
    return (
      <PersonResult person={searchResult} />
    );
  }

  let topRightMessages = [];
  if (isInList) {
    topRightMessages.push('Already in list');
  }
  if (searchResult.hasSeen) {
    topRightMessages.push('You have seen this');
  }

  const onClickTitle = searchResult.type === SearchResultType.MOVIE
    ? () => createMovieAndNavigate(searchResult.id)
    : () => createTvAndNavigate(searchResult.id);

  return (
    <Media
      media={searchResult}
      titleClassname="pr-0"
      renderTitle={title => (
        <TitleLink
          appearance="link"
          className="good-word-break"
          onClick={onClickTitle}
        >
          {title}
        </TitleLink>
      )}
      content={
        !isInList && (
          <AddToListButton
            loading={loading}
            onClick={createMedia}
            className="good-word-break"
            icon={<Icon icon="plus" />}
          >
            Add {searchResult.type === SearchResultType.MOVIE ? 'movie' : 'TV'}
          </AddToListButton>
        )
      }
      topRight={
        topRightMessages.length === 0 ? null : (
          <TopRightContainer>
            <TopRightTagContainer>
              {topRightMessages.map(message => <Tag key={message}>{message}</Tag>)}
            </TopRightTagContainer>
          </TopRightContainer>
        )
      }
    />
  );
}

const SearchResults = ({results, createMedia, getIsInList, loading}: SearchResultArgs) => {
  return (
    <div className="mt-4">
      {
        results.map(searchResult => (
          <InListContainer key={searchResult.id}>
            <MediaElement
              loading={loading}
              createMedia={() => createMedia(searchResult)}
              searchResult={searchResult}
              isInList={getIsInList(searchResult)}
            />
          </InListContainer>
        ))
      }
    </div>
  );
}

export default SearchResults;
