import React, {MutableRefObject, useCallback, useEffect, useRef} from 'react';
import {
  getAllWatchProviders_allWatchProviders, getMoviesByProviders_moviesByProvider_watchProviderMappings,
} from "../graphql-types";
import {NonNull} from "./types";

export const debounce = <A>(fn: (args: A) => void, time: number) => {
  let timerId: NodeJS.Timeout | null = null;
  return (args: A) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(args);
    }, time);
  };
};

export const defined = <V, F = any>(op: (...values: Array<V>) => F, ...values: Array<V | undefined>) => {
  if (values.every(v => typeof v !== 'undefined')) {
    op(...values as V[]);
  }
};

export const nonNull = <T>(value: T | undefined | null): value is T => {
  return value !== null && typeof value !== 'undefined';
};

export const renderOn = <T>(
  on: T | null | undefined,
  renderFunc: (data: T) => JSX.Element,
  extraCondition: (data: T) => boolean = () => true,
  renderDefault: () => React.ReactNode = () => null,
) => {
  if (nonNull(on) && extraCondition(on)) {
    return renderFunc(on as T);
  }
  return renderDefault();
};

export const negate = (fn: (...args: any[]) => boolean) => (...args: any[]) => !fn(...args);

export const getImdbUrl = (imdbId: string, type = 'title') => `https://www.imdb.com/${type}/${imdbId}/`;

export const useAutoFocus = (trigger?: boolean) => {
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (typeof trigger === 'undefined' || trigger) {
      setTimeout(() => {
        ref.current?.focus();
      });
    }
  }, [trigger]);

  return ref;
};

export const range = (n: number) => Array.from(Array(n).keys());

type Provider = NonNull<getAllWatchProviders_allWatchProviders>;

export const sortProviders = (providers: Provider[]) => {
  return [...providers].sort(
    (providerA, providerB) => providerA.displayPriority - providerB.displayPriority
  );
};

type HasMappings = {
  watchProviderMappings?: getMoviesByProviders_moviesByProvider_watchProviderMappings[] | null;
};

type ProviderMapping = {
  watchProvider: {
    id: number | null;
    logoPath: string | null;
    displayPriority: number | null;
    providerName: string | null;
    updatedAt: any | null;
  }
};

export const getProvidersFromMedia = ({watchProviderMappings}: HasMappings): Provider[] => {
  const mappings: ProviderMapping[] = (watchProviderMappings || []) as ProviderMapping[];

  return mappings
    .map(providerMapping => providerMapping.watchProvider)
    .filter(provider => (
      Boolean(provider?.logoPath)
      && Boolean(provider?.providerName)
      && nonNull(provider?.displayPriority)
      && nonNull(provider?.id)
    )) as Provider[];
};

export const round = (number: number, dp: number) => {
  return Math.round(number * (10 ** dp)) / (10 ** dp);
};

export const scrollToRef = (ref: MutableRefObject<HTMLElement | null>) => {
  if (ref.current) {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  }
};

export const toTitleCase = (str: string) => {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/
  const wordSeparators = /([ :–—-])/

  return str.split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' ||
          (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase()
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase()
      })
    })
    .join('');
}

export const roundVote = (vote: number) => {
  return vote * 10;
};

export const useScrollOnMount = () => {
  useEffect(() => {
    const timerId = setTimeout(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      clearTimeout(timerId);
    };
  }, []);
};

export const useScroll = () => {
  return useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
};
