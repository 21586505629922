import {useMutation} from "@apollo/client";
import {
  CREATE_LIST,
  CREATE_SHARED_LINK,
  DELETE_LIST,
  REDEEM_SHARED_LINK,
  REMOVE_LIST_ACCESS,
  UPDATE_LIST_NAME
} from "./mutations";
import {
  createList,
  createListVariables, createSharedLink, createSharedLinkVariables,
  deleteList,
  deleteListVariables, redeemSharedLink, redeemSharedLinkVariables, removeListAccess, removeListAccessVariables,
  updateListName,
  updateListNameVariables
} from "../../graphql-types";
import {useCallback} from "react";
import {GET_LISTS} from "./queries";
import {getListsParameters, useCurrentList} from "./useListQueries";
import {listSortVar} from "../../stores";
import {useMovieRouteParams, useNavigate} from "../../utils";
import {GET_MOVIE} from "../Movie/queries";
import {getMovieVariables} from "../Movie";
import {TIME_SPENT_WATCHING, WATCH_COMPLETION_STAT} from "../Stats/queries";
import {useGetMe} from "../User/useUserQueries";

export const useCreateList = () => {
  const [createListMutation, {data}] = useMutation<createList, createListVariables>(CREATE_LIST);

  const createList = (variables: createListVariables) => createListMutation({
    variables,
    refetchQueries: [
      {query: GET_LISTS, variables: getListsParameters(listSortVar())},
    ],
    awaitRefetchQueries: true,
  });

  return {
    createList,
    data,
  };
};

export const useDeleteList = () => {
  const {movieId} = useMovieRouteParams();
  const [deleteList, {data}] = useMutation<deleteList, deleteListVariables>(DELETE_LIST);

  const doDelete = useCallback((id: number) => {
    return deleteList({
      variables: {
        id,
      },
      refetchQueries: [
        {query: GET_LISTS, variables: getListsParameters(listSortVar())},
        ...(typeof movieId === 'undefined' ? [] : [{query: GET_MOVIE, variables: getMovieVariables(movieId)}]),
        {query: WATCH_COMPLETION_STAT},
        {query: TIME_SPENT_WATCHING},
        'getRecommendedMovies',
      ],
      awaitRefetchQueries: true,
    });
  }, [movieId, deleteList]);

  return {
    deleteList: doDelete,
    data,
  }
};

export const useUpdateListName = () => {
  const [doUpdate, result] = useMutation<updateListName, updateListNameVariables>(UPDATE_LIST_NAME);

  const update = useCallback((id: number, name: string) => {
    return doUpdate({
      variables: {
        listId: id,
        name,
      }
    });
  }, [doUpdate]);

  return {
    updateName: update,
    ...result,
  };
};

export const useCreateSharedLink = () => {
  const [doCreate, result] = useMutation<createSharedLink, createSharedLinkVariables>(CREATE_SHARED_LINK);

  const create = useCallback((listId: number) => {
    return doCreate({
      variables: {
        listId,
      }
    });
  }, [doCreate]);

  return {
    createSharedAccess: create,
    ...result
  };
};

export const useRedeemInviteLink = () => {
  const [doRedeem, result] = useMutation<redeemSharedLink, redeemSharedLinkVariables>(REDEEM_SHARED_LINK);

  const redeem = useCallback((code: string) => {
    return doRedeem({
      variables: {
        code,
      }
    });
  }, [doRedeem]);

  return {
    redeemCode: redeem,
    ...result,
  };
};

export const useRemoveListAccess = () => {
  const {data: me} = useGetMe();
  const {data: currentList} = useCurrentList();
  const [doRemove, result] = useMutation<removeListAccess, removeListAccessVariables>(REMOVE_LIST_ACCESS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {query: GET_LISTS, variables: getListsParameters(listSortVar())},
      {query: WATCH_COMPLETION_STAT},
      {query: TIME_SPENT_WATCHING},
      'getRecentActivities',
    ]
  });
  const {goToIndex} = useNavigate();

  const remove = useCallback(async (listAccessId: number) => {
    const isRemovingOwnAccess = currentList?.listAccesses.some(
      access => (
        access.id === listAccessId
        && access.user.id === me?.id
      )
    );
    return doRemove({
      variables: {
        listAccessId,
      }
    })
      .then((result) => {
        if (isRemovingOwnAccess && result.data?.removeSharedListAccess) {
          goToIndex();
        }
        return Boolean(result.data?.removeSharedListAccess);
      });
  }, [
    me,
    doRemove,
    currentList,
    goToIndex,
  ]);

  return {
    removeListAccess: remove,
    ...result,
  };
};
