import React from 'react';
import {Optional} from "../types";
import styled from "styled-components";
import {Button} from "rsuite";
import MediaImage from "../MediaImage";
import {useNavigate} from "../../utils";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
`;

const Image = styled(MediaImage)`
  margin-right: 1rem;
`;

const NameLink = styled(Button)`
  padding: 0;
  font-size: 1.5rem;
`;

type Person = {
  id: number;
  profilePath: Optional<string>;
  name: Optional<string>;
};

type Props = {
  person: Person;
};

const PersonResult = ({person}: Props) => {
  const {goToPerson} = useNavigate();
  return (
    <Container>
      {person.profilePath && person.name && (
        <Image src={person.profilePath} alt={person.name} />
      )}
      <NameLink
        appearance="link"
        onClick={() => goToPerson(person.id)}
      >
        {person.name}
      </NameLink>
    </Container>
  );
};

export default PersonResult;
