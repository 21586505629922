import {Pagination as RPagination} from 'rsuite';
import {useMediaQuery} from "@react-hook/media-query";

type Args = {
  page: number;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
  totalResults: number;
  hasSearched: boolean;
  className?: string
}

export const Pagination = ({page, totalPages, totalResults, goToPage, hasSearched, className}: Args) => {
  const hasResults = totalResults > 0;
  const matches = useMediaQuery('only screen and (max-width: 1000px)');
  if (!hasResults || !hasSearched) return null;
  return (
    <div className="is-flex is-justify-content-center">
      <RPagination
        className={className || ''}
        activePage={page}
        pages={totalPages}
        maxButtons={matches ? 2 : 10}
        next
        prev
        ellipsis
        first
        last
        boundaryLinks
        onSelect={goToPage}
      />
    </div>
  );
};

