import React, {useEffect} from 'react';
import {Credits, DetailedMedia} from "./types";
import {WatchProviderInputType} from "../../graphql-types";
import {useCurrentMediaWatchProviders} from "../../api";
import {renderOn, useNavigateContext} from "../../utils";
import MediaBackdropOverview from "./MediaBackdropOverview";
import Genre, {GenreType} from "../Genre";
import MediaLists from "./MediaLists";
import MediaCredits from "./MediaCredits";
import MediaVote from "../MediaVote";


type Props = {
  media: DetailedMedia;
  type: WatchProviderInputType;
  credits: Credits;
  loading: boolean;
};

const MediaPage = ({type, media, loading, credits}: Props) => {
  const {data: watchProviderData, loading: watchProviderLoading} = useCurrentMediaWatchProviders(type, media?.id);
  const navigateContext = useNavigateContext();

  useEffect(() => {
    if (!loading && !media) {
      navigateContext.setIs404();
    }
  }, [navigateContext, loading, media]);

  if (!media) return null;

  return (
    <>
      <MediaBackdropOverview
        media={media}
        mediaType={type}
        watchProviders={watchProviderData}
        watchProvidersLoading={watchProviderLoading}
        titleChildren={
          renderOn(
            media?.voteAverage,
            average => (
              <div className="ml-4">
                <MediaVote voteAverage={average} />
              </div>
            ),
            average => average > 0
          )
        }
      >
        <div className="mt-4">
          {renderOn(
            media?.genres,
            genres => genres.map((genre: GenreType) => <Genre key={genre.id} genre={genre} />)
          )}
        </div>
      </MediaBackdropOverview>
      <MediaLists lists={media?.lists || []} />
      <MediaCredits credits={credits} />
    </>
  );
}

export default MediaPage;
