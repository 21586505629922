import React from 'react';
import styled, {css} from "styled-components";
import {useMediaQuery} from '@react-hook/media-query';
import {getWatchProviders, WatchProviderInputType} from "../../graphql-types";
import WatchProviders from "./WatchProviders";
import {useTruncateText} from "../TruncateText";
import {renderOn} from "../../utils";
import MediaTimes from "../Media/MediaTimes";
import {DetailedMedia} from "./types";
import ReleaseStatus from "./ReleaseStatus";
import ImdbButton from "../ImdbButton";
import AddToList from "../AddToList";
import MediaWatchedCheckbox from "../MediaWatchedCheckbox";

const Container = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: 1400px) {
    & {
      color: var(--colour-1) !important;
    }
  }
`;

const fontColour = css`
  @media (min-width: 1400px) {
    color: white !important;

    > * {
      color: white !important;
    }
  }
`;

const WhereToWatchContainerWrapper = styled.div`
  margin-top: 1.5rem;
  ${fontColour}
`;

const WrappedWatchProviders = styled(WatchProviders)`
  ${fontColour}
  & * {
    ${fontColour}
  }
`;

const padding = css`
  padding: 2rem 4rem;

  @media (max-width: 1400px) {
    padding: 2rem;
  }
`;

const TextBlock = styled.p`
  ${fontColour}
`;

const MediaWatchedStatus = styled(MediaWatchedCheckbox)`
  margin-top: 1rem;
  ${fontColour}
`;

const OverviewContainer = styled.div`
  position: absolute;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  ${padding};
  padding-top: 5rem;
  padding-right: 22rem;
  height: 100%;
  width: 70%;
  top: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0));

  @media (max-width: 1400px) {
    width: 100%;
    position: relative;
    background: transparent;
  }
`;

const overviewFontSize = css`
  font-size: 1.25rem;

  @media (max-width: 1400px) {
    font-size: 1rem;
  }
`;

const Overview = styled.p`
  ${overviewFontSize}
  ${fontColour}
`;

const OverflowOverview = styled.div`
  ${padding}
  ${overviewFontSize}
  ${fontColour}
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const Title = styled.h1`
  ${fontColour}
`;

const ChildContainer = styled.div`
  ${fontColour}
`;

const Backdrop = styled.img`
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const AddMediaToList = styled(AddToList)`
  margin-top: 1rem;
`;

type Props = {
  media: DetailedMedia;
  mediaType: WatchProviderInputType;
  children?: React.ReactNode;
  titleChildren?: React.ReactNode;
  watchProviders?: getWatchProviders['watchProviders'];
  watchProvidersLoading: boolean;
};

const MediaBackdropOverview = ({
  media,
  mediaType,
  children,
  titleChildren,
  watchProviders,
  watchProvidersLoading,
}: Props) => {
  const {
    backdropPath,
    overview,
    title,
    runtime,
    releaseDate,
    numberOfSeasons,
    imdbId,
  } = media;
  const matches = useMediaQuery('only screen and (max-width: 1400px)');
  const {isTruncated, truncated} = useTruncateText(overview || '', 300);
  if (!backdropPath || !title || !overview) return null;
  return (
    <Container>
      <Backdrop src={backdropPath} alt={title} />
      <OverviewContainer>
        <TitleContainer>
          <Title className="good-word-break">{title}</Title>
          {titleChildren}
        </TitleContainer>
        <TextBlock className="is-size-5">
          <MediaTimes
            seasons={numberOfSeasons}
            runtime={runtime}
            releaseDate={releaseDate}
          />
        </TextBlock>
        <TextBlock>
          <ReleaseStatus media={media} />
        </TextBlock>
        {((!isTruncated && !matches) || matches) && (
          <Overview>
            {matches ? overview : truncated}
          </Overview>
        )}
        <ChildContainer>{children}</ChildContainer>
        {(watchProvidersLoading || (typeof watchProviders?.length !== 'undefined' && watchProviders.length > 0)) && (
          <WhereToWatchContainerWrapper>
            <WrappedWatchProviders
              watchProviders={watchProviders}
              loading={watchProvidersLoading}
            />
          </WhereToWatchContainerWrapper>
        )}
        <AddMediaToList
          mediaId={media.id}
          mediaType={mediaType}
          mediaMovieDbId={media.movieDbId}
        />
        <MediaWatchedStatus
          mediaType={mediaType}
          mediaId={media.id}
        />
        {renderOn(imdbId, id => (
          <div>
            <ImdbButton
              imdbId={id}
              title={title}
            />
          </div>
        ))}
      </OverviewContainer>
      {isTruncated && !matches && (
        <OverflowOverview>{overview}</OverflowOverview>
      )}
    </Container>
  );
};

export default MediaBackdropOverview;
