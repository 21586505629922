import React from 'react';
import {DetailedMedia} from "./types";
import {formatFullDate, isInFuture, renderOn} from "../../utils";
import styled from "styled-components";

const Line = styled.span`
  display: block;
`;

type Props = {
  media: DetailedMedia;
};

const ReleaseStatus = ({media}: Props) => {
  if (!isInFuture(media.releaseDate)) return null;
  if (media.status === 'Released') return null;

  return (
    <Line>
      {media.status}{renderOn(
      media.releaseDate,
      date => <>: Release date {formatFullDate(date)}</>,
      () => media.status !== 'Canceled',
    )}
    </Line>
  );
};

export default ReleaseStatus;
