import React, {useCallback} from 'react';
import {useModal} from "../../stores";
import {Modal, ModalProps} from "rsuite";

export type ExtendedArgs<T> = T & {
  closeModal: () => void;
  ModalComponent: React.FunctionComponent<ModalProps>;
};

type Required = { id: string };

export const createModal = <T extends Required>(Component: React.FunctionComponent<ExtendedArgs<T>>): React.FunctionComponent<T> => {
  return (args: T) => {
    const {isOpen, close} = useModal(args.id);
    const ModalComponent = useCallback(({children, ...rest}) => {
      return React.createElement(Modal, {
        show: true,
        onExit: close,
        onHide: close,
        children,
        ...rest,
      });
    }, [close]);
    if (!isOpen) {
      return null;
    }
    return React.createElement(Component, {
      ...args,
      closeModal: close,
      ModalComponent,
    });
  }
};
