import {gql} from "@apollo/client";
import {TV_VIEWING_FIELDS} from "./fragments";

export const CREATE_TV_VIEWING = gql`
  mutation createTvViewing($tvId: Int!) {
    createTvViewing(tvId: $tvId) {
      ...TvViewingFields
      tv {
        id
        tvViewings {
          id
        }
      }
    }
  }
  ${TV_VIEWING_FIELDS}
`;

export const REMOVE_TV_VIEWINGS = gql`
  mutation removeTvViewings($tvId: Int!) {
    removeTvViewings(tvId: $tvId) {
      id
      tvViewings {
        ...TvViewingFields
      }
    }
  }
  ${TV_VIEWING_FIELDS}
`;
