import {useCurrentMovie, useCurrentMovieCredits} from "../../api";
import {getMovie_movie, WatchProviderInputType} from "../../graphql-types";
import MediaPage from "../../components/MediaPage";
import {NonNull} from "../../utils/types";

const MoviePage = () => {
  const {data: movie, loading, called} = useCurrentMovie();
  const {data: movieCredits} = useCurrentMovieCredits();

  const nonNull = movie as NonNull<getMovie_movie>;

  return (
    <MediaPage
      type={WatchProviderInputType.MOVIE}
      loading={loading || !called}
      credits={movieCredits}
      media={nonNull}
    />
  );
}

export default MoviePage;
