import React from 'react';
import defaultTheme, {ThemeShape} from './theme';

export type ThemeStore = {
  theme: ThemeShape;
  setTheme: (theme: ThemeShape) => void;
};

const ThemeStoreContext = React.createContext<ThemeStore>({
  theme: defaultTheme,
  setTheme: () => {},
});

export default ThemeStoreContext;
