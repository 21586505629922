import React, {useEffect, useMemo, useState} from 'react';
import {Icon, Input, InputGroup} from "rsuite";
import styled from "styled-components";
import {debounce} from "../../utils";

type Props = {
  query: string;
  setQuery: (query: string) => void;
  className?: string;
  placeholder?: string;
};

const StyledInput = styled(Input)`
  &::placeholder {
    opacity: 0.5;
  }
`;

const MediaSearch = ({ query, setQuery, className, placeholder }: Props) => {
  const [tempQuery, setTempQuery] = useState(query);

  useEffect(() => {
    if (query !== tempQuery) {
      setTempQuery(query);
    }
  }, [query]);

  const onChange = useMemo(() => debounce(setQuery, 500), [setQuery]);

  useEffect(() => {
    onChange(tempQuery);
  }, [
    tempQuery,
    onChange,
  ]);

  return (
    <InputGroup inside>
      <StyledInput
        placeholder={placeholder}
        className={className}
        value={tempQuery}
        onChange={setTempQuery}
      />
      <InputGroup.Button onClick={() => setQuery('')}>
        <Icon icon="close" />
      </InputGroup.Button>
    </InputGroup>
  );
};

export default MediaSearch;
