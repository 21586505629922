import {useEffect, useMemo, useRef, useState} from "react";
import {Input} from "rsuite";
import {debounce, isNumber, useFocusOnRender, useListRouteParams} from "../../utils";
import SearchResults from "./SearchResults";
import {hasId, notNull} from "./filterNullResults";
import {useHasListGotMovieDbMovie, useMediaSearch,} from "../../api";
import {Pagination} from "../pagination";
import styled from "styled-components";
import {mediaSearch_mediaSearch_data, SearchResultType} from "../../graphql-types";
import {useHasListGotTvMovieDbMovie} from "../../api/Tv/useTvQueries";

const ResultsWrapper = styled.div`
  min-height: 50vh;
`;

type Props = {
  createMedia: (media: mediaSearch_mediaSearch_data, listId: undefined | number) => Promise<any>;
  createMediaLoading: boolean;
};

const SearchInput = ({createMedia, createMediaLoading}: Props) => {
  const {query: doQuery, loading, data, variables: activeVariables} = useMediaSearch();
  const {listId} = useListRouteParams();
  const [searchVariables, setSearchVariables] = useState({
    page: 1,
    query: '',
  });
  const lastVariables = useRef(searchVariables);
  const hasListGotTv = useHasListGotTvMovieDbMovie();
  const hasListGotMovie = useHasListGotMovieDbMovie();
  const ref = useFocusOnRender();

  const create = (media: mediaSearch_mediaSearch_data) => createMedia(media, listId);

  const hasSearched = searchVariables.query.length > 0;

  const onChange = useMemo(() => {
    return debounce((args: { page: number, query: string }) => {
      doQuery(args.page, args.query);
    }, 1000);
  }, [doQuery]);

  useEffect(() => {
    const {query, page} = searchVariables;
    if (
      !hasSearched
      // If the search hasn't changed, i.e. something like the page number has
      || lastVariables.current.query === query
    ) {
      doQuery(page, query);
    } else {
      onChange({page, query});
    }
  }, [hasSearched, doQuery, onChange, searchVariables]);

  useEffect(() => {
    lastVariables.current = searchVariables;
  }, [searchVariables]);

  const setQuery = (query: string) => {
    setSearchVariables({
      page: 1,
      query,
    });
  }

  const setPage = (page: number) => {
    setSearchVariables(prevVariables => ({
      ...prevVariables,
      page,
    }));
  };

  if (typeof listId === 'undefined') {
    return null;
  }

  const paginationElement = (() => {
    if (data !== null && typeof data !== 'undefined' && isNumber(data.page) && isNumber(data.totalPages) && isNumber(data.totalResults)) {
      return (
        <Pagination
          className="mt-4"
          totalResults={data?.totalResults}
          page={data?.page}
          totalPages={data?.totalPages}
          goToPage={setPage}
          hasSearched={typeof activeVariables?.query.length !== 'undefined' && activeVariables?.query.length > 0}
        />
      );
    }
    return null;
  })();


  const hasResults = data?.totalResults !== null && typeof data?.totalResults !== 'undefined' && data.totalResults > 0;

  return (
    <>
      <Input
        value={searchVariables.query}
        inputRef={ref}
        onChange={setQuery}
      />
      <ResultsWrapper>
        {data?.data && activeVariables?.query && (
          <>
            {paginationElement}
            {hasResults && (
              <SearchResults
                loading={createMediaLoading}
                createMedia={create}
                results={hasId(notNull<mediaSearch_mediaSearch_data>(data.data))}
                getIsInList={(media) => {
                  switch (media.type) {
                    case SearchResultType.MOVIE:
                      return hasListGotMovie(media.id);
                    case SearchResultType.TV:
                      return hasListGotTv(media.id);
                    default:
                      return false;
                  }
                }}
              />
            )}
            {paginationElement}
          </>
        )}
        {activeVariables?.query && !hasResults && !loading && (
          <div className="mt-3">
            <span className="is-size-5">Nothing found for "{activeVariables.query}"</span>
          </div>
        )}
      </ResultsWrapper>
    </>
  );
}

export default SearchInput;
