import React from 'react';
import styled from "styled-components";
import {getForegroundColour} from "../../theme/utils";

const Colour = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 0.125rem;
  background-color ${({colour}: { colour: string }) => colour};
  border: 1px solid ${({ theme }) => getForegroundColour(theme)};
`;

type Props = {
  colour: string;
}

const ColourIcon = ({ colour }: Props) => {
  return (
    <Colour
      colour={colour}
    />
  );
};

export default ColourIcon;
