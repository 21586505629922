import Color from 'color';
import {useMediaQuery} from "@react-hook/media-query";
import defaultTheme, {DARK_THEME_BACKGROUND, ThemeShape} from "../theme";
import {
  BASE_BACKGROUND_COLOUR_KEY,
  mapColourSettingsToColours,
  updateThemeWithColours
} from "./userSettings";
import {useMemo} from "react";
import variableMapCustomised from "../styles/variableMapCustomised";
import {darkenColour, getBackgroundColour, isDark, lightenColour} from "../theme/utils";

const hashCode = (str: string | number) => {
  if (typeof str === 'number') {
    str = str.toString();
  }

  let i, h;
  for (i = 0, h = 0; i < str.length; i++) {
    h = Math.imul(31, h) + str.charCodeAt(i) | 0;
  }

  return Math.abs(h);
};

export const getColor = (theme: ThemeShape, identifier: string | number) => {
  const code = hashCode(identifier);
  const colours = Object.values(theme.colours.palette);
  const index = code % colours.length;
  return colours[index];
};

export const getContrastYIQ = (hexColour: string) => {
  return Color(hexColour).isDark() ? 'white' : 'black';
};

export const usePrefersDarkTheme = () => {
  return useMediaQuery('(prefers-color-scheme: dark)');
};

export const getDefaultBackground = (prefersDarkTheme: boolean) =>
  prefersDarkTheme
    ? DARK_THEME_BACKGROUND
    : variableMapCustomised[BASE_BACKGROUND_COLOUR_KEY];

export const useDefaultTheme = () => {
  const prefersDarkTheme = usePrefersDarkTheme();

  return useMemo(() => {
    const background = getDefaultBackground(prefersDarkTheme);
    const mappedColours = mapColourSettingsToColours([
      {
        themeKey: BASE_BACKGROUND_COLOUR_KEY,
        colour: background,
      }
    ]);

    return updateThemeWithColours(mappedColours, defaultTheme);
  }, [prefersDarkTheme]);
};

export const getAltBackgroundColour = (theme: ThemeShape) => {
  if (isDark(theme)) {
    return lightenColour(getBackgroundColour(theme));
  }
  return darkenColour(getBackgroundColour(theme), 0.02);
};
