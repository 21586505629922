import {gql} from "@apollo/client";

export const USER_SETTING_FIELDS = gql`
  fragment UserSettingsFields on UserSettings {
    id
    key
    value
    updatedAt
  }
`;

export const USER_SETTINGS_BASE_FIELDS = gql`
  fragment UserSettingsBaseFields on UserSettingsBase {
    id
    updatedAt
  }
`;
