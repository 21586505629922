import {useExtractQueryValue} from "../utils";
import {GET_USER_SETTINGS} from "./queries";
import {getUserSettings, WatchProviderLocale} from "../../graphql-types";
import {useQuery} from "@apollo/client";
import {useContext, useMemo} from "react";
import {buildSettings, usePrefersDarkTheme} from "../../utils";
import DefaultLocaleCodeContext from '../../DefaultCountryCode';

export const useGetUserSettings = () => {
  return useExtractQueryValue(
    useQuery<getUserSettings, {}>(GET_USER_SETTINGS, {
      fetchPolicy: 'network-only',
    }),
    'settingsBase'
  );
};

export const useBuiltUserSettings = () => {
  const settings = useGetUserSettings();
  const prefersDarkTheme = usePrefersDarkTheme();
  const defaultLocaleValue = useContext(DefaultLocaleCodeContext);

  return useMemo(() => {
    const {data, ...rest} = settings;
    return {
      data: buildSettings(data, prefersDarkTheme, defaultLocaleValue as WatchProviderLocale | null),
      ...rest
    };
  }, [
    settings,
    prefersDarkTheme,
    defaultLocaleValue,
  ]);
};
