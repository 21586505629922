import {gql} from "@apollo/client";

export const TV_VIEWING_FIELDS = gql`
  fragment TvViewingFields on TvViewing {
    id
    createdAt
    updatedAt
    rating
  }
`;
