import {gql} from "@apollo/client";
import {ACTOR_FIELDS, CREW_MEMBER_FIELDS} from "../Credits";

export const GET_MOVIE_CREDITS = gql`
  query getMovieCredits($movieId: Int!) {
    movieCredits(movieId: $movieId) {
      cast {
        ...ActorFields
      }
      crew {
        ...CrewMemberFields
      }
    }
  }
  ${ACTOR_FIELDS}
  ${CREW_MEMBER_FIELDS}
`;
