import {
  getUserSettings_settingsBase,
  getUserSettings_settingsBase_watchProviders,
  UserSettingsKey,
  WatchProviderLocale
} from "../graphql-types";
import {ThemeShape} from "../theme";
import Color from "color";
import variableMapCustomised from "../styles/variableMapCustomised";
import {getDefaultBackground} from "./colours";
import bcp47 from 'bcp-47';

export type ColourSettingShape = {
  primaryColour: string;
  backgroundColour: string;
};

export type ThemeColoursShape = {
  [key in keyof ThemeShape['colours']['themeColours']]: string;
};

export const BASE_PRIMARY_COLOUR_KEY: keyof typeof variableMapCustomised = '--colour-46';
export const BASE_BACKGROUND_COLOUR_KEY: keyof typeof variableMapCustomised = '--colour-1';
export const BASE_TEXT_COLOUR_KEY: keyof typeof variableMapCustomised = '--colour-0';

export type UserSettings = {
  colour: ColourSettingShape;
  watchProviders: getUserSettings_settingsBase_watchProviders[];
  locale: WatchProviderLocale;
}

export const getDefaultSettings = (prefersDarkTheme: boolean): UserSettings => ({
  colour: {
    primaryColour: variableMapCustomised[BASE_PRIMARY_COLOUR_KEY],
    backgroundColour: getDefaultBackground(prefersDarkTheme),
  },
  watchProviders: [],
  locale: getFirstRegion() || WatchProviderLocale.GB,
});

export const getColourSetting = (
  userSettings: getUserSettings_settingsBase | undefined,
  key: keyof ColourSettingShape,
  prefersDarkTheme: boolean
) => {
  const setting = (userSettings?.settingProperties || []).find(
    setting => setting.key === UserSettingsKey.COLOURS
  );
  if (!setting || !(key in setting.value)) {
    return getDefaultSettings(prefersDarkTheme).colour[key];
  }
  return setting.value[key];
};

export const buildSettings = (
  userSettings: getUserSettings_settingsBase | null | undefined,
  prefersDarkTheme: boolean = false,
  locale: WatchProviderLocale | null
): UserSettings => {
  if (!userSettings) return getDefaultSettings(prefersDarkTheme);
  return {
    colour: {
      primaryColour: getColourSetting(userSettings, 'primaryColour', prefersDarkTheme),
      backgroundColour: getColourSetting(userSettings, 'backgroundColour', prefersDarkTheme),
    },
    watchProviders: userSettings.watchProviders || [],
    locale: userSettings.settingProperties.find(prop => prop.key === UserSettingsKey.LOCALE)?.value || locale || WatchProviderLocale.GB,
  };
};

type Config = {
  base: keyof typeof variableMapCustomised;
  related: (keyof typeof variableMapCustomised)[];
}[];

const conversions: Config = [
  {
    base: BASE_PRIMARY_COLOUR_KEY,
    related: [
      '--colour-2',
      '--colour-3',
      '--colour-4',
      '--colour-11',
      '--colour-34',
      '--colour-35',
      '--colour-36',
      '--colour-37',
      '--colour-38',
      '--colour-39',
      '--colour-40',
      BASE_PRIMARY_COLOUR_KEY,
      '--colour-47',
      '--colour-60',
      '--colour-61',
      '--colour-62',
      '--colour-63',
      '--colour-64',
      '--colour-65',
      '--colour-71',
      '--colour-72',
      '--colour-73',
      '--colour-74',
      '--colour-76',
      '--colour-77',
    ]
  },
  {
    base: BASE_BACKGROUND_COLOUR_KEY,
    related: [
      BASE_BACKGROUND_COLOUR_KEY,
      '--colour-6',
      '--colour-7',
      '--colour-10',
      '--colour-13',
      '--colour-15',
      '--colour-16',
      '--colour-18',
      '--colour-45',
      '--colour-73',
    ]
  }
];

const diffColours = (col: number[], col2: number[]) => {
  return [
    col[0] - col2[0],
    col[1] - col2[1],
    col[2] - col2[2],
  ];
};

const addColours = (col: number[], col2: number[]) => {
  return [
    col[0] + col2[0],
    col[1] + col2[1],
    col[2] + col2[2],
  ];
};

type ColourMap = Partial<typeof variableMapCustomised>;

const mapColours = (
  conversions: Config,
  colourSettings: { themeKey: keyof typeof variableMapCustomised, colour: string }[],
) => {
  return colourSettings.reduce<ColourMap>((acc, colourSetting) => {
    const maps = conversions.find(conversion => conversion.base === colourSetting.themeKey);
    if (!maps) return acc;

    const originalColourHslArray = Color(variableMapCustomised[maps.base]).hsl().array();
    return {
      ...acc,
      ...maps.related.reduce<ColourMap>((acc, key) => {
        const relatedCol = Color(variableMapCustomised[key]).hsl().array();
        const diffToOriginal = diffColours(
          relatedCol,
          originalColourHslArray,
        );
        const colourSetHslArray = Color(colourSetting.colour).hsl().array();
        const adjustedForColourSetting = addColours(
          colourSetHslArray,
          diffToOriginal,
        );
        return {
          ...acc,
          [key]: Color.hsl(adjustedForColourSetting).hex().toString()
        };
      }, {})
    };
  }, {});
};

export const mapColourSettingsToColours = (colours: { themeKey: keyof typeof variableMapCustomised, colour: string }[]) => {
  const themedColours = mapColours(conversions, colours);

  const backgroundColour = Color(themedColours[BASE_BACKGROUND_COLOUR_KEY]);
  const mappedTextColours = mapColours([
      {
        base: BASE_TEXT_COLOUR_KEY,
        related: [
          BASE_TEXT_COLOUR_KEY,
          '--colour-6',
          '--colour-17',
          '--colour-19',
          '--colour-80',
        ]
      }
    ], [
      {
        themeKey: BASE_TEXT_COLOUR_KEY,
        colour: backgroundColour.isDark() ? '#edfae1' : '#575757',
      },
    ],
  );
  return {
    ...themedColours,
    ...mappedTextColours,
  };
}

export const updateThemeWithColours = (values: Partial<ThemeColoursShape>, theme: ThemeShape) => {
  return Object.entries(values).reduce<ThemeShape>((acc, [key, value]) => {
    if (acc.colours.themeColours[key as keyof ThemeColoursShape] === value) return acc;
    return {
      ...acc,
      colours: {
        ...acc.colours,
        themeColours: {
          ...acc.colours.themeColours,
          [key]: value,
        }
      },
    };
  }, theme);
};

export const getFirstRegion = (): WatchProviderLocale | undefined => {
  return navigator.languages
    .map(language => bcp47.parse(language))
    .map(result => result.region)
    .find(region => {
      if (region) {
        return Object.keys(WatchProviderLocale).includes(region);
      }
      return false;
    }) as WatchProviderLocale | undefined;
};
