import {
  getTv,
  getTvForList,
  getTvForList_listTv_data,
  getTvForListVariables,
  ListMediaType,
  WatchProviderLocale,
  getTvVariables as getTvVariablesType, getTvMovieDbIds, getTvMovieDbIdsVariables,
} from "../../graphql-types";
import {useCurrentList} from "../List";
import {useLazyQuery, useQuery} from "@apollo/client";
import {OrderByArray, TvListSort} from "../../stores";
import {useExtractQueryValue} from "../utils";
import {useBuiltUserSettings} from "../UserSettings/userUserSettingsQueries";
import {useCallback, useEffect, useMemo} from "react";
import {GET_TV, GET_TV_FOR_LIST, GET_TV_MOVIE_DB_IDS} from "./queries";
import {nonNull, useListRouteParams, useTvRouteParams} from "../../utils";
import {Optional} from "../../components/types";

export const getTvForListParameters = (listId: number, tvSort: TvListSort, locale: WatchProviderLocale) => {
  return {
    listId: listId,
    orderBy: tvSort[0],
    ...(!locale ? {} : {providerLocale: locale}),
    ...(!locale ? {} : {locale}),
  }
};

export const useCurrentListTv = <T>({
  viewStatus,
  providerIds,
  page,
  pageSize,
  query,
  order,
}: {
  viewStatus?: ListMediaType;
  providerIds?: number[];
  page?: number;
  pageSize?: number;
  order: OrderByArray<T>;
  query: string;
} = {
  query: '',
  order: [],
}) => {
  const {data: list} = useCurrentList();
  const [doFetch, result] = useLazyQuery<getTvForList, getTvForListVariables>(GET_TV_FOR_LIST, {
    fetchPolicy: 'network-only',
  });
  const extractedValue = useExtractQueryValue(result, 'listTv');
  const {data: userSettings} = useBuiltUserSettings();

  useEffect(() => {
    if (list?.id) {
      doFetch({
        variables: {
          ...getTvForListParameters(list.id, order, userSettings.locale),
          viewStatus,
          providerIds,
          page,
          pageSize,
          query,
        },
      });
    }
  }, [
    list?.id,
    doFetch,
    order,
    viewStatus,
    providerIds,
    pageSize,
    page,
    userSettings.locale,
    query,
  ]);

  return useMemo(() => {
    const {data, ...rest} = extractedValue;
    const getDateAdded = (tv: getTvForList_listTv_data) => {
      const matchingList = tv.lists.find(listOnTv => typeof listOnTv.list?.id !== 'undefined' && listOnTv.list?.id === list?.id);
      if (matchingList) {
        return matchingList.createdAt;
      }
      return null;
    };

    return {
      data: {
        ...data,
        data: data && data.data.map(tv => ({
          ...tv,
          addedToListAt: getDateAdded(tv),
        })),
      },
      ...rest,
    };
  }, [
    extractedValue,
    list?.id,
  ]);
};

const useCurrentListTvMovieDbIds = () => {
  const {listId} = useListRouteParams();
  const [fetch, {data}] = useLazyQuery<getTvMovieDbIds, getTvMovieDbIdsVariables>(GET_TV_MOVIE_DB_IDS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (typeof listId === 'number') {
      fetch({
        variables: {
          listId,
        }
      })
    }
  }, [listId]);

  return data?.tvs.map(tv => tv.movieDbId) || [];
};

export const useHasListGotTvMovieDbMovie = () => {
  const movieDbIds = useCurrentListTvMovieDbIds();

  return useCallback((movieDbId: number) => {
    return movieDbIds.some(id => movieDbId === id);
  }, [movieDbIds]);
};

export const getTvVariables = (tvId: number) => ({
  tvId,
});

export const useCurrentTv = () => {
  const {tvId} = useTvRouteParams();
  const [doFetch, result] = useLazyQuery<getTv, getTvVariablesType>(GET_TV, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (typeof tvId !== 'undefined') {
      doFetch({
        variables: getTvVariables(tvId),
      });
    }
  }, [doFetch, tvId]);

  return useExtractQueryValue(result, 'tv');
};

export const useTv = (tvId: Optional<number>) => {
  const [doFetch, result] = useLazyQuery<getTv, getTvVariablesType>(GET_TV, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (nonNull(tvId)) {
      doFetch({
        variables: getTvVariables(tvId),
      });
    }
  }, [doFetch, tvId]);

  return useExtractQueryValue(result, 'tv');
};
