import rsuiteThemeColours from '../styles/variableMapCustomised';

export type ThemeShape = {
  textScale: number;
  stats: {
    size: number;
  };
  colours: {
    palette: {
      red: string,
      pink: string,
      purple: string,
      deepPurple: string,
      indigo: string,
      blue: string,
      lightBlue: string,
      cyan: string,
      teal: string,
      green: string,
      lightGreen: string,
      lime: string,
      yellow: string,
      amber: string,
      orange: string,
      deepOrange: string,
      brown: string,
      grey: string,
      blueGrey: string,
    },
    themeColours: {
      [key in keyof typeof rsuiteThemeColours]: string;
    }
  },
  breakpoints: {
    desktop: string;
    mobile: string;
    mobileOnly: string;
    tablet: string;
    tabletOnly: string;
  }
}

const defaultTheme: ThemeShape = {
  textScale: 1,
  stats: {
    size: 75,
  },
  colours: {
    palette: {
      red: '#F44336',
      pink: '#E91E63',
      purple: '#9C27B0',
      deepPurple: '#673AB7',
      indigo: '#3F51B5',
      blue: '#2196F3',
      lightBlue: '#03A9F4',
      cyan: '#00BCD4',
      teal: '#009688',
      green: '#4CAF50',
      lightGreen: '#8BC34A',
      lime: '#CDDC39',
      yellow: '#FFEB3B',
      amber: '#FFC107',
      orange: '#FF9800',
      deepOrange: '#FF5722',
      brown: '#795548',
      grey: '#9E9E9E',
      blueGrey: '#607D8B',
    },
    themeColours: rsuiteThemeColours,
  },
  breakpoints: {
    desktop: '@media only screen and (min-width: 1048px)',
    tablet: '@media only screen and (max-width: 1048px)',
    tabletOnly: '@media only screen and (min-width: 1048px)',
    mobile: '@media only screen and (max-width: 768px)',
    mobileOnly: '@media only screen and (min-width: 768px)',
  }
};

export type ThemeProps = { theme: ThemeShape };

export default defaultTheme;

export const breakpoint = (device: keyof ThemeShape['breakpoints']) => ({ theme }: ThemeProps) => {
  return theme.breakpoints[device];
};

export const DARK_THEME_BACKGROUND = '#121212';
