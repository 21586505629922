import {useMutation} from "@apollo/client";
import {CREATE_MOVIE_VIEWING, REMOVE_MOVIE_VIEWINGS} from "./mutations";
import {
  createMovieViewing,
  createMovieViewingVariables,
  removeMovieViewingsVariables,
  removeMovieViewings as removeMovieViewingsType
} from "../../graphql-types";
import {useCallback} from "react";

export const useCreateMovieViewing = () => {
  const [doCreate, result] = useMutation<createMovieViewing, createMovieViewingVariables>(CREATE_MOVIE_VIEWING);

  const create = useCallback((movieId: number) => {
    doCreate({
      variables: {
        movieId,
      }
    });
  }, [doCreate]);

  return {
    createMovieViewing: create,
    ...result,
  }
};

export const useRemoveMovieViewings = () => {
  const [doRemove, result] = useMutation<removeMovieViewingsType, removeMovieViewingsVariables>(REMOVE_MOVIE_VIEWINGS);

  const remove = useCallback((movieId: number) => {
    doRemove({
      variables: {
        movieId,
      }
    });
  }, [doRemove]);

  return {
    removeMovieViewings: remove,
    ...result,
  };
};
