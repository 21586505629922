import {useClaimLinkRouteParams, useNavigate} from "../../utils";
import {useRedeemInviteLink} from "../../api";
import {useEffect, useState} from "react";
import {Button, Notification} from "rsuite";
import styled from "styled-components";

const HomeButton = styled(Button)`
  margin-top: 2rem;
`;

const ClaimLink = () => {
  const {code} = useClaimLinkRouteParams();
  const {redeemCode} = useRedeemInviteLink();
  const {goToIndex, goToList} = useNavigate();
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    if (code) {
      (async () => {
        try {
          const result = await redeemCode(decodeURIComponent(code));
          if (result.data?.redeemInvite) {
            Notification.info({
              title: <span>Added to List</span>,
              description: `You've now got access to "${result.data.redeemInvite.name}"`,
            });
            goToList(result.data.redeemInvite.id);
          }
        } catch (e) {
          console.error(e);
          setError(e.message);
        }
      })();
    } else {
      goToIndex();
    }
  }, [
    code,
    redeemCode,
    goToIndex,
    goToList,
    setError,
  ]);

  if (error) {
    return (
      <>
        <h1>Unable to redeem code</h1>
        <p className="is-size-4">{error}</p>
        <HomeButton
          onClick={goToIndex}
        >
          Home
        </HomeButton>
      </>
    );
  }

  return null;
};

export default ClaimLink;
