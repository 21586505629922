import {gql} from "@apollo/client";
import {RECENT_ACTIVITY_FIELDS} from "./fragments";

export const GET_RECENT_ACTIVITIES = gql`
  query getRecentActivities(
    $types: [RecentActivityType!]!
    $listId: Int
  ) {
    recentActivity(
      types: $types,
      listId: $listId
    ) {
      ...RecentActivityFields
      movie {
        id
        title
        posterPath
      }
      tv {
        id
        title
        posterPath
      }
      lists {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
  
  ${RECENT_ACTIVITY_FIELDS}
`;
