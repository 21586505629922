import {useUserLists} from "../../api";
import {List, Placeholder, Tag} from "rsuite";
import {Link} from 'react-router-dom';
import {getLists_lists} from "../../graphql-types";
import {AppPaths} from "../../utils";
import styled from "styled-components";
import EditListDropdown from "../list/EditListDropdown";
import {useDebounceResult} from "../../api/utils";
import {range} from "ramda";
import React from "react";

const hasMovies = (list: getLists_lists) => list.movieCount !== null && list.movieCount > 0;
const hasTv = (list: getLists_lists) => list.tvShowCount !== null && list.tvShowCount > 0;

const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ListTitle = styled.div`
  & > * {
    margin-right: 0.5rem
  }
`;

const ListItem = ({list}: { list: getLists_lists }) => {
  return (
    <>
      <List.Item>
        <ListContent>
          <ListTitle>
            <Link to={AppPaths.LIST.buildPath(list.id)}>{list.name}</Link>
            <span>{hasMovies(list) && <Tag>{list.movieCount} movies</Tag>}</span>
            <span>{hasTv(list) && <Tag>{list.tvShowCount} TV shows</Tag>}</span>
          </ListTitle>
          <div>
            <EditListDropdown listId={list.id} />
          </div>
        </ListContent>
      </List.Item>
    </>
  );
};

const ListContainer = styled(List)`
  overflow: initial;
`;

const MediaLists = () => {
  const {data: lists, loading} = useDebounceResult(useUserLists());

  return (
    <>
      {lists?.length === 0 && (
        <span className="is-size-5">You haven't got any lists yet. Try adding one below.</span>
      )}
      <ListContainer>
        {loading && !lists && range(0, 5).map((n) => (
          <List.Item key={n}>
            <Placeholder.Paragraph rows={1} />
          </List.Item>
        ))}
        {lists?.map((list: getLists_lists) => <ListItem key={list.id} list={list} />)}
      </ListContainer>
    </>
  );
};

export default MediaLists;
