import {gql} from "@apollo/client";

export const MOVIE_FIELDS = gql`
  fragment MovieFields on Movie {
    id
    adult
    backdropPath
    budget
    genres
    homepage
    imdbId
    movieDbId
    originalLanguage
    originalTitle
    overview
    popularity
    posterPath
    productionCompanies
    productionCountries
    releaseDate
    revenue
    runtime
    spokenLanguages
    status
    tagline
    title
    video
    voteAverage
    voteCount
  }
`;
