import {Container, Placeholder} from "rsuite";
import styled from "styled-components";
import React, {useCallback, useRef} from "react";
import {getAltBackgroundColour, range, toTitleCase, useScroll} from "../../utils";
import {useDebounceResult} from "../../api/utils";
import {ThemeProps} from "../../theme";
import MapMedia from "./MapMedia";
import {Pagination} from "../pagination";
import {ListMediaType, SortOrder} from "../../graphql-types";
import {ListMediaFilterVar, OrderByArray} from "../../stores";
import {containerStyle} from "./styles";
import MediaSearch from "./MediaSearch";
import {ListState, SetListState} from "./useListState";

const SearchContainer = styled.div`
  margin: 1rem;
`;

const SeenMediaContainer = styled.div`
  padding-top: 3rem;
  background-color: ${({theme}: ThemeProps) => getAltBackgroundColour(theme)};
  padding-bottom: 5rem;
`;

const MapMediaContainer = styled.div`
  margin-bottom: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;

  & > h3 {
    margin-right: 1rem;
  }
`;

const StyledContainer = styled(Container)`
  ${containerStyle}
`;

const LoadingPlaceholder = () => (
  <>
    {range(5).map((_, i) => (
      <Placeholder.Paragraph
        key={i}
        style={{marginTop: 30}}
        rows={5}
        graph="image"
      />
    ))}
  </>
);

const viewedMediaOrder = [{
  viewedAt: SortOrder.desc,
}];

type UseCurrentListMedia<T> = {
  viewStatus: any;
  providerIds?: number[];
  page: number;
  pageSize: number;
  query: string;
  order: OrderByArray<T>;
};

type Props = {
  filter: ListMediaFilterVar;
  hook: (args: UseCurrentListMedia<any>) => void;
  labelPlural: string;

  viewedState: ListState;
  setViewedState: SetListState;
  nonViewedState: ListState;
  setNonViewedState: SetListState;
  order: OrderByArray<any>;
};

const List = ({
  hook,
  filter,
  labelPlural,
  viewedState,
  nonViewedState,
  setViewedState,
  setNonViewedState,
  order,
}: Props) => {
  const nonViewedTitleRef = useRef(null);
  const viewedTitleRef = useRef(null);
  const scroll = useScroll();

  const updateNonViewedQuery = useCallback((query: string) => {
    setNonViewedState({
      page: 1,
      query,
    });
  }, [setNonViewedState]);

  const updateViewedQuery = useCallback((query: string) => {
    setViewedState({
      page: 1,
      query,
    });
  }, [setViewedState]);

  const {
    data: {
      data: nonViewedMedia,
      page: nonViewedMediaPage,
      pages: nonViewedMediaPages,
      totalResults: nonViewedMediaTotalResults,
    },
    called: calledNonViewedMedia
  } = useDebounceResult(
    hook({
      viewStatus: ListMediaType.NOT_SEEN,
      providerIds: filter.providers,
      page: nonViewedState.page,
      pageSize: 20,
      query: nonViewedState.query,
      order,
    }),
    hookResult => hookResult.data?.data
  );

  const {
    data: {
      data: viewedMedia,
      page: viewedMediaPage,
      pages: viewedMediaPages,
      totalResults: viewedMediaTotalResults,
    },
    called: calledViewedMedia
  } = useDebounceResult(
    hook({
      viewStatus: ListMediaType.SEEN,
      pageSize: 20,
      page: viewedState.page,
      query: viewedState.query,
      order: viewedMediaOrder,
    }),
    hookResult => hookResult.data?.data
  );

  if (
    viewedMedia
    && viewedMedia.length === 0
    && nonViewedMedia
    && nonViewedMedia.length === 0
    && typeof filter.providers === 'undefined'
  ) {
    return (
      <Container className="container mt-4">
        <p className="is-size-4">You haven't added any {labelPlural} yet. Try adding some via the search button.</p>
      </Container>
    );
  }

  const getNonViewedPagination = (onClick: () => void = () => {}) => {
    return (
      <Pagination
        goToPage={pageNumber => {
          onClick();
          setNonViewedState({ page: pageNumber });
        }}
        hasSearched
        page={nonViewedMediaPage}
        totalPages={nonViewedMediaPages}
        totalResults={nonViewedMediaTotalResults}
      />
    );
  };

  const viewedPagination = (
    <Pagination
      goToPage={pageNumber => {
        setViewedState({ page: pageNumber });
      }}
      hasSearched
      page={viewedMediaPage}
      totalPages={viewedMediaPages}
      totalResults={viewedMediaTotalResults}
    />
  );

  const isNoNonViewedMedia = Boolean(nonViewedMedia?.length === 0 && nonViewedMedia);

  return (
    <>
      <StyledContainer className="mb-6 container">
        <TitleContainer className="mt-4" ref={nonViewedTitleRef}>
          <h3 className="is-title-3">{toTitleCase(labelPlural)} to See</h3>
          {nonViewedMedia && <span>{nonViewedMediaTotalResults} {labelPlural} not seen</span>}
        </TitleContainer>
        <SearchContainer>
          <MediaSearch
            placeholder={`Search for ${labelPlural} in your list`}
            query={nonViewedState.query}
            setQuery={updateNonViewedQuery}
          />
        </SearchContainer>
        {isNoNonViewedMedia &&
        <span className="is-size-6 mt-4">There are no {labelPlural} matching your current filters.</span>}
        {(!nonViewedMedia || !calledNonViewedMedia) && <LoadingPlaceholder />}
        {!isNoNonViewedMedia && getNonViewedPagination()}
        <MapMediaContainer>
          <MapMedia media={nonViewedMedia} isSeen={false} />
        </MapMediaContainer>
        {!isNoNonViewedMedia && getNonViewedPagination(scroll)}
      </StyledContainer>
      <SeenMediaContainer>
        <StyledContainer className="container">
          <TitleContainer ref={viewedTitleRef}>
            <h3 className="is-title-3">Seen {toTitleCase(labelPlural)}</h3>
            {viewedMedia && <span>{viewedMediaTotalResults} viewed {labelPlural}</span>}
          </TitleContainer>
          <SearchContainer>
            <MediaSearch
              placeholder={`Search for viewed ${labelPlural} in your list`}
              query={viewedState.query}
              setQuery={updateViewedQuery}
            />
          </SearchContainer>
          {viewedMedia?.length === 0 && viewedMedia &&
          <span className="is-size-6">You haven't seen any {labelPlural} in this list yet.</span>}
          {viewedPagination}
          <MapMediaContainer>
            <MapMedia media={viewedMedia} isSeen />
          </MapMediaContainer>
          {viewedPagination}
          {(!viewedMedia || !calledViewedMedia) && <LoadingPlaceholder />}
        </StyledContainer>
      </SeenMediaContainer>
    </>
  );
};

export default List;
