import React from "react";
import {Container} from "rsuite";
import styled from "styled-components";

const ErrorContainer = styled(Container)`
  margin-top: 4rem;
`;

const ErrorComponent = () => {
  return (
    <ErrorContainer className="container">
      <h1>Something went wrong</h1>
      <section className="section">
        <p>
          Unable to start the app :(
        </p>
      </section>
    </ErrorContainer>
  );
};

export default ErrorComponent;
