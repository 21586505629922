import React, {useEffect, useMemo, useState} from 'react';
import {
  Switch,
  Route,
  Redirect, useLocation,
  matchPath, useHistory, useRouteMatch,
} from "react-router-dom";
import ListPage from "./pages/list";
import {useAuth0} from "@auth0/auth0-react";
import {AppPaths, NavigateContext, NavigateContextType} from "./utils";
import {RouteProps} from "react-router";
import {LoggedIn, LoginRequired} from "./pages/main";
import {PageScaffold} from "./components";
import MoviePage from "./pages/movie";
import PageNotFound from "./components/PageNotFound";
import SettingsPage from "./pages/settings";
import ClaimLink from "./pages/claimLink";
import TvPage from "./pages/tv";
import BackButton from "./components/BackButton";
import PersonPage from "./pages/person";

type AuthSwitchRouteProps = {
  authenticated: JSX.Element;
  defaultElement: JSX.Element;
}

const AuthRouteChild = ({authenticated, defaultElement}: AuthSwitchRouteProps) => {
  const {isLoading, isAuthenticated} = useAuth0();
  if (isLoading) {
    return null;
  }
  if (isAuthenticated) {
    return authenticated;
  }
  return defaultElement;
}

const AuthSwitchRoute = ({authenticated, defaultElement, ...rest}: RouteProps & AuthSwitchRouteProps) => {
  return (
    <Route {...rest}>
      <AuthRouteChild
        authenticated={authenticated}
        defaultElement={defaultElement}
      />
    </Route>
  );
};

type AuthRequiredRouteProps = {
  element: JSX.Element;
};

const AuthRequiredChild = ({element}: AuthRequiredRouteProps) => {
  const {isAuthenticated, isLoading} = useAuth0();
  if (isLoading) {
    return null;
  }
  if (!isAuthenticated) {
    return <Redirect to={AppPaths.INDEX.buildPath()} />
  }
  return element;
};

const AuthRequiredRoute = ({element, ...rest}: RouteProps & AuthRequiredRouteProps) => {
  return (
    <Route {...rest}>
      <AuthRequiredChild element={element} />
    </Route>
  );
};

const pagesWithoutScaffold = [
  AppPaths.MOVIE,
  AppPaths.LIST,
  AppPaths.TV,
  AppPaths.PERSON,
];

function App() {
  const {isLoading} = useAuth0();
  const location = useLocation();
  const [is404, setIs404] = useState(false);
  const history = useHistory();

  const isIndex = useRouteMatch(AppPaths.INDEX);

  const navigateProvider: NavigateContextType = useMemo(() => ({
    setIs404: () => setIs404(true),
  }), [setIs404]);

  useEffect(() => {
    setIs404(false);
  }, [setIs404, location.pathname]);

  const isWithoutScaffold = useMemo(
    () => pagesWithoutScaffold.some(props => Boolean(matchPath(location.pathname, props))),
    [location.pathname],
  );

  if (isLoading) {
    return null;
  }

  return (
    <NavigateContext.Provider value={navigateProvider}>
      <PageScaffold ignorePadding={!is404 && isWithoutScaffold}>
        {!isIndex && <BackButton navigate={() => history.goBack()} />}
        {is404 && <PageNotFound />}
        {!is404 && (
          <Switch>
            <AuthSwitchRoute
              {...AppPaths.INDEX}
              defaultElement={<LoginRequired />}
              authenticated={<LoggedIn />}
            />
            <AuthRequiredRoute
              {...AppPaths.LIST}
              element={<ListPage />}
            />
            <AuthRequiredRoute {...AppPaths.MOVIE} element={<MoviePage />} />
            <AuthRequiredRoute {...AppPaths.TV} element={<TvPage />} />
            <AuthRequiredRoute {...AppPaths.PERSON} element={<PersonPage />} />
            <AuthRequiredRoute {...AppPaths.SETTINGS} element={<SettingsPage />} />
            <AuthRequiredRoute {...AppPaths.CLAIM_LINK} element={<ClaimLink />} />
            <Route {...AppPaths.NOT_FOUND}><PageNotFound /></Route>
          </Switch>
        )}
      </PageScaffold>
    </NavigateContext.Provider>
  );
}

export default App;
