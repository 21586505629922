import {useCurrentList} from "../../api";
import {getMoviesForList_listMovies_data, getTvForList_listTv_data} from "../../graphql-types";
import {Button, Checkbox, Icon, Panel} from "rsuite";
import MediaTitleLink from "../lists/MediaTitleLink";
import React, {SyntheticEvent, useContext, useMemo} from "react";
import ProviderLogos from "../ProviderLogos";
import {getProvidersFromMedia} from "../../utils";
import styled from "styled-components";
import Media from "../Media";
import {Media as MediaType} from './types';
import ControlContext from "./ControlContext";

const MediaContainer = styled(Panel)`
  display: flex;
  align-items: start;
  padding-top: 1rem;
  margin-top: 1rem;
  padding-bottom: 1rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--colour-5);
  }

  border-radius: 0;

  & .rs-panel-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
  }
`;

const Toggle = styled(Checkbox)`
  margin-top: 0.5rem;
`;

const MediaComponent = styled(Media)`
  flex-grow: 1;
  padding-left: 0;
`;

const CloseButton = styled(Button)`
  margin-top: -0.5rem !important;
  margin-right: -0.5rem !important;
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
`;

const LocaleContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

type MapMediaProps = {
  isSeen: boolean;
  media?: MediaType[] | null;
};

const isMovie = (media: getMoviesForList_listMovies_data | getTvForList_listTv_data): media is getMoviesForList_listMovies_data => {
  return typeof (media as getMoviesForList_listMovies_data).movieViewings !== 'undefined';
};

const MapMedia = ({media, isSeen}: MapMediaProps) => {
  const {data: list} = useCurrentList();

  const controls = useContext(ControlContext);

  const mediaList = useMemo(() => (media || []).map(media => ({
    ...media,
    viewings: isMovie(media) ? media.movieViewings : media.tvViewings
  })), [media]);

  if (!list || !media) return null;

  return (
    <>
      {mediaList.map(media => (
        <MediaContainer
          key={media.id}
          bodyFill
        >
          <Toggle
            checked={isSeen}
            onClick={() => controls.toggleViewing(media)}
          />
          <MediaComponent
            media={media}
            renderTitle={title => (
              <MediaTitleLink
                title={title}
                onClick={() => controls.goToMedia(media)}
              />
            )}
            topRight={(
              <CloseButton
                appearance="subtle"
                className="mt-4"
                onClick={(e: SyntheticEvent) => {
                  e.preventDefault();
                  controls.removeMedia(media);
                }}
              >
                <Icon icon="close" />
              </CloseButton>
            )}
            content={
              <LocaleContainer>
                <ProviderLogos providers={getProvidersFromMedia(media)} />
              </LocaleContainer>
            }
          />
        </MediaContainer>
      ))}
    </>
  );
};

export default MapMedia;
