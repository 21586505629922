import {Dropdown, Icon, IconButton} from "rsuite";
import {SortOrder} from "../graphql-types";
import {
  SortVars,
  useActiveSortField,
  useSetSortField,
  useSortOrder,
  useToggleOrder
} from "../stores";

export type SortField = {
  fieldName: string;
  label: string;
};

type Props = {
  fields: SortField[];
  sortVar: SortVars;
};

const Sort = ({fields, sortVar}: Props) => {
  const setSortField = useSetSortField(sortVar);
  const sortOrder = useSortOrder(sortVar);
  const toggleSortOrder = useToggleOrder(sortVar);
  const activeSortField = useActiveSortField(sortVar);

  const getTitle = () => {
    const activeField = fields.find(field => field.fieldName === activeSortField);
    if (activeField) return activeField.label;
    return 'Sort';
  }

  return (
    <>
      <Dropdown title={getTitle()}>
        {fields.map(field => (
          <Dropdown.Item
            key={field.fieldName}
            active={activeSortField === field.fieldName}
            onSelect={() => setSortField(field.fieldName)}
          >
            {field.label}
          </Dropdown.Item>
        ))}
      </Dropdown>
      <IconButton
        onClick={toggleSortOrder}
        appearance="subtle"
        className="ml-2"
        icon={<Icon icon={sortOrder === SortOrder.desc ? 'sort-desc' : 'sort-up'} />}
      >
        Sort Order
      </IconButton>
    </>
  );
};

export default Sort;
