import React from 'react';
import {Icon, IconButton, IconButtonProps} from "rsuite";
import {createPortal} from "react-dom";
import {useReactiveVar} from "@apollo/client";
import {backButtonContainer} from '../stores';

type Args = IconButtonProps & {
  navigate: () => void;
};

const BackButton = ({navigate, ...rest}: Args) => {
  const buttonContainer = useReactiveVar(backButtonContainer);
  if (buttonContainer === null) return null;
  return (
    createPortal(
      <IconButton
        {...rest}
        onClick={() => {
          navigate();
          window.scrollTo(0, 0);
        }}
        icon={<Icon icon="back-arrow" />}
      />,
      buttonContainer,
    )
  );
};

export default BackButton;
