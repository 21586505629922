import {gql} from "@apollo/client";

export const MOVIE_VIEWING_FIELDS = gql`
  fragment MovieViewingFields on MovieViewing {
    id
    createdAt
    updatedAt
    rating
  }
`;
