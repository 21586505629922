import React from 'react';
import MediaLists from './MediaLists';
import CreateList from "./CreateList";
import Sort, {SortField} from "../Sort";
import {listSortVar} from "../../stores";
import styled from "styled-components";
import {useUserLists} from "../../api";
import {renderOn} from "../../utils";
import {useDebounceResult} from "../../api/utils";

const sortFields: SortField[] = [
  {
    fieldName: 'name',
    label: 'Alphabetically',
  },
  {
    fieldName: 'movieCount',
    label: 'Number of Movies'
  },
  {
    fieldName: 'tvShowCount',
    label: 'Number of TV Shows',
  },
  {
    fieldName: 'updatedAt',
    label: 'Last Changed'
  },
  {
    fieldName: 'createdAt',
    label: 'Time Created'
  },
];

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

const Lists = () => {
  const {data: lists} = useDebounceResult(useUserLists());
  return (
    <>
      <h2 className="is-size-2 mt-6">Lists</h2>
      {renderOn(
        lists,
        () => (
          <ControlsContainer>
            <Sort
              fields={sortFields}
              sortVar={listSortVar}
            />
          </ControlsContainer>
        ),
        lists => lists.length > 0
      )}
      <MediaLists />
      <CreateList className="mt-4" />
    </>
  );
}

export default Lists;
