import {useHistory, useRouteMatch, useLocation} from "react-router-dom";
import React, {useCallback, useContext, useMemo} from "react";
import {useCreateMovie} from "../api";
import {useCreateTv} from "../api/Tv";

export type ListParams = {
  listId?: string;
};

export type MovieParams = {
  movieId?: string;
};

export type TvParams = {
  tvId?: string;
}

export type ClaimLinkParams = {
  code?: string;
};

export type PeopleParams = {
  personId?: string;
}

export const useRouteParams = <T>() => {
  const match = useRouteMatch<T>();
  return match.params;
};

export const useListRouteParams = () => {
  const {listId} = useRouteParams<ListParams>();
  return {
    listId: typeof listId !== 'undefined' ? parseInt(listId, 10) : undefined,
  };
};

export const useMovieRouteParams = () => {
  const {movieId} = useRouteParams<MovieParams>();
  return {
    movieId: typeof movieId !== 'undefined' ? parseInt(movieId, 10) : undefined,
  }
};

export const useTvRouteParams = () => {
  const {tvId} = useRouteParams<TvParams>();
  return {
    tvId: typeof tvId !== 'undefined' ? parseInt(tvId, 10) : undefined,
  };
};

export const usePeopleRouteParams = () => {
  const {personId} = useRouteParams<PeopleParams>();
  return {
    personId: typeof personId !== 'undefined' ? parseInt(personId, 10) : undefined,
  };
};

export const useClaimLinkRouteParams = () => {
  const {code} = useRouteParams<ClaimLinkParams>();
  return {
    code,
  }
};

export const useGoTo = () => {
  const history = useHistory();
  return useCallback((location: string) => {
    history.push(location);
  }, [history]);
};

export const useNavigate = () => {
  const goTo = useGoTo();
  const goToList = useCallback((id?: number) => {
    if (typeof id !== 'undefined') {
      goTo(AppPaths.LIST.buildPath(id));
      window.scrollTo(0, 0);
    }
  }, [goTo]);

  const {createMovie} = useCreateMovie();
  const {createTv} = useCreateTv();

  const goToListTv = useCallback((id?: number) => typeof id !== 'undefined' && goTo(AppPaths.LIST_TV.buildPath(id)), [goTo]);
  const goToListRecentActivity = useCallback((id?: number) => typeof id !== 'undefined' && goTo(AppPaths.LIST_RECENT_ACTIVITY.buildPath(id)), [goTo]);
  const goToIndex = useCallback(() => goTo(AppPaths.INDEX.buildPath()), [goTo]);
  const goToMovie = useCallback((movieId: number, listId?: number) => goTo(AppPaths.MOVIE.buildPath(movieId, listId)), [goTo]);
  const goToTv = useCallback((tvId: number) => goTo(AppPaths.TV.buildPath(tvId)), [goTo]);
  const goToPerson = useCallback((personId) => goTo(AppPaths.PERSON.buildPath(personId)), [goTo]);

  const createTvAndNavigate = useCallback(async (movieDbId: number) => {
    const result = await createTv(movieDbId);
    const id = result.data?.createTv?.id;
    if (typeof id === 'number') {
      goToTv(id);
    }
  }, [
    createTv
  ]);

  const createMovieAndNavigate = useCallback(async (movieDbId: number) => {
    const result = await createMovie(movieDbId);
    const id = result.data?.createMovie?.id;
    if (typeof id === 'number') {
      goToMovie(id);
    }
  }, [
    createMovie,
  ]);

  const goToSettings = useCallback(() => goTo(AppPaths.SETTINGS.buildPath()), [goTo]);

  return useMemo(
    () => ({
      goToIndex,
      goToList,
      goToListRecentActivity,
      goToMovie,
      goToSettings,
      goToListTv,
      goToTv,
      goToPerson,
      createTvAndNavigate,
      createMovieAndNavigate,
    }),
    [
      goToList,
      goToIndex,
      goToMovie,
      goToSettings,
      goToListRecentActivity,
      goToListTv,
      goToTv,
      goToPerson,
      createMovieAndNavigate,
      createTvAndNavigate,
    ]
  );
};

export const AppPaths = {
  INDEX: {exact: true, path: '/', buildPath: () => '/'},
  LIST: {
    exact: false,
    path: "/list/:listId",
    buildPath: (listId: number) => `/list/${encodeURIComponent(listId)}`,
  },
  LIST_MOVIES: {
    exact: true,
    path: '/list/:listId',
    buildPath: (listId: number) => `/list/${encodeURIComponent(listId)}`,
  },
  LIST_TV: {
    exact: true,
    path: '/list/:listId/tv',
    buildPath: (listId: number) => `/list/${encodeURIComponent(listId)}/tv`,
  },
  LIST_RECENT_ACTIVITY: {
    exact: true,
    path: '/list/:listId/recent-activity',
    buildPath: (listId: number) => `/list/${encodeURIComponent(listId)}/recent-activity`,
  },
  MOVIE: {
    exact: true,
    path: '/movie/:movieId',
    buildPath: (movieId: number, fromListId?: number) => `/movie/${encodeURIComponent(movieId)}${typeof fromListId !== 'undefined' ? `?fromListId=${encodeURIComponent(fromListId)}` : ''}`,
  },
  PERSON: {
    exact: true,
    path: '/person/:personId',
    buildPath: (personId: number) => `/person/${personId}`,
  },
  TV: {
    exact: true,
    path: '/tv/:tvId',
    buildPath: (tvId: number) => `/tv/${encodeURIComponent(tvId)}`,
  },
  SETTINGS: {
    exact: true,
    path: '/settings',
    buildPath: () => `/settings`,
  },
  CLAIM_LINK: {
    exact: true,
    path: '/claim-link/:code',
    buildPath: (code: string) => `/claim-link/${encodeURIComponent(code)}`,
  },
  NOT_FOUND: {path: '*', exact: false, buildPath: () => '*'},
};

export type AllAppPaths = typeof AppPaths;

export const useQuery = (value: string) => {
  return new URLSearchParams(useLocation().search).get(value);
};

export type NavigateContextType = {
  setIs404: () => void;
};

export const NavigateContext = React.createContext<NavigateContextType>(null as unknown as NavigateContextType);

export const useNavigateContext = () => {
  return useContext(NavigateContext);
};
