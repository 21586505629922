import {SEARCH_MEDIA} from "./queries";
import {useLazyQuery} from "@apollo/client";
import {useCallback, useEffect, useState} from "react";
import {mediaSearch, mediaSearchVariables} from "../../graphql-types";

export const useMediaSearch = () => {
  const [doSearch, {
    loading,
    data,
    client,
    variables
  }] = useLazyQuery<mediaSearch, mediaSearchVariables>(SEARCH_MEDIA, {
    fetchPolicy: 'network-only',
  });

  const [activeVariables, setActiveVariables] = useState(variables);

  useEffect(() => {
    setActiveVariables(variables);
  }, [variables]);

  const clearSearch = useCallback(() => {
    client?.cache.modify({
      fields: {
        mediaSearch: value => {
          return {
            ...value,
            page: 1,
            totalPages: 0,
            totalResults: 0,
            data: [],
          };
        }
      }
    });
    setActiveVariables({
      query: '',
      page: 1,
    });
  }, [client]);

  const query = useCallback((page: number, query: string) => {
    if (query.length === 0) {
      clearSearch();
    } else {
      doSearch({
        variables: {
          query,
          page,
        }
      });
    }
  }, [clearSearch, doSearch]);

  return {
    query,
    clearSearch,
    loading,
    data: data?.mediaSearch,
    variables: activeVariables,
  };
};
