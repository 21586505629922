import {ADD_WATCH_PROVIDER, REMOVE_WATCH_PROVIDER, UPDATE_SETTINGS} from "./mutations";
import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import {
  addWatchProvider,
  addWatchProviderVariables, removeWatchProvider, removeWatchProviderVariables,
  updateSettings,
  updateSettingsVariables,
  UserSettingsKey
} from "../../graphql-types";
import {GET_USER_SETTINGS} from "./queries";

export const useUpdateUserSettings = () => {
  const [doUpdate, result] = useMutation<updateSettings, updateSettingsVariables>(UPDATE_SETTINGS);

  const update = useCallback((key: UserSettingsKey, value: any) => {
    return doUpdate({
      variables: {
        key,
        value,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {query: GET_USER_SETTINGS},
      ]
    });
  }, [doUpdate]);

  return {
    updateSettings: update,
    ...result,
  }
};

export const useAddWatchProviderSetting = () => {
  const [doAdd, result] = useMutation<addWatchProvider, addWatchProviderVariables>(ADD_WATCH_PROVIDER);

  const add = useCallback((watchProviderId: number) => {
    return doAdd({
      variables: {
        watchProviderId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {query: GET_USER_SETTINGS},
      ],
    });
  }, [doAdd]);

  return {
    addProvider: add,
    ...result,
  };
};

export const useRemoveWatchProviderSetting = () => {
  const [doRemove, result] = useMutation<removeWatchProvider, removeWatchProviderVariables>(REMOVE_WATCH_PROVIDER);

  const remove = useCallback((watchProviderId: number) => {
    return doRemove({
      variables: {
        watchProviderId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {query: GET_USER_SETTINGS},
      ]
    });
  }, [doRemove]);

  return {
    removeProvider: remove,
    ...result,
  };
};
