import React, {useState} from 'react';
import styled from "styled-components";
import {Button} from "rsuite";
import ColourIcon from "./ColourIcon";
import {SketchPicker} from "react-color";
import {getBackgroundColour, getDropdownBackground, getForeground, isDark} from "../../theme/utils";
import {ThemeProps} from "../../theme";

const ColourPickerContainer = styled.div`
  position: absolute;
  z-index: 10;
  margin-left: 1rem;
`;

const StyledColourPicker = styled(SketchPicker)`
  ${getDropdownBackground}
  ${getForeground}
  label {
    ${getForeground}
  }

  ${({theme}: ThemeProps) => isDark(theme) && `input {
    color: ${getBackgroundColour(theme)} !important;
  }`}
`;

const ColourPickerOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ToggleButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin-right: 0.25rem;
  }
`;

type Props = {
  colour: string;
  setColour: (colour: string) => void;
};

const ColourPicker = ({colour, setColour}: Props) => {
  const [isColourPickerOpen, setIsColourPickerOpen] = useState(false);

  return (
    <>
      <ToggleButton
        onClick={() => setIsColourPickerOpen(true)}
      >
        <ColourIcon colour={colour} /> Change Colour
      </ToggleButton>
      {!isColourPickerOpen ? null : (
        <ColourPickerContainer>
          <ColourPickerOverlay
            onClick={() => setIsColourPickerOpen(false)}
          />
          <StyledColourPicker
            color={colour}
            onChange={colour => setColour(colour.hex)}
          />
        </ColourPickerContainer>
      )}
    </>
  );
};

export default ColourPicker;
