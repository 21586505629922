import React, {useMemo} from 'react';
import {Checkbox, Dropdown, Placeholder} from "rsuite";
import {NonNull} from "../utils/types";
import {getAllWatchProviders_allWatchProviders} from "../graphql-types";
import styled from "styled-components";
import {sortProviders} from "../utils";
import NonCloseDropdown from "./NonCloseDropdown";

type Provider = NonNull<getAllWatchProviders_allWatchProviders>;

const ProviderToggle = styled(Checkbox)`
  padding: 0;

  & .rs-checkbox-checker {
    padding: 0;
  }

  & .rs-checkbox-wrapper {
    top: 18px;
    left: 14px;
  }
`;

const ProviderContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 18px 46px;
`;

const DropdownItem = styled(Dropdown.Item)`
  & > .rs-dropdown-item-content {
    padding: 0;
  }
`;

type Props = {
  providers: Provider[];
  toggleProvider: (provider: Provider) => () => void;
  isProviderSelected: (provider: Provider) => void;
  loading: boolean;
  disabled?: boolean;
  className?: string;
  clearOption?: {
    title: React.ReactNode;
    onSelect: () => void;
  }
};

const ProviderDropdown = ({
  providers,
  isProviderSelected,
  loading,
  toggleProvider,
  disabled = false,
  className = '',
  clearOption,
}: Props) => {
  const orderedProviders = useMemo(() => {
    return sortProviders(providers);
  }, [providers]);

  return (
    <NonCloseDropdown
      className={className}
      disabled={disabled}
      title="Choose Streaming Services"
    >
      {loading && <Placeholder.Paragraph rows={5} />}
      {clearOption && (
        <DropdownItem
          onSelect={clearOption.onSelect}
        >
          <ProviderContent>
            {clearOption.title}
          </ProviderContent>
        </DropdownItem>
      )}
      {orderedProviders.map(provider => (
        <DropdownItem key={provider.id}>
          <ProviderToggle
            checked={isProviderSelected(provider)}
            onChange={toggleProvider(provider)}
          >
            <ProviderContent>
              {provider.providerName}
            </ProviderContent>
          </ProviderToggle>
        </DropdownItem>
      ))}
    </NonCloseDropdown>
  );
};

export default ProviderDropdown;
