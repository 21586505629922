import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";

const createClient = (
  isLoggedIn: boolean,
  getAccessToken: () => Promise<string | null>,
) => {
  const httpLink = createHttpLink({
    uri: '/graphql',
  });

  const errorLink = onError(({graphQLErrors}) => {
    if (graphQLErrors) {
      graphQLErrors.map(({message, locations, path}) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }
  });

  const authLink = setContext(async (_, {headers}) => {
    if (isLoggedIn) {
      const accessToken = await getAccessToken();
      if (accessToken) {
        return {
          headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          }
        }
      }
    }
    return {
      headers,
    };
  });

  return new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      authLink,
      httpLink,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Tv: {
          fields: {
            tvViewings: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            lists: {
              merge(existing, incoming) {
                return incoming;
              }
            }
          }
        },
        Movie: {
          fields: {
            movieViewings: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            lists: {
              merge(existing, incoming) {
                return incoming;
              }
            }
          }
        },
        Query: {
          fields: {
            movies: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            tvs: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            recommendMovies: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            listMovies: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            lists: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            allWatchProviders: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            stats: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            recentActivity: {
              merge(existing, incoming) {
                return incoming;
              }
            }
          },
        },
        UserSettingsBase: {
          fields: {
            watchProviders: {
              merge(existing, incoming) {
                return incoming;
              }
            }
          }
        },
        List: {
          fields: {
            listAccesses: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            movies: {
              merge(existing, incoming) {
                return incoming;
              }
            },
            tv: {
              merge(existing, incoming) {
                return incoming;
              }
            }
          }
        }
      },
    }),
  });
};

export default createClient;
