import React, {useEffect, useState} from 'react';
import {useUserMutations} from "../api";
import {useAuth0} from "@auth0/auth0-react";

export const UserSync = ({children}: { children: React.ReactNode }) => {
  const {isAuthenticated, isLoading} = useAuth0();
  const {syncMe, loading, called, data} = useUserMutations();
  const [isSynced, setIsSynced] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      syncMe();
    }
  }, [isAuthenticated, syncMe]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      setIsSynced(true);
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (called && data && !loading) {
      setIsSynced(true);
    }
  }, [loading, called, data]);

  if (!isSynced) {
    return null;
  }

  return <>{children}</>;
};
