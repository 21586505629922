import {Button, List} from "rsuite";
import {useMemo} from "react";
import {renderOn, useNavigate} from "../../utils";
import styled from "styled-components";
import EditListDropdown from "../list/EditListDropdown";
import {Lists} from "./types";

const Container = styled.div`
  padding: 2rem;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
`;

const ListContainer = styled(List)`
  overflow: initial;
`;

type Props = {
  lists: Lists[];
}

const MediaLists = ({lists}: Props) => {
  const {goToList} = useNavigate();

  const nonNullLists = useMemo(() => {
    return lists.filter(list => Boolean(list.list));
  }, [lists]);

  if (nonNullLists.length === 0) return null;

  return (
    <Container className="mt-2">
      <h3>Your Lists</h3>
      <ListContainer>
        {nonNullLists.map(list => (
          renderOn(list.list, list => (
            <List.Item key={list.id}>
              <ListItem>
                <Button
                  appearance="link"
                  className="p-0"
                  onClick={() => goToList(list.id)}
                >
                  {list.name}
                </Button>
                <div>
                  <EditListDropdown listId={list.id} />
                </div>
              </ListItem>
            </List.Item>
          ))
        ))}
      </ListContainer>
    </Container>
  );
};

export default MediaLists;
