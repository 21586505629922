import {Modal} from "rsuite";
import SearchInput from "./SearchInput";
import {createModal} from "../Modal";
import {mediaSearch_mediaSearch_data, SearchResultType} from "../../graphql-types";
import {useCreateMovieFromSearchResult} from "../../api";
import {useCreateTvFromSearchResult} from "../../api/Tv";
import {useNavigate} from "../../utils";
import {useCallback, useEffect} from "react";

type Props = {
  id: string;
};

const CreateMedia = createModal<Props>(({ModalComponent, closeModal}) => {
  const {createMovie, loading: createMovieLoading} = useCreateMovieFromSearchResult();
  const {createTv, loading: createTvLoading} = useCreateTvFromSearchResult();
  const {goToList, goToListTv} = useNavigate();

  const createMedia = useCallback(async (media: mediaSearch_mediaSearch_data, listId: undefined | number) => {
    if (media.id !== null) {
      if (media.type === SearchResultType.TV) {
        await createTv(media.id, listId);
        goToListTv(listId);
      } else if (media.type === SearchResultType.MOVIE) {
        await createMovie(media.id, listId);
        goToList(listId);
      }
    }
    return Promise.resolve();
  }, [
    createTv,
    goToListTv,
    createMovie,
    goToList,
  ]);

  return (
    <ModalComponent size="lg">
      <Modal.Header onHide={closeModal}>
        <Modal.Title>
          Search for Media
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchInput
          createMediaLoading={createMovieLoading || createTvLoading}
          createMedia={createMedia}
        />
      </Modal.Body>
    </ModalComponent>
  );
});

export default CreateMedia;
