import {gql} from "@apollo/client";
import {USER_FIELDS} from "./fragments";

export const USE_ME = gql`
  query useMe {
    me {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;
