import React, {useMemo} from 'react';
import {Button, Icon, List, Modal, Tag} from 'rsuite';
import {createModal} from "../Modal";
import {useList, useRemoveListAccess} from "../../api";
import {useGetMe} from "../../api/User/useUserQueries";
import styled from "styled-components";
import {ListAccessType} from "../../graphql-types";

const ListItem = styled.div`
  ${({isMe}: { isMe: boolean }) => isMe && 'font-weight: bold;'}
`;

const ListItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ListAccessTag = styled(Tag)`
  margin-left: 0.5rem;
`;

type Props = {
  listId: number;
  id: string;
};

const ListUsers = createModal<Props>(({listId, ModalComponent, closeModal}) => {
  const {data: list} = useList(listId);
  const {data: me} = useGetMe();
  const {removeListAccess, loading} = useRemoveListAccess();

  const accesses = useMemo(() => {
    const listAccesses = list?.listAccesses || [];
    return listAccesses.filter(
      (access, i) => listAccesses.findIndex(
        a => a.user.id === access.user.id
      ) === i
    );
  }, [list]);

  const isListOwner = useMemo(() => accesses.some(
    access => (
      access.user.id === me?.id
      && access.accessType === ListAccessType.OWNER
    )
  ), [me, accesses]);

  const handleListAccessDeleted = async (listAccessId: number) => {
    await removeListAccess(listAccessId);
    closeModal();
  };

  return (
    <ModalComponent>
      <Modal.Header>
        <Modal.Title>
          People who have access to "{list?.name}"
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <List>
          {accesses.map(listAccess => {
            const isMe = me?.id === listAccess.user.id;
            const isOwner = listAccess.accessType === ListAccessType.OWNER;
            return (
              <ListItem
                key={listAccess.id}
                isMe={isMe}
              >
                <List.Item>
                  <ListItemContent>
                    <span>
                      {listAccess.user.firstName} {listAccess.user.lastName}
                      {isOwner && <ListAccessTag>owner</ListAccessTag>}
                      {isMe && <ListAccessTag>you</ListAccessTag>}
                    </span>
                    {((isListOwner && !isMe) || (isMe && !isListOwner)) && (
                      <Button
                        appearance="subtle"
                        loading={loading}
                        onClick={() => handleListAccessDeleted(listAccess.id)}
                      >
                        <Icon icon="close" />
                      </Button>
                    )}
                  </ListItemContent>
                </List.Item>
              </ListItem>
            )
          })}
        </List>
      </Modal.Body>
    </ModalComponent>
  );
});

export default ListUsers;
