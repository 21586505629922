import React, {useContext} from 'react';
import {round} from "../utils";
import {ThemeContext} from "styled-components";
import {getBackgroundColour, getForegroundColour} from "../theme/utils";

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

  return [
    "M", start.x, start.y,
    "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
    "L", x, y,
    "L", start.x, start.y
  ].join(" ");
}

type Props = {
  percentage: number;
  colour: string;
  size: number;
};

const PercentageCircle = ({percentage, colour, size}: Props) => {
  percentage = (percentage === 100) ? 99.999 : percentage;
  const halfSize = size / 2;
  const theme = useContext(ThemeContext);
  return (
    <svg viewBox={`0 0 ${size} ${size}`} width={size} height={size}>
      <path shapeRendering="optimizeQuality" d={describeArc(halfSize, halfSize, halfSize, 0, (percentage / 100) * 360)} fill={colour} />
      <path shapeRendering="optimizeQuality" d={describeArc(halfSize, halfSize, halfSize, (percentage / 100) * 360, 360)} fill={colour} opacity="0.1" />
      <circle shapeRendering="optimizeQuality" cx={halfSize} cy={halfSize} r={halfSize * 0.85} fill={getBackgroundColour(theme)} />
      <text shapeRendering="optimizeQuality" x="50%" y="50%" dominantBaseline="middle" fill={getForegroundColour(theme)} textAnchor="middle">{round(percentage, 2)}%</text>
    </svg>
  );
};

export default PercentageCircle;
