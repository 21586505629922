import {useMovieRouteParams} from "../../utils";
import {useLazyQuery} from "@apollo/client";
import {GET_MOVIE_CREDITS} from "./queries";
import {useEffect} from "react";
import {getMovieCredits, getMovieCreditsVariables} from "../../graphql-types";
import {useExtractQueryValue} from "../utils";

export const useCurrentMovieCredits = () => {
  const {movieId} = useMovieRouteParams();
  const [doFetch, result] = useLazyQuery<getMovieCredits, getMovieCreditsVariables>(GET_MOVIE_CREDITS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (typeof movieId !== 'undefined') {
      doFetch({
        variables: {
          movieId,
        }
      });
    }
  }, [doFetch, movieId]);

  return useExtractQueryValue(result, 'movieCredits');
};
